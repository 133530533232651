import React, { useEffect } from "react";
import ContactForm from "./contact/contactForm";
import "../styles/solution-view.css";

const SolutionComponent = () => {
  return (
    <section className="solutions-overview-sec">
      <div className="ctas-wrapper">
        <div className="ctas-container">
          <div className="ctas-text-wrapper">
            <h2
              className="heading-48px ctas-heading"
              style={{ color: "#f7e8c7" }}
            >
              Trim off those extra payment processing fees
            </h2>
            <p className="paragraph-18px ctas-paragraph">
              Share your information and we will be in touch with you shortly.
            </p>
            {/*<div className="ctas-button-contain">*/}
            {/*  <a href="#calculator" className="pink-arrow-button w-inline-block" style={{ background: '#16a34a' }}>*/}
            {/*    <div className="button-panels-contain">*/}
            {/*      <div className="button-panel" />*/}
            {/*    </div>*/}
            {/*  <div className="button-text-wrapper">*/}
            {/*    <div className="button-text">See how much you can save</div>*/}
            {/*    <div className="button-arrow-embed _18px w-embed">*/}
            {/*      <svg width={10} height={17} viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*        <path d="M2 2L8.75 8.75L2 15.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />*/}
            {/*      </svg>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</a>*/}
            {/*  <a href="/book-a-demo" className="transparent-white-arrow-button w-inline-block"><div className="button-panels-contain">*/}
            {/*    <div className="button-panel white" />*/}
            {/*  </div><div className="button-text-wrapper">*/}
            {/*      <div className="button-text">Get a free savings analysis</div>*/}
            {/*    </div></a>*/}
            {/*</div>*/}
          </div>
          <div className="solution-view">
            <ContactForm />
            {/* <CheckForm /> */}
          </div>
          {/*<div className="ctas-image-wrapper">*/}
          {/*  <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa1e36f940a58a7f297_Mask%20group.png" loading="lazy" data-w-id="83ce114f-84d0-2bc6-c9a0-05ec9be11879" sizes="(max-width: 767px) 90vw, (max-width: 991px) 68vw, (max-width: 1279px) 38vw, 440px" alt="Logo" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa1e36f940a58a7f297_Mask%20group-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa1e36f940a58a7f297_Mask%20group-p-800.png 800w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa1e36f940a58a7f297_Mask%20group-p-1080.png 1080w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa1e36f940a58a7f297_Mask%20group.png 1320w" className="ctas-image first" />*/}
          {/*  <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90427b501b65ea11c959_Mask%20group-1.png" loading="lazy" data-w-id="83ce114f-84d0-2bc6-c9a0-05ec9be1187a" sizes="(max-width: 767px) 90vw, (max-width: 991px) 68vw, (max-width: 1279px) 38vw, 440px" alt="Salesforce Numbers" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90427b501b65ea11c959_Mask%20group-1-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90427b501b65ea11c959_Mask%20group-1.png 1320w" className="ctas-image second" />*/}
          {/*  <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90613f05c2c1b3ed1f67_Mask%20group-2.png" loading="lazy" data-w-id="83ce114f-84d0-2bc6-c9a0-05ec9be1187b" sizes="(max-width: 767px) 90vw, (max-width: 991px) 68vw, (max-width: 1279px) 38vw, 440px" alt="Screenshot" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90613f05c2c1b3ed1f67_Mask%20group-2-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90613f05c2c1b3ed1f67_Mask%20group-2-p-800.png 800w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d90613f05c2c1b3ed1f67_Mask%20group-2.png 1320w" className="ctas-image third" />*/}
          {/*  <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa2ebf2d82cddaae8ec_Mask%20group-3.png" loading="lazy" data-w-id="83ce114f-84d0-2bc6-c9a0-05ec9be1187c" sizes="(max-width: 767px) 90vw, (max-width: 991px) 68vw, (max-width: 1279px) 38vw, 440px" alt="Abode cloud logo" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa2ebf2d82cddaae8ec_Mask%20group-3-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa2ebf2d82cddaae8ec_Mask%20group-3-p-800.png 800w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa2ebf2d82cddaae8ec_Mask%20group-3-p-1080.png 1080w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/652d8fa2ebf2d82cddaae8ec_Mask%20group-3.png 1320w" className="ctas-image fourth" />*/}
          {/*</div>*/}
        </div>
      </div>
    </section>
  );
};

export default SolutionComponent;
