import React, {useRef, useEffect, useState} from 'react';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import {MediaPlayer, MediaProvider} from '@vidstack/react';
import {defaultLayoutIcons, DefaultVideoLayout} from '@vidstack/react/player/layouts/default';
import {Poster} from '@vidstack/react';

import '../../styles/video-player-styling/player.css';

const VideoPlayer = () => {
    const playerRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);

    const handlePlay = () => {
        if (playerRef.current) {
            playerRef.current.play();
        }
    };

    useEffect(() => {
        const player = playerRef.current;

        const handlePlaying = () => {
            setIsPlaying(true);
            setHasStarted(true);
        };

        const handlePause = () => {
            setIsPlaying(false);
        };

        if (player) {
            player.addEventListener('playing', handlePlaying);
            player.addEventListener('pause', handlePause);

            return () => {
                player.removeEventListener('playing', handlePlaying);
                player.removeEventListener('pause', handlePause);
            };
        }
    }, []);

    return (
        <div className={`video-player ${isPlaying || hasStarted ? 'playing' : ''}`}>
            <MediaPlayer
                ref={playerRef}
                crossOrigin
                // playsInline TODO: Look at which is better for mobile
                title="Pabloo | Company Video"
                src="https://www.youtube.com/watch?v=mVTksfdKOzo"
            >
                <MediaProvider>
                    <Poster
                        className="vds-poster"
                        src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/pabloo%2Fpabloo-thumbnail-HD.png?alt=media&token=0cb782ea-3fcf-4d90-af93-72437a54a1f1"
                        alt="Pabloo | Company Video Thumbnail"
                    />
                </MediaProvider>
                <DefaultVideoLayout icons={defaultLayoutIcons}/>
            </MediaPlayer>
            {!hasStarted && (
                <div className="central-play-button" onClick={handlePlay}>
                    <svg
                        fill="#ffffff"
                        width="55"
                        height="55"
                        viewBox="-4 -3 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMinYMin"
                        className="jam jam-play"
                    >
                        <path
                            d="M13.82 9.523a.976.976 0 0 0-.324-1.363L3.574 2.128a1.031 1.031 0 0 0-.535-.149c-.56 0-1.013.443-1.013.99V15.03c0 .185.053.366.153.523.296.464.92.606 1.395.317l9.922-6.031c.131-.08.243-.189.325-.317zm.746 1.997l-9.921 6.031c-1.425.867-3.3.44-4.186-.951A2.918 2.918 0 0 1 0 15.03V2.97C0 1.329 1.36 0 3.04 0c.567 0 1.123.155 1.605.448l9.921 6.032c1.425.866 1.862 2.696.975 4.088-.246.386-.58.712-.975.952z"/>
                    </svg>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
