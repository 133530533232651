// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solution-view {
  width: 50%;
}

@media (max-width: 768px) {
  .solution-view {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/solution-view.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".solution-view {\n  width: 50%;\n}\n\n@media (max-width: 768px) {\n  .solution-view {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
