import { useState } from "react";
import { Switch } from "@headlessui/react";
import { Radio, RadioGroup } from "@headlessui/react";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const frequencies = [
    { value: "monthly", label: "Monthly" },
    { value: "annually", label: "Annually" },
];

export default function PricingToggle({ frequency, onFrequencyChange }) {
  const [enabled, setEnabled] = useState(false);

  return (
    <RadioGroup
      value={frequency}
      onChange={onFrequencyChange}
      className="flex justify-center items-center space-x-4 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
    >
      {frequencies.map((option) => (
        <RadioGroup.Option
          key={option.value}
          value={option.value}
          className={({ checked }) =>
            classNames(
              checked ? "bg-green-700 text-white" : "text-gray-500",
              "cursor-pointer rounded-full px-4 py-2"
            )
          }
        >
          {({ checked }) => (
            <span
              className={classNames(
                checked ? "text-white" : "",
                "cursor-pointer"
              )}
            >
              {option.label}
            </span>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
}
