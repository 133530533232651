import React, { useState } from "react";
import CTAButtonRight from "../generalComponents/ctaButtonRight.jsx";
import CTAButtonLeft from "../generalComponents/LeftctaButton.jsx";
import "../../styles/contact/style.css";
import "../../styles/contact/button-center.css";
import { sendSlackMessage } from "../../models/notifications.js";

export default function ContactForm() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const isFormValid =
    fullName.trim() !== "" && email.trim() !== "" && website.trim() !== "";

  return (
    <div>
      <div className="ze ug oe">
        <div className=" wi">
          {/*TODO: Send the viewer to a form submitted page - only if all the values were provided - but always send the payload */}
          {/*TODO: Needs to be a different form submission page from the regular campaign. Make two campaigns and send them through different routes. */}
          <form action="src/components/home#" method="POST" className="ge">
            <div>
              <label htmlFor="fullName" className="vf dg jg">
                {" "}
                Your name{" "}
              </label>
              <div className="rb">
                <input
                  type="text_"
                  name="fullName"
                  id="fullName"
                  placeholder="Enter your full name"
                  className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                  style={{
                    color: "#000000",
                  }}
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="vf dg jg">
                {" "}
                Email address{" "}
              </label>
              <div className="rb">
                <input
                  type="email_"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                  style={{
                    color: "#000000",
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
            <div>
              <label htmlFor="website" className="vf dg jg">
                Company website
              </label>
              <div className="rb">
                <input
                  type="url_"
                  name="website"
                  id="website"
                  placeholder="Enter your company's website"
                  className="vb lc hf of vf jg ze pe ue ne hh gh jh lh"
                  style={{
                    color: "#000000",
                  }}
                  value={website}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </div>
            </div>
            <div id="button-center" className="">
              <CTAButtonRight
                text="Submit"
                onClick={() => {
                  window.open("#submitted", "_self");
                  handleSubmission();
                }}
                isValid={isFormValid}
                isSubmitted={isFormValid}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  function handleSubmission() {
    if (isFormValid) {
      const myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        type: "form_submission",
        name: fullName,
        email: email,
        website: website,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://server.pabloo.com/website/handle_submission",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);

          window.gtag("event", "form_submission", {
            event_category: "Contact Form",
            event_label: "Submit for review",
            name: fullName,
            email: email,
            website: website,
          });
        })
        .catch((error) => console.error(error));
    } else {
      alert("Please complete all fields.");
    }
  }
}
