import React from "react";
import CTAButtonRight from "./generalComponents/ctaButtonRight";
import { PricingCollage } from "../assets/assets";
import {sendSlackMessage} from "../models/notifications";

const SecondaryPricingComponent = () => {
  return (
    <div
      id="pricing"
      className="pricing-hero-table"
      style={{ marginBottom: "80px" }}
    >
      <div className="pricing-table-left">
        <div className="html-embed-2 w-embed w-script">
          <div id="ajaxContent" />
          {/*<img src={PricingCollage} width="750" height="500" />*/}
          <video
            src={PricingCollage}
            width="750"
            height="500"
            loop
            autoPlay
            muted
          ></video>
        </div>
      </div>
      <div className="pricing-table-right w-clearfix">
        <div className="price-table-heading">What’s in it for you:</div>
        <div className="price-point-contain">
          <div className="price-point-wrapper price-point-seperator-wrapper">
            <div className="price-point bold price-point-seperator">
              Product
            </div>
          </div>
          {cellView("Simple, flat-rate pricing")}
          {cellView("Refund & cashback")}
          {cellView("Full mobile compatibility")}
          {cellView("Pay-By-Bank checkout")}
          {cellView("Loyalty program")}
          {cellView("Granular data tracking")}

          <div className="price-point-wrapper price-point-seperator-wrapper">
            <div className="price-point bold price-point-seperator">
              Services
            </div>
          </div>
          {cellView("AI powered assistant")}
          {cellView("Dedicated human assistant")}

          <div className="price-point-wrapper price-point-seperator-wrapper">
            <div className="price-point bold price-point-seperator">
              Savings
            </div>
          </div>
          <div className="price-point-wrapper">
            <img
              src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/61f43727b1956b51bd6f9dcd_ic-fluent-checkmark-circle-24-filled%201%20(1).svg"
              loading="lazy"
              alt="Pabloo Success Story"
              className="checkmark"
            />
            <div
              className="price-point bold"
              style={{
                color: "#15803d",
              }}
            >
              Save up to 64% of your payment processing expenses.
            </div>
          </div>
        </div>
        <div id="button-center-pricing">
        <CTAButtonRight
          text="Get started for free"
          onClick={() => {
            window.open("https://apps.shopify.com/pabloo-store-credit", "_blank");

            sendSlackMessage("Someone has clicked on the Install on Shopify from the pricing page - What's in it for you.", "#website-updates");
            window.gtag("event", "conversion_button_clicked", {
              button: "view_shopify_listing",
              source: "Secondary Pricing Component",
            });
          }}
        />
        </div>
      </div>
    </div>
  );

  function cellView(text) {
    return (
      <div className="price-point-wrapper">
        <img
          src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/61f43727b1956b51bd6f9dcd_ic-fluent-checkmark-circle-24-filled%201%20(1).svg"
          loading="lazy"
          alt="Pabloo Success Story"
          className="checkmark"
        />
        <div className="price-point">{text}</div>
      </div>
    );
  }
};

export default SecondaryPricingComponent;
