//React imports
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom/client";

//Imports the components
import FooterComponent from "../components/home/FooterComponent.js";
import NavBarComponent from "../components/navBar/NavBarComponent.js";

import CaseStudyComponent from "../components/casestudy/CaseStudyComponent.js";
import CaseHero from "../components/casestudy/CaseHero.js";

import BalodanaStudy from "../components/casestudy/listOfCaseStudies/BalodanaStudy";
import ParksProjectStudy from "../components/casestudy/listOfCaseStudies/parksProjectStudy";
import PlantedMaterialStudy from "../components/casestudy/listOfCaseStudies/plantedMaterialStudy";
import StzyStudy from "../components/casestudy/listOfCaseStudies/stzyStudy";


export default function CaseStudy() {

    const searchParameters = new URLSearchParams(window.location.search);
    const pageName = searchParameters.get("page");
    let availablePages = ["balodana", "parks-project", "planted-material", "stzy"];

    const [page, setPage] = useState((Boolean(pageName) && availablePages.includes(pageName)) ? pageName : undefined)


    return (
        <div className="updated-page-wrapper">
            <NavBarComponent />
            <div className="">
                {
                    !Boolean(page) &&
                        <div>
                            <CaseHero />
                            <CaseStudyComponent />
                            {/*<CtaComponent />*/}
                        </div>
                }
                {
                    Boolean(page) &&
                    <div className="mt-12" />  // Adds margin so the navigation doesn't overlap the page content
                }
                {
                    page === "balodana" &&
                        <div>
                            <BalodanaStudy />
                        </div>
                }
                {
                    page === "parks-project" &&
                        <div>
                            <ParksProjectStudy />
                        </div>
                }
                {
                    page === "planted-material" &&
                        <div>
                            <PlantedMaterialStudy />
                        </div>
                }
                {
                    page === "stzy" &&
                        <div>
                            <StzyStudy />
                        </div>
                }


                <div>
                    <FooterComponent />
                </div>
            </div>
        </div>
    );
}
