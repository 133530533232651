//React imports
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';


//Imports the components

import FooterComponent from '../components/home/FooterComponent.js';
import NavBarComponent from '../components/navBar/NavBarComponent.js';

import HeaderComponent from '../components/HeaderComponent.js';
import {sendSlackMessage} from "../models/notifications";


export default function ScheduleCall() {


    const script = document.createElement("script");

    useEffect(() => {
        script.src = "https://assets.calendly.com/assets/external/widget.js";
        script.async = true;
        document.body.appendChild(script);

        sendSlackMessage("Visitor is visiting the Schedule a call page", "#website-updates")
    }, []);

    return (
        <div>

            <NavBarComponent />
            <div className="updated-page-wrapper">

                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>

                    <div className="calendly-inline-widget" data-url="https://calendly.com/pablooinc/25min" style={{
                        paddingTop: "4rem",
                        minWidth: "320px",
                        height: "100vh"
                    }}></div>

                </div>
                <FooterComponent />

            </div>
        </div>

    );
}

