import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";

import { Component } from "react";

function BalodanaStudy() {
    return (
        <div>
            <section className="section-58 case-study-hero-section">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-669">
                        <div className="case-study-hero-container">
                            <div className="case-study-hero-logo-container">
                                <img
                                    loading="lazy"
                                    alt=""
                                    src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FbalodanaLogo.png?alt=media&token=8cb7a9cf-ecfa-4720-8afd-6d035fab2017"
                                    sizes="(max-width: 479px) 93vw, (max-width: 767px) 60vw, (max-width: 991px) 57vw, 563.984375px"
                                    srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FbalodanaLogo.png?alt=media&token=8cb7a9cf-ecfa-4720-8afd-6d035fab2017 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FbalodanaLogo.png?alt=media&token=8cb7a9cf-ecfa-4720-8afd-6d035fab2017 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FbalodanaLogo.png?alt=media&token=8cb7a9cf-ecfa-4720-8afd-6d035fab2017 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FbalodanaLogo.png?alt=media&token=8cb7a9cf-ecfa-4720-8afd-6d035fab2017 2356w"
                                    className="case-study-hero-logo"
                                />
                            </div>
                            <div className="case-study-hero-heading-container">
                                <h1 className="case-study-hero-heading-2">
                                    Tech Marketing Pioneer Turned Fashion Entrepreneur: Dana
                                    Todd’s Story
                                </h1>
                            </div>
                            <div className="case-study-hero-metrics-container">
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade208-93cb77c1">
                                    <h2 className="heading-241">100+</h2>
                                    <div className="text-block-293">hours saved</div>
                                </div>
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade20d-93cb77c1">
                                    <h2 className="heading-241 w-dyn-bind-empty" />
                                    <div className="text-block-293 w-dyn-bind-empty" />
                                </div>
                                <div
                                    id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade212-93cb77c1"
                                    className="hide"
                                >
                                    <h2 className="heading-241">4x</h2>
                                    <div className="text-block-293">roi</div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade217-93cb77c1"
                            className="div-block-674"
                        >
                            <img
                                loading="lazy"
                                alt=""
                                src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35"
                                sizes="(max-width: 479px) 93vw, (max-width: 767px) 72vw, (max-width: 991px) 68vw, (max-width: 1919px) 676.796875px, 940px"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd351436w"
                                className="image-210"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-60">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-681">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade21c-93cb77c1">
                            <div>
                                <div className="text-block-290">headquarters</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">United States</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade223-93cb77c1">
                            <div>
                                <div className="text-block-290">industry</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">Women's Fashion</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade22a-93cb77c1">
                            <div>
                                <div className="text-block-290">founded</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">2019</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-59">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-670">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade234-93cb77c1">
                            <div className="div-block-686">
                                <p className="case-study-hero-sub-heading">
                                    Dana Todd, the founder, and CEO of Balodana — an e-commerce
                                    marketplace specializing in custom made-to-measure clothing
                                    for women. Dana began her professional journey in digital
                                    marketing and held various positions as Chief Marketing
                                    Officer across several industries before starting Balodana. In
                                    2017, Dana had a frustrating shopping experience related to
                                    sizing and, after conducting further research, she discovered
                                    that over 75% of women faced the same problem with trying to
                                    navigate the inconsistent and inaccurate sizing in traditional
                                    retail and online shopping. This motivated her to launch
                                    Balodana. Today, Balodana is the only online marketplace that
                                    provides thousands of made-to-measure styles for women of all
                                    shapes, made precisely to each customer’s measurements from
                                    talented designers worldwide.
                                </p>
                            </div>

                            {/*<div className="w-dyn-list">*/}
                            {/*    <div role="list" className="w-dyn-items">*/}
                            {/*        <div role="listitem" className="w-dyn-item">*/}
                            {/*            <div className="div-block-682">*/}
                            {/*                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade236-93cb77c1">*/}
                            {/*                    <img*/}
                            {/*                        loading="lazy"*/}
                            {/*                        alt=""*/}
                            {/*                        src="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/64c23f88c82b2204b21e5311_testimonial%20website%20photo-04%20(1).png"*/}
                            {/*                        sizes="(max-width: 479px) 93vw, (max-width: 991px) 19vw, 184.78515625px"*/}
                            {/*                        srcSet="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/64c23f88c82b2204b21e5311_testimonial%20website%20photo-04%20(1)-p-500.png 500w, https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/64c23f88c82b2204b21e5311_testimonial%20website%20photo-04%20(1)-p-800.png 800w, https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/64c23f88c82b2204b21e5311_testimonial%20website%20photo-04%20(1).png 851w"*/}
                            {/*                        className="image-207"*/}
                            {/*                    />*/}
                            {/*                </div>*/}
                            {/*                <div*/}
                            {/*                    id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade238-93cb77c1"*/}
                            {/*                    className="div-block-677"*/}
                            {/*                >*/}
                            {/*                    <p className="paragraph-133">*/}
                            {/*                         proactively manages our SaaS contracts and*/}
                            {/*                        reporting, making our lives so much easier. Compared*/}
                            {/*                        to similar products we've used in the past, the*/}
                            {/*                        customer experience with  has been nothing*/}
                            {/*                        short of delightful. We no longer have to worry about*/}
                            {/*                        getting the best SaaS deals - it's all taken care of*/}
                            {/*                        on auto-pilot!*/}
                            {/*                    </p>*/}
                            {/*                    <div className="div-block-790">*/}
                            {/*                        <div className="text-block-322">Rick Schopp</div>*/}
                            {/*                        <div className="text-block-323">CFO, Wodify</div>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="div-block-683">
                                <div className="rich-text-block-28 w-richtext">
                                    <p>‍</p>
                                    <h4>
                                        Tell us about your background before starting Balodana.
                                    </h4>
                                    <p>
                                        Well, that’s a long history. Just prior to Balodana, I spent
                                        the largest chunk of the latter part of my corporate career
                                        being a chief marketing officer with a very particular
                                        expertise of digital. I’ve been a digital marketer for gosh,
                                        close to 30 years now — since literally the beginning of the
                                        internet. I first got into digital in 1996 and was mostly
                                        focused at that time on web development at my agency SiteLab
                                        in San Diego. I was there about 12 years until I left, and
                                        my partners ultimately ran it without me while I moved out
                                        here to Chicago. That experience got me up close and
                                        personal with so much emerging technology. You might imagine
                                        what those early days of the internet were like, and the
                                        commercial web — long before there was e-commerce, there
                                        were people struggling with micropayments and trying to
                                        figure out how to transact on the internet. And it’s very
                                        funny to me when I see the struggles that are happening now
                                        with cypto wallets and things like that where there’s no
                                        standardization. It was very much like that in the early
                                        days of the internet, it was a lot of fun but also very
                                        challenging. And then the marketing aspect of it started
                                        with SEO. We were doing SEO in, probably 1997, when there
                                        really weren’t even many search engines back then. There was
                                        no Google, there was AltaVista, Infoseek, and most were
                                        pretty easily gamed. We had a lot of success in those early
                                        years just learning something that was brand new and
                                        building the industry. I remained involved in digital
                                        marketing pretty much the last 25 years, either on the
                                        services side, the agency side, or the corporate side. When
                                        I transitioned into corporate, I ran global marketing and
                                        sales for a big agency. Then I moved into telecom, crime
                                        scene cleanup, and all sorts of weird businesses to be a
                                        chief marketing officer.
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    The lack of SaaS renewal tracking also led to negotiations
                    being done at the last minute. Trying to gather the relevant
                    pricing benchmarks and contract designing expertise for each
                    SaaS tool, drained time and resources. But optimizing SaaS
                    costs was the need of the hour.
                  </p> */}
                                    <p>‍</p>
                                    <h4>
                                        What made you want to take the risk of just leaving that and
                                        jumping straight into marketing?
                                    </h4>
                                    <p>
                                        Well, my journalism degree is actually in advertising. I
                                        started out as a real journalist though — I was an editor
                                        and newspaper writer for a few years. To be honest, I didn’t
                                        love it. I mean, I’m a good writer, but I didn’t love the
                                        way it was at all newspapers back then: I didn’t really love
                                        the politics, I didn’t love the terrible salaries. When I
                                        graduated in ’91, the average salary of journalists was
                                        $14,000 and that was poverty wages even then. So I went back
                                        to school, finished my degree in advertising, and then
                                        started working at an ad agency right out of college. I
                                        stayed on the advertising side of things and ultimately
                                        moved upstream into marketing, marketing strategy, and all
                                        of that.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        My early clients in advertising were tech clients and it was
                                        cool because I loved technology and would just talk to
                                        engineers. We had to really get familiar with engineering
                                        concepts and be able to present them in a way that was
                                        consumable. And I really enjoyed that task. So then when the
                                        internet became a thing, I remember my partner at the agency
                                        that we had, she and I went to tour a web development
                                        company in 1996. And my god, I had never seen the internet
                                        like that before. We had AOL, where any time you pressed the
                                        “worldwide web” button, it would just crash my computer. So
                                        as far as I knew, there was nothing out there except
                                        explosions and dialup modem. When I was able to see what a
                                        web page looked like, just to see what was possible with
                                        hyperlinking, it blew me away like nothing else. And I
                                        remember going to lunch with my partner after that and
                                        saying, “This is really going to change everything. This is
                                        amazing.” And she was so negative about it. She said “We’ll
                                        never make any money at it”. We parted ways shortly after
                                        and that’s when I started working for that web development
                                        firm. And within about a year, I ended up purchasing it with
                                        some partners. I became a business owner at age 32!
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    Wodify also evaluated SaaS management solutions such as
                    Tropic and Vendr but eventually chose  because the
                    concierge onboarding enabled them to adopt the solution
                    quickly and smoothly.
                  </p> */}
                                    <p>‍</p>
                                    <h4>
                                        What was it like in the very early stage? How would you keep
                                        pushing forward and keep inventing new things?
                                    </h4>
                                    <p>
                                        I’m a problem solver. So for us at SiteLab, it was always
                                        about solving a problem. A client would have a problem — ”I
                                        need to do this” or “I need to get more of that” and we
                                        would just figure it out. We had an engineering team, we had
                                        coders, we had graphic artists, and we had people who were
                                        business thinkers. And we were right there when people were
                                        literally building new things on the internet to solve
                                        problems, and today I’m still building things to solve
                                        problems. All innovation starts with a problem and I was
                                        very lucky to have clients who had budgets and would be just
                                        like, yeah, sure, let’s just do that. The first website I
                                        ever worked on was a million-dollar website! A million
                                        dollars because it was so expensive to build back then and
                                        there were very, very few people who could even code simple
                                        HTML. The starting salary for a basic web programmer in San
                                        Diego was $80k, so it was nuts. We differentiated because we
                                        were out there moving fast and there were no limits other
                                        than the technology itself and what we were capable of
                                        doing. We were working with VRML, video in panormic 360,
                                        Flash technology that was a precursor to the web animation
                                        that we have now. Flash was not just animation, you could do
                                        computational stuff with it as well. And all of this was
                                        happening while we still only had 14 web-safe colors, so it
                                        was a very bizarre time, and you could really almost do no
                                        wrong. I wish I knew then what I knew now, I would have made
                                        some serious investments!
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        Moving on to what you’re working on now, you went from
                                        marketing and now you’re in the apparel space. Tell us about
                                        that.
                                    </h4>
                                    <p>
                                        Working corporate and being involved in operations made me a
                                        lot less afraid to be client side and to actually be
                                        responsible for the business itself, not just talking about
                                        the business or performing marketing the services. It’s very
                                        different being on the side of having a company where you
                                        have to make and eat your own dog food every day. The
                                        impetus for the company’s business model came from my own
                                        frustration with trying to find clothes to fit. I didn’t
                                        think I should be that difficult to fit, but apparently, I
                                        was, particularly when I looked at the kind of clothes that
                                        I wanted to buy that were very high quality and had a little
                                        bit of edge to them. There simply weren’t many available
                                        options that weren’t made for a perfect, very young body,
                                        which really frustrated me. I was like, “I’ve got money. Why
                                        can’t I buy the clothes that I want?”
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        So it seemed to me, as a business strategist, that this was
                                        an opportunity. And the more I investigated it, the more I
                                        realized that we as a nation have outsourced all of our
                                        manufacturing innovation as it relates to garments to other
                                        countries, and have for quite some time. It’s almost like
                                        the industrialization of the food industry, right?
                                        Everything’s factory-farmed. We didn’t even know or ask what
                                        was in our food until recently, because we’ve gotten so far
                                        away from living on farms that we just assumed everything
                                        was fine. The FDA takes care of that, right? So the same
                                        thing with clothing. The garment industry is largely outside
                                        the United States, all the making and innovation and
                                        technology. But there are no standards. I’ve never seen
                                        anything like it. As somebody who comes from a data
                                        background, I saw where the reason the internet works is
                                        that we all agree on what a JPG is, for example. We all
                                        agree on a certain set of standards that make everything
                                        interoperable. There is no such thing in the garment
                                        industry, it is just an absolute disaster.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        So knowing that that was only part of the big problem, but
                                        at least something that I could get my head around from a
                                        technology perspective, I thought “If there are no standards
                                        and you’ll never win this game of trying to guess size based
                                        on the limited amount of information on a sizing chart, why
                                        can’t you just have things custom made?” I was buying
                                        custom-made clothes off Etsy but I despised the experience
                                        there. I wondered why isn’t there a Macy’s.com where
                                        everything is made to measure? Why isn’t the experience very
                                        simple? Where your measurements are uploaded, you can adjust
                                        them if you gain weight or lose weight, and then you can
                                        just shop normally. Why doesn’t this exist out there?
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        I was really surprised to find when I did the research that
                                        there was no competition that was a marketplace approach.
                                        Everyone who did custom clothing was single-brand only or on
                                        broad marketplaces like Etsy or Amazon. Custom clothing for
                                        most consumers means T-shirts or high-end business suits or
                                        formal wear, but there’s a whole lot of other clothes in
                                        between there that could be customized. When I researched
                                        it, I realized it was a billion-dollar opportunity. There’s
                                        about 10% of women who have experience already with
                                        custom-made clothes, particularly women who are
                                        generationally associated with immigrants within a couple of
                                        generations. They may even go to other countries to have
                                        things made. It’s more standard behavior, not just for
                                        weddings, and it’s demystified for them. So if 10% was
                                        already predisposed, I figured I could convince another 10%
                                        on top of that to give it a shot. We built Balodana as a
                                        marketplace to allow us to keep the prices low because one
                                        of the big myths around custom clothing is that it’s
                                        expensive. Because we are a marketplace model and our
                                        business model is commission based, we can price things as
                                        low as $60. It’s crazy cheap compared to what you might
                                        expect for the quality that’s being delivered.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        Right now we’re in the middle of a pivot where we’re going
                                        to offer our custom manufacturing services to other brands.
                                        I have 12 makers in 10 countries and a platform to offer any
                                        brand out there — Lululemon, Levi’s, etc. — to have an
                                        “infinity size” button on their website. This would increase
                                        their chances of gaining a 10% to 20% sales lift. This
                                        estimate is based on our conversations with retailers of
                                        about how people, particularly women, are leaving the
                                        website empty handed because they just don’t see themselves
                                        represented in the sizing guide.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        How did you convince your cofounder to join you in your
                                        business?
                                    </h4>
                                    <p>
                                        I had recruited Nisha originally as an intern at a Telecom
                                        company in Chicago, and I was really impressed with her
                                        work. We were together there for a couple of years before
                                        the company shut down our whole department. When I had to
                                        lay her off I was like, I’m so sorry, let me help you find a
                                        job. She knew that I had a side gig going, that I was
                                        thinking about this startup. I told her, “Well, this may be
                                        the sign from the universe that I needed. I’m just gonna go
                                        ahead and see if this thing has legs and start building.”
                                        And she said, “Dana you know I have a huge interest in
                                        sustainable fashion, right? How would it be if I joined
                                        you?” She is just such an amazing person to work with. She’s
                                        got more than just a sense of style, from clothing and
                                        design and building our brand, but also she has a really
                                        strong organizational mind and thinks through the
                                        onboarding, how we present ourselves, and how we do business
                                        with our partners and our customers.
                                    </p>
                                    <p>‍</p>
                                    <h4>Could you tell us about your trough of sorrow?</h4>
                                    <p>
                                        We launched Balodana in 2019 and for us, COVID was
                                        absolutely devastating. We had just started seeing sales
                                        grow and having our first repeat customers, getting some
                                        traction. We had invested a lot into our rollout with a
                                        national PR tour, and everything was just ticking along the
                                        way it should be in that nice little upward graph. And then
                                        all of a sudden, no one in the United States was even
                                        wearing pants, and they didn’t for two years. Business
                                        flatlined and we really had to hunker down. We had to cut
                                        our marketing spend. We had to put everything on hold
                                        indefinitely. So that really sucked.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        What we did though was say okay, we’re going to go back to
                                        school. We were lucky that so many corporate internships
                                        were canceled in 2020, leaving us a line of candidates out
                                        the door for engineering work and marketing work. We ended
                                        up adopting 12 interns that summer and we directed the teams
                                        to work on different needs of the company. We had one team
                                        building an integration hub for all the data that we get out
                                        of body scanners. Our marketing strategy team interviewed
                                        customers, 10 who bought and 10 who didn’t, to understand
                                        their reasons and to really double-check a lot of our
                                        assumptions. We went back to our “why”. Why are we doing
                                        this? Have we made the right assumption? We knew that the
                                        pandemic would eventually end and we needed to be in a
                                        better place for it. I also spent time reaching out to
                                        industry peers around the globe. They were all hungry for
                                        collaboration, and I ended up volunteering with the IEEE to
                                        work on standards for body measurements. I also created
                                        another working group of my own to just try to solve
                                        problems. I have now been in the fashion industry for only
                                        five years and have a pretty impressive network of people in
                                        the fashion tech side of things, and the growing list of
                                        innovations is just staggering. It reminds me of 1996 in
                                        many, many ways because we are in a state of disruption now
                                        on the tech side of fashion.
                                    </p>
                                    <p>‍</p>
                                    <h4>What does the future look like for you?</h4>
                                    <p>
                                        The near-term future for us is the expansion of our
                                        business-to-business service, as I previously mentioned.
                                        What I realized is that I no longer have the marketing
                                        budget I did four years ago. So I’m not going to be able to
                                        fund consumer acquisition at a rapid pace. But there is a
                                        huge opportunity in the market for medium size designers and
                                        brands ($1M-$100M) to do a couple of different things. One
                                        is to be able to get small batch manufacturing of a garment
                                        line. If you’re a designer in the United States right now
                                        and you want to get your clothes made to go to market, you
                                        either have to pay insanely high costs per garment or you
                                        have to commit to very high minimum order quantities. A
                                        brand might not be able to get off the ground with a single
                                        style for less than $10,000. And that’s a lot when you’re
                                        trying to introduce even a small collection of clothes. What
                                        we found is that there is no one out there really filling
                                        that opportunity from a manufacturing perspective. And
                                        secondarily, we are offering the ability do custom grading
                                        (calculating the different sizes offered) including the
                                        option for made-to-measure. We recently debuted our new
                                        package at Sourcing@MAGIC, which is like CES for fashion.
                                        The designers and brands that we talked with there are
                                        really taken with the idea so we know that there’s an
                                        opportunity here.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        Our vision is still the same: make every woman in America
                                        realize that she has an option for custom-made clothing, and
                                        that it’s one the most sustainable ways to buy clothes.
                                        You’re not going to have as many returns. You’re not going
                                        to have the sadness of thinking that your body can’t be
                                        clothed properly. If I can create that by partnering with
                                        other brands, our mission is accomplished. We’ve had four
                                        years now of experiences, failures, and successes. We know
                                        how it works. We know how it doesn’t work. So by having gone
                                        through that, we’re able to offer that experience now for
                                        brands in a risk-free way.
                                    </p>
                                    <p>‍</p>
                                    <h4>What’s your secret to success?</h4>
                                    <p>
                                        My mom used to say that I was stupid brave. I’m stubborn and
                                        I don’t give up easily. That’s one big part of it. The other
                                        part is curiosity, partly from being a journalist. But my
                                        whole life, and the reason why I succeeded in the early days
                                        of technology without any technology background, was being
                                        curious, staying curious, asking questions, and trying to
                                        really understand context and problems. To me, that is
                                        always one of the best traits that you can have. And
                                        generally speaking, I think everybody I’ve worked with would
                                        tell you that I have high integrity in business. It matters,
                                        especially when you’re dealing with people around the world.
                                        They have to trust you, you have to trust them.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        It was a privilege to delve into Dana’s background to hear
                                        about her professional journey, especially as the founder of
                                        Balodana. Dana’s journey with Balodana is a great example of
                                        how encountering a vexing problem can spark innovative
                                        solutions. Dana’s extensive background in digital marketing
                                        and her realization of a widespread sizing issue in retail
                                        led her to a solution that is filling a significant gap in
                                        the online retail market, providing a much-needed solution
                                        for women of all sizes and shapes. To learn more about
                                        Balodana visit https://www.balodana.com/ and for more
                                        information on Dana visit
                                        https://www.linkedin.com/in/danatodd/.
                                    </p>
                                    <p>‍</p>
                                    {/* <h4>What’s your formula for success?</h4>
                  <p>
                    Stay focused, solve a problem, and get the right people to
                    help you. It’s so case-specific and I’m saying in terms of
                    the industry you’re in, the product you’re offering, the
                    cultural movement that’s happening, the timing of how people
                    feel, and if they connect with the brand, product, offering,
                    or service. It’s such a dynamic environment and one thing
                    that works now may not work 5 years down the road. So
                    there’s a lot of change that’s always happening and I think
                    that timing is one of the most critical components of an
                    idea taking off.
                  </p>
                  <p>‍</p>
                  <h4>
                    Thank you for your time, is there anything else you would
                    want to add?
                  </h4>
                  <p>
                    Yea, one of the most beautiful things about this country is
                    that people can come up with ideas and usually get some sort
                    of funding to try and innovate. Bigger companies are slower
                    to move, smaller companies are usually nimble and fast with
                    a lot of ideas and innovation. That’s what keeps powering
                    our economy. You don’t have to have a worldwide
                    distribution, you can also be solving a problem that’s
                    hyperlocal and you can build a very healthy small business.
                    So every idea will find its end destination and it just
                    takes that hustle and grit to keep moving things forward so
                    just go get it! We were honored to learn about Keith
                    Eshelman’s journey with Parks Project — one that’s been
                    fueled by passion and purpose from the beginning. As Parks
                    Project continues to grow as a business, so does the
                    strength of its mission to protect and preserve national
                    parklands to leave them better than they found them. To
                    learn more about Parks Project and how you can contribute to
                    this mission visit: https://www.parksproject.us/ and for
                    more information on Keith visit:
                    https://www.linkedin.com/in/keith-eshelman-a972473/.
                  </p>
                  <p>‍</p> */}
                                    {/*<figure*/}
                                    {/*    style={{ maxWidth: "627px" }}*/}
                                    {/*    className="w-richtext-align-fullwidth w-richtext-figure-type-image"*/}
                                    {/*>*/}
                                    {/*    <div>*/}
                                    {/*        <img*/}
                                    {/*            src="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/64e6112e03f8956055345af4_Screenshot%202023-08-23%20193111.png"*/}
                                    {/*            loading="lazy"*/}
                                    {/*            alt=""*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</figure>*/}
                                    {/*<p>‍</p>*/}
                                    {/* <h4>Results</h4>
                  <p>
                    With the help of , Wodify’s was able to put SaaS
                    buying, and management on autopilot. They were able to
                    customize each SaaS contract specifically for their Wodify’s
                    use cases and ensure they are getting the best deal.
                  </p>
                  <p>‍</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BalodanaStudy;
