//React imports
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

//Imports the components
import CarouselComponent from '../components/CarouselComponent.js';
import CalculatorComponent from '../components/calculator/CaculatorComponent.js';
import SolutionComponent from '../components/SolutionComponent.js';
import FooterComponent from '../components/home/FooterComponent.js';
import HeaderComponent from '../components/HeaderComponent.js';
import PricingComponent from '../components/pricing/PricingComponent.js';
import GuaranteeComponent from '../components/GuaranteeComponent.js';
import SecondaryPricingComponent from '../components/SecondaryPricingComponent.js';

//Imports assets such as images
import pabloo_transparent_logo_cropped from '../assets/pabloo_transparent_logo_cropped.png';
import NavBarComponent from '../components/navBar/NavBarComponent.js';
import {handleSubmission} from "../models/notifications";




function PricingPage() {
  useEffect(() => {
    const sendPricingPageVisitEvent = () => {
      window.gtag('event', 'pricing_page_visit', {
        'event_callback': () => {
          window.gtag('get', 'G-8G0V7WWP5L', 'client_id', (clientId) => {
            if (!window.pricingPageVisitEventSent) {
              window.pricingPageVisitEventSent = true;
              handleSubmission({
                type: 'pricing_page_visit',
                clientId: clientId,
              }, () => {})

            }
          });
        }
      });
    };
    sendPricingPageVisitEvent();
  }, []);
  return (
    <div>
      <div className="updated-page-wrapper">

        {/* stupid banner at top of page */}
        {/* <div className="new-hello-bar">
          <a href="https://www..com/integrations/slack" className="hello-bar-link"><strong className="bold-text-34">Procurement workflows that adapt to you. On Slack. </strong><strong className="bold-text-35">See how ➔</strong></a>
        </div> */}


        <NavBarComponent />

        <HeaderComponent page="pricing" />


        <section className="section-68" style={{ paddingTop: '1px', paddingBottom: '80px' }}>
          <PricingComponent />
        </section>

        <section className="section-68 space-y-8" style={{ paddingTop: '0px', paddingBottom: '80px' }}>
          <GuaranteeComponent />

          <CarouselComponent />
        </section>

        <SecondaryPricingComponent />

        <CalculatorComponent />

        <SolutionComponent />

        <FooterComponent />
      </div>
    </div>
  );
}


export default PricingPage;
