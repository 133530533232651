import React from "react";
import NavBarComponent from "../components/navBar/NavBarComponent";
import ListOfFeatures from "../components/features/listOfFeatures";
import FooterComponent from "../components/home/FooterComponent";


export default function Features() {

    return (
        <div>
            <NavBarComponent />
            <ListOfFeatures />
            <FooterComponent />
        </div>
    )
}