import React from "react";
import "../../styles/faq.css";

const FaqComponent = () => {
  return (
    <div
      className="mx-auto mt-32 max-w-4xl px-4 md:px-0"
      style={{ paddingBottom: "50px" }}
    >
      <div className="text-left">
        <h2 className="text-2xl font-bold text-gray-900">
          Frequently Asked Questions
        </h2>
        <p className="mt-2 text-base text-gray-600 font-pj">
          Make sure to read these questions and answers before you make your
          purchase
        </p>
      </div>
      <div className="grid w-full grid-cols-1 mx-auto mt-10 sm:mt-10 md:grid-cols-2 gap-8">
        {cellView(
          "How much can I expect to save within the first 60 days?",
          "Yes, the average savings for a Pabloo partner is 3% of their annual GMV."
        )}
        {cellView(
          "Which platforms do you support?",
          "Pabloo is compatible with Shopify, WooCommerce, and offers a public-facing SDK for external integration."
        )}
        {cellView(
          "Can I build my own store credit app with the API?",
          "Yes, the purpose of the API is to extend the main functionality of store credit to your needs."
        )}
        {cellView(
          "How do I checkout?",
          "Create an account, select the tier you wish to purchase, then proceed to payment. After the payment, you will receive an access code and a login for your Pabloo dashboard."
        )}
        {cellView(
          "What is your refund policy?",
          "If you are not happy with the product, we offer a fourteen-day money-back guarantee. Simply contact our live chat support and we will refund you."
        )}
        {cellView(
          "Is payment secure?",
          "Yes, we use Stripe for payment. We do not store any of your payment information."
        )}
        {cellView(
          "Can I use it for commercial projects?",
          "Yes, you can use it for commercial projects. We do not charge any fees for commercial use."
        )}
        {cellView(
          "Can I get support?",
          "Yes, although we cannot help you build your website. Support is mostly for technical issues related to our product."
        )}
        {cellView(
          "I'm transferring from a loyalty app, can I import customer balances?",
          "Yes, you can import your current balances using the customer import tool within the app."
        )}
      </div>
    </div>
  );

  function cellView(text, subtext) {
    return (
      <div>
        <p className="text-base font-semibold text-black">{text}</p>
        {typeof subtext === "string" ? (
          <p className="mt-3 text-sm text-gray-600">{subtext}</p>
        ) : (
          <div className="mt-3 text-sm text-gray-600">{subtext}</div>
        )}
      </div>
    );
  }
};

export default FaqComponent;
