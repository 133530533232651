import LoginComponent from "../components/signUp/loginComponent";
import NavBarComponent from "../components/navBar/NavBarComponent";
import React, {useEffect} from "react";
import FooterComponent from "../components/home/FooterComponent";
import {sendSlackMessage} from "../models/notifications";


export function Login() {

    useEffect(() => {

        sendSlackMessage("Visitor is visiting the Login page", "#website-updates")
    }, [])

    return (
        <div>
            <NavBarComponent />
            <LoginComponent />
            <FooterComponent />
        </div>
    )
}