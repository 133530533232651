//React imports
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';

//Imports the components
import FooterComponent from '../../components/home/FooterComponent.js';
import NavBarComponent from '../../components/navBar/NavBarComponent.js';
import {sendSlackMessage} from "../../models/notifications";

import ListOfPartners from "../../components/partners/listOfPartners";

import {getIPAddress} from "../../models/locationHelpers";
import HeroSection from "../../components/partners/heroSection";



export default function Partners() {
    useEffect(() => {

        getIPAddress((ipAddress) => {
            sendSlackMessage(`Visitor is visiting the partners page ${ipAddress}`, "#website-updates")
        })
    }, [])

    return (
        <div>
            <div className="updated-page-wrapper">

                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>
                    <NavBarComponent />
                    <HeroSection/>
                    <ListOfPartners />
                </div>
                <FooterComponent />

            </div>
        </div>
    );
}

