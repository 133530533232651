import React, { useState } from "react";
import { PablooDashboardImage } from "../../assets/assets";

const PointsComponent = () => {
  return (
    <section className="points-section">
      <div className="wrapper-1280px points-container">
        <div className="flex justify-center items-center">
          <div className="" style={{ width: "100%" }}>
            <div className="">
              {/*<h2 className="heading-48px points-heading">A SaaS partner that saves you <span className="points-heading-span">time and money.</span></h2>*/}
              {/*<h2 className="heading-48px points-heading">The benefits of store credit and <span className="points-heading-span">time and money.</span></h2>*/}
              <h2 className="heading-48px points-heading">
                Enhanced store credit system with{" "}
                <span className="points-heading-span">
                  more benefits & less hassle.
                </span>
              </h2>
              <div className="text-white mb-4">
                Remove the trouble from your life and use store credit for
                everything. Pabloo will help you along the way.
              </div>
              <div className="points-checklist-grid">
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b54-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Store credit technology
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b58-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Spend analysis powered by AI
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b5c-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Pay-by-bank integration
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b60-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Loyalty credit
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b64-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Global currency support
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b68-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Shipping and tax credit
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b6c-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Cart abandonment credit{" "}
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b70-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Rich insights data
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b74-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div className="paragraph-18px points-check-list-text">
                    Store credit upsells
                  </div>
                </div>
                <div
                  id="w-node-_24139d18-8e79-5ec2-9466-4056e3ba9b78-e3ba9b4c"
                  className="points-checklist-row"
                >
                  <div className="html-embed-38 w-embed">
                    <svg
                      width="100%"
                      height="100%"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        d="M17.5 10C17.5 11.4834 17.0601 12.9334 16.236 14.1668C15.4119 15.4001 14.2406 16.3614 12.8701 16.9291C11.4997 17.4968 9.99168 17.6453 8.53682 17.3559C7.08196 17.0665 5.74559 16.3522 4.69669 15.3033C3.6478 14.2544 2.9335 12.918 2.64411 11.4632C2.35472 10.0083 2.50324 8.50032 3.0709 7.12987C3.63856 5.75943 4.59985 4.58809 5.83322 3.76398C7.06659 2.93987 8.51664 2.5 10 2.5C11.9891 2.5 13.8968 3.29018 15.3033 4.6967C16.7098 6.10322 17.5 8.01088 17.5 10Z"
                        fill="#F7E8C7"
                      />
                      <path
                        d="M13.5672 7.68281C13.6253 7.74086 13.6714 7.80979 13.7028 7.88566C13.7343 7.96154 13.7505 8.04287 13.7505 8.125C13.7505 8.20713 13.7343 8.28846 13.7028 8.36434C13.6714 8.44021 13.6253 8.50914 13.5672 8.56719L9.19218 12.9422C9.13414 13.0003 9.06521 13.0464 8.98933 13.0779C8.91346 13.1093 8.83213 13.1255 8.75 13.1255C8.66786 13.1255 8.58653 13.1093 8.51066 13.0779C8.43478 13.0464 8.36585 13.0003 8.30781 12.9422L6.43281 11.0672C6.31553 10.9499 6.24965 10.7909 6.24965 10.625C6.24965 10.4591 6.31553 10.3001 6.43281 10.1828C6.55008 10.0655 6.70914 9.99965 6.875 9.99965C7.04085 9.99965 7.19991 10.0655 7.31718 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.6247 12.8098 7.5786 12.8857 7.54715C12.9615 7.5157 13.0429 7.49951 13.125 7.49951C13.2071 7.49951 13.2885 7.5157 13.3643 7.54715C13.4402 7.5786 13.5091 7.6247 13.5672 7.68281ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.5939 16.8916 13.1093 17.5065C11.6246 18.1215 9.99098 18.2824 8.41489 17.9689C6.83879 17.6554 5.39106 16.8815 4.25475 15.7452C3.11845 14.6089 2.34462 13.1612 2.03112 11.5851C1.71761 10.009 1.87851 8.37535 2.49347 6.8907C3.10844 5.40605 4.14984 4.1371 5.48599 3.24431C6.82214 2.35152 8.39302 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.875 8.64025 16.4718 7.31104 15.7163 6.18045C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32513 3.52237 6.10012 4.17716 5.13864 5.13864C4.17715 6.10013 3.52237 7.32513 3.2571 8.65875C2.99182 9.99237 3.12797 11.3747 3.64832 12.6309C4.16868 13.8872 5.04986 14.9609 6.18045 15.7164C7.31104 16.4718 8.64025 16.875 10 16.875C11.8227 16.8729 13.5702 16.1479 14.8591 14.8591C16.1479 13.5702 16.8729 11.8227 16.875 10Z"
                        fill="#F7E8C7"
                      />
                    </svg>
                  </div>
                  <div
                      className="paragraph-18px points-check-list-text font-bold cursor-pointer"
                      onClick={() => {
                        window.open("/features", "_blank")
                      }}
                  >
                    Plus more
                  </div>
                </div>
              </div>
              {/*  <a href="/pricing" className="arrow-text-link cream w-inline-block">*/}
              {/*    <div className="arrow-text-link-top-contain">*/}
              {/*  <p className="arrow-link-text">Learn More</p>*/}
              {/*  <div className="arrow-wrapper">*/}
              {/*    <div className="button-arrow-embed _18px w-embed">*/}
              {/*      <svg width={10} height={17} viewBox="0 0 10 17" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ width: '100%', height: '100%' }}>*/}
              {/*        <path d="M2 2L8.75 8.75L2 15.5" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />*/}
              {/*      </svg>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*    <div className="text-link-underline cream" />*/}
              {/*  </a>*/}
            </div>
          </div>

          {/*<div className="points-image-contain">*/}
          {/*  <div className="points-images-top-flex">*/}
          {/*    <div className="points-image-wrapper a">*/}
          {/*      <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b241b79b8c5f875c4d4c5_Points%20image%201.svg" loading="lazy" alt="" className="points-image" />*/}
          {/*    </div>*/}
          {/*    <div className="points-image-wrapper b">*/}
          {/*      <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b26759bf37b36ccd89a60_points%20image%202.png" loading="lazy" sizes="(max-width: 767px) 38vw, (max-width: 991px) 33vw, (max-width: 1439px) 18vw, 249.39453125px" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b26759bf37b36ccd89a60_points%20image%202-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b26759bf37b36ccd89a60_points%20image%202.png 766w" alt="" className="points-image" />*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="points-image-wrapper c">*/}
          {/*    <img src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b243945dc80f316923a07_points%20image%203.png" loading="lazy" sizes="(max-width: 767px) 90vw, (max-width: 991px) 78vw, (max-width: 1439px) 41vw, 588.7890625px" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b243945dc80f316923a07_points%20image%203-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b243945dc80f316923a07_points%20image%203-p-800.png 800w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b243945dc80f316923a07_points%20image%203-p-1080.png 1080w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/657b243945dc80f316923a07_points%20image%203.png 1806w" alt="" className="points-image" />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="" style={{ width: "110%" }}>
            <div>
              <img
                src={PablooDashboardImage}
                alt={"Pabloo Dashboard Image"}
                className="points-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PointsComponent;
