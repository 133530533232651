import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";

import { Component } from "react";

function ParksProjectStudy() {
    return (
        <div>
            <section className="section-58 case-study-hero-section">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-669">
                        <div className="case-study-hero-container">
                            <div className="case-study-hero-logo-container">
                                <img
                                    loading="lazy"
                                    alt=""
                                    src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProjectLogo.png?alt=media&token=469205b2-27b5-4f4b-8738-532d5695725d"
                                    sizes="(max-width: 479px) 93vw, (max-width: 767px) 60vw, (max-width: 991px) 57vw, 563.984375px"
                                    srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProjectLogo.png?alt=media&token=469205b2-27b5-4f4b-8738-532d5695725d 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProjectLogo.png?alt=media&token=469205b2-27b5-4f4b-8738-532d5695725d 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProjectLogo.png?alt=media&token=469205b2-27b5-4f4b-8738-532d5695725d 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProjectLogo.png?alt=media&token=469205b2-27b5-4f4b-8738-532d5695725d 2356w"
                                    className="case-study-hero-logo"
                                />
                            </div>
                            <div className="case-study-hero-heading-container">
                                <h1 className="case-study-hero-heading-2">
                                    Passion, Purpose, and Parks Project — An Interview With Keith
                                    Eshelman, Co-Founder and CEO
                                </h1>
                            </div>
                            <div className="case-study-hero-metrics-container">
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade208-93cb77c1">
                                    <h2 className="heading-241">100+</h2>
                                    <div className="text-block-293">hours saved</div>
                                </div>
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade20d-93cb77c1">
                                    <h2 className="heading-241 w-dyn-bind-empty" />
                                    <div className="text-block-293 w-dyn-bind-empty" />
                                </div>
                                <div
                                    id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade212-93cb77c1"
                                    className="hide"
                                >
                                    <h2 className="heading-241">4x</h2>
                                    <div className="text-block-293">roi</div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade217-93cb77c1"
                            className="div-block-674"
                        >
                            <img
                                loading="lazy"
                                alt=""
                                src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620"
                                sizes="(max-width: 479px) 93vw, (max-width: 767px) 72vw, (max-width: 991px) 68vw, (max-width: 1919px) 676.796875px, 940px"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 1436w"
                                className="image-210"
                                style={{
                                    height: "100%",
                                    width: "100%"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-60">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-681">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade21c-93cb77c1">
                            <div>
                                <div className="text-block-290">headquarters</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">Los Angeles, CA</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade223-93cb77c1">
                            <div>
                                <div className="text-block-290">industry</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">E-commerce</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade22a-93cb77c1">
                            <div>
                                <div className="text-block-290">founded</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">2014</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-59">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-670">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade234-93cb77c1">
                            <div className="div-block-686">
                                <p className="case-study-hero-sub-heading">
                                    Keith Eshelman, the co-founder, and CEO of Parks Project- an
                                    e-commerce apparel brand on the mission to preserve parklands
                                    for generations to come by educating, advocating,
                                    volunteering, and activating park supporters to get involved
                                    in conservation. As a nature enthusiast, Keith has been able
                                    to turn his passion into a profitable business with the
                                    purpose to leave national parklands better than they found
                                    them. To date, Parks Project has contributed over 2.5 million
                                    dollars to help fund vital projects in national parks around
                                    the United
                                </p>
                            </div>


                            <div className="div-block-683">
                                <div className="rich-text-block-28 w-richtext">
                                    <p>‍</p>
                                    <h4>
                                        Tell us about your background before finding Parks Project.
                                    </h4>
                                    <p>
                                        Growing up, I spent the majority of my time living in
                                        California and was very fortunate to have access to parks,
                                        wild lands, and the Sierras on the west coast. Most of my
                                        formative business experience came from working at the
                                        company Toms where when you sell a pair of shoes, they give
                                        a pair back. That was over 10 years ago now but it opened my
                                        eyes up to a business that can contribute more than just the
                                        bottom — to align yourself with a mission and purpose of a
                                        greater good. My experience at Toms helped me come up with
                                        the idea for Parks Project which in the early days was more
                                        of a volunteer group composed of friends doing trail work
                                        and habitat restoration and things like that. Through those
                                        experiences, we learned a lot about the land of parks, the
                                        lack of funding and how every park had different needs, and
                                        how they were all trying to connect with “the next
                                        generation” so originally Parks Project was a part of that
                                        solution. I think any startup has to solve a problem, and
                                        that was one of the problems we saw and thought we could
                                        engage more people in nature conservation of parks and
                                        taking care of them. The other thing we started to see was
                                        that we didn’t think that the products sold at national
                                        parks were all that cool. We thought we could make better
                                        quality and overall better-designed products. And that
                                        naturally connected back to all the problems we’d seen in
                                        the parks and so we became Parks Project, to make better
                                        products that can help fund problems in the parks. The third
                                        layer was distribution — a lot of the national park products
                                        couldn’t be found outside of those national park stores and
                                        so we were the first maneuvers in that sense. Solving three
                                        problems for what customers come to us for now in terms of a
                                        connection to parks, our purpose, the better-made products,
                                        and also the distribution via e-commerce, Urban Outfitters,
                                        REI, and a bunch of wholesale outlets.
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    The lack of SaaS renewal tracking also led to negotiations
                    being done at the last minute. Trying to gather the relevant
                    pricing benchmarks and contract designing expertise for each
                    SaaS tool, drained time and resources. But optimizing SaaS
                    costs was the need of the hour.
                  </p> */}
                                    <p>‍</p>
                                    <h4>
                                        You mentioned that early on Parks Project was more of a
                                        volunteer group. When you guys became a business, did you
                                        guys face any challenges with being profit motivated vs
                                        staying purpose motivated?
                                    </h4>
                                    <p>
                                        Yea, anyone who was well-versed in finance would ask us how
                                        did you do that. When you’re scaling a business you need to
                                        keep the cash inside the business to fund more inventory and
                                        hire more people etc. In retrospect, it was hard to see back
                                        then but it made the business very cash-strapped but we were
                                        able to bootstrap and stay light on overhead and kind of
                                        prebook the inventory so we didn’t have to buy a bunch and
                                        hold it to wait and see if it sells. We’d book it with a
                                        whole seller so it’s almost made to order from a supply
                                        chain perspective. Eventually, we had some innovations
                                        within the business model that helped us flow the funds back
                                        to the nonprofits that were doing the work in the parks. But
                                        there were days early on when we’d get the cash pinch and
                                        would have to find a solution, find partners and credit
                                        facilities and we’d flex them all. There’s that hustle
                                        mindset where you figure it out and get it done. That’s what
                                        a lot of starting a business and growing a business is, the
                                        problems move around and you just take them as they come and
                                        get it done.
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    Wodify also evaluated SaaS management solutions such as
                    Tropic and Vendr but eventually chose  because the
                    concierge onboarding enabled them to adopt the solution
                    quickly and smoothly.
                  </p> */}
                                    <p>‍</p>
                                    <h4>
                                        Most founders resort to investor funding, why did you take
                                        the bootstrap route?
                                    </h4>
                                    <p>
                                        Number one is because it was a real cash-light business —
                                        the made-to-order products. When I look back, I think it’s
                                        very important to learn every role of a company and that’s
                                        what bootstrapping involves. You have to figure out where
                                        you’re sourcing things, how you’re going to do your
                                        packaging, and how you’re going to get lines of credit. The
                                        fast way is to hire someone who’s an expert at it but you
                                        need money and also give up equity. I think in those early
                                        days we just saw how possible it was, and obviously, we were
                                        a bit jealous, especially during the D2C ramp-up where
                                        everyone was making 30 million in sales a year and we werent
                                        able to do that because we didn’t have a lot of funding to
                                        scale that fast. I’ve always been under the impression that
                                        as fast as you come in is as fast as you’re going to go, so
                                        if you’re flashing the pan hot and doing fast distribution,
                                        you might be forgotten just as easily. So part of the
                                        bootstrap tactic was sort of a slow, healthy, and more
                                        linear strong build.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        Given how bootstrapping leaves you cash-strapped, in
                                        retrospect what were some of the costly mistakes you guys
                                        made early on?
                                    </h4>
                                    <p>
                                        We had a retail buyer with about 10 locations across the
                                        south that went bankrupt. And it was a wake-up call in doing
                                        credit checks. When they went bankrupt, we didn’t find out
                                        for a while and by the time we did, it was a lot of money
                                        owed and wouldn’t absorb that loss. So paying attention to
                                        account receivables was a big learning curve that came the
                                        hard way and we were forced to stay on top of it.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        When you go through those challenging and unexpected turns,
                                        how do you weather through the storms?
                                    </h4>
                                    <p>
                                        When I look back, those are my learning moments. You have to
                                        fail to learn and learning is unfortunately expensive. Not
                                        in terms of paying for school but the type of learning you
                                        get in trying something and it doesn’t work. It can be an
                                        expense to the business, it can be an expense emotionally
                                        and it kinda just takes you out because you are the business
                                        and the business is you, and if the business is failing,
                                        you’re failing, so there’s a lot tied into that. But when
                                        you’re in the middle of it, it seems like there’s no way out
                                        but everything over time will heal and resolve. Sometimes
                                        it’s shorter and other times it’s longer. In the early days
                                        they were much shorter because we could just bust through it
                                        but now since the business has grown, the problems are
                                        taking much longer to resolve. At the end of the day, I
                                        think to overcome those moments, you just have to believe in
                                        the vision and purpose, and if you can visualize what it’s
                                        going to look like in 5 years, just have to keep looking up
                                        and out to pursue that vision and dream to manifest it.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        Parks Project is 7 years old now, would you say you’ve hit
                                        your 5-year vision from when you initially started?
                                    </h4>
                                    <p>
                                        I’d say maybe even exceeded it. This happens a lot with
                                        athletes or high achievers, you get to this moment when you
                                        hit the 5-year vision and then it’s like oh what about the
                                        next 5? I think when we hit that moment, it took some
                                        recalibration and figuring out a smaller chunk like the next
                                        3 years but then it wasn’t much of scaling the business but
                                        more so making it a healthy business.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        In terms of day-to-day operations, how did the first few
                                        months look compared to how they look now?
                                    </h4>
                                    <p>
                                        Oh my gosh, sometimes I want to go back. I want to pack a
                                        box! It is very very different. Every year comes with
                                        different skill sets and you have to evolve and grow
                                        quickly. So year one, I was literally in a screen print
                                        shop, helping with packaging, printing labels, calling UPS,
                                        and maybe even calling online service providers for some
                                        help so it was very much a scramble. We were looking at
                                        day-to-day and week-to-week in terms of operations vs now
                                        where we are more focused on the quarter and looking at the
                                        year as a whole, so your horizon broadens. We used to do a
                                        lot of pop-up shops and I’m going to start doing that again
                                        this year because I love talking to customers. I like that
                                        interaction of getting to know why the brand resonates and I
                                        think there’s a lot to discover by staying close to your
                                        customers.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        You said that your horizon has broadened, so what does
                                        success look like for you in the next 5 years or the next 10
                                        years for Parks Project?
                                    </h4>
                                    <p>
                                        We’re trying to achieve a 5 million contribution to funding
                                        projects in the parks and were halfway there at around 2.5
                                        million with a couple more years to achieve that. It goes
                                        back to why we exist and why we started and it was to have
                                        an impact on habitats and parks. So some of it is the impact
                                        of the business, and some of it is building relationships
                                        with customers and retention instead of selling 1 thing to a
                                        lot of people. Why don’t we sell 3 things to a 3rd of those
                                        people and make a more efficient business with a better
                                        connection and maybe get them out to volunteer? When you’re
                                        starting, you kind of shotgun things but then you realize
                                        more about who your customer is and how you can form a
                                        relationship with them. Building into a little bit more of
                                        the strength of the business instead of the pure topline
                                        growth.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        In your earlier days, what do you think you could have been
                                        better at?
                                    </h4>
                                    <p>
                                        Employee relationships honestly. It was such a scramble and
                                        I think there was also a lot of fear of failure. I think it
                                        was a lot of fast action which if anyone whos been in a
                                        bootstrap startup, there are many things to do and you can
                                        never accomplish them all. A lot of the staff didn’t know
                                        what I was going through personally in terms of being
                                        responsible for the whole operation. Everyone works their
                                        tails off and it may be overseen how much everyone is giving
                                        to the growth of the business. We had some people come in
                                        early on that just made amazing contributions which I’m so
                                        grateful for and I still try to text people happy new years,
                                        thinking of you, and thanks for all you did for us.
                                    </p>
                                    <p>‍</p>
                                    <h4>What’s your formula for success?</h4>
                                    <p>
                                        Stay focused, solve a problem, and get the right people to
                                        help you. It’s so case-specific and I’m saying in terms of
                                        the industry you’re in, the product you’re offering, the
                                        cultural movement that’s happening, the timing of how people
                                        feel, and if they connect with the brand, product, offering,
                                        or service. It’s such a dynamic environment and one thing
                                        that works now may not work 5 years down the road. So
                                        there’s a lot of change that’s always happening and I think
                                        that timing is one of the most critical components of an
                                        idea taking off.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        Thank you for your time, is there anything else you would
                                        want to add?
                                    </h4>
                                    <p>
                                        Yea, one of the most beautiful things about this country is
                                        that people can come up with ideas and usually get some sort
                                        of funding to try and innovate. Bigger companies are slower
                                        to move, smaller companies are usually nimble and fast with
                                        a lot of ideas and innovation. That’s what keeps powering
                                        our economy. You don’t have to have a worldwide
                                        distribution, you can also be solving a problem that’s
                                        hyperlocal and you can build a very healthy small business.
                                        So every idea will find its end destination and it just
                                        takes that hustle and grit to keep moving things forward so
                                        just go get it! We were honored to learn about Keith
                                        Eshelman’s journey with Parks Project — one that’s been
                                        fueled by passion and purpose from the beginning. As Parks
                                        Project continues to grow as a business, so does the
                                        strength of its mission to protect and preserve national
                                        parklands to leave them better than they found them. To
                                        learn more about Parks Project and how you can contribute to
                                        this mission visit: https://www.parksproject.us/ and for
                                        more information on Keith visit:
                                        https://www.linkedin.com/in/keith-eshelman-a972473/.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ParksProjectStudy;
