import { CheckCircle } from "../../assets/assets";
import CTAButton from "../generalComponents/ctaButton";
import {sendSlackMessage} from "../../models/notifications";

export function ProblemAndSolution() {
  // TODO: This needs to animate in (right section and left section)
  return (
    <div>
      <div
        className="ctas-wrapper-copy bg-green-900"
        style={{ maxWidth: "100%" }}
      >
        <div className="ctas-container-copy">
          <div
            data-w-id="164f69ba-ce03-da92-efdc-0482584bffa7"
            className="ctas-text-wrapper-copy"
          >
            <h2 className="heading-48px-copy ctas-heading">
              You’re spending{" "}
              <span className="text-span-279">
                huge amounts of time and money
              </span>{" "}
              with online payment processors.
              {/*You’re spending huge amounts of time on security*/}
              {/*and compliance checks for <span className="text-span-279">every new SaaS tool.</span>*/}
            </h2>
            <p className="paragraph-18px-copy ctas-paragraph center-this">
              When there’s no system in place to issue store credit to your
              customers, you end up paying 3x more for refunds, and the customer
              experience suffers due to the 5-7 day processing wait.
            </p>
          </div>
          <div
            data-w-id="dbd27668-b53f-a8d4-a069-0aa13ead44c3"
            className="ctas-text-wrapper-copy-copy-copy"
          >
            <h2 className="heading-28px-copy ctas-subheading">
              You need a solution that:
            </h2>
            <div>
              <div className="div-block-793">
                <img
                  src={CheckCircle}
                  loading="lazy"
                  alt="tick"
                  className="image-242"
                />
                <p className="paragraph-18px-copy ctas-paragraph">
                  Adds credit to your customer's account
                </p>
              </div>
              <div className="div-block-793">
                <img
                  src={CheckCircle}
                  loading="lazy"
                  alt="tick"
                  className="image-242"
                />
                <p className="paragraph-18px-copy ctas-paragraph">
                  Lets your customers use your store credit at checkout
                </p>
              </div>
              <div className="div-block-793">
                <img
                  src={CheckCircle}
                  loading="lazy"
                  alt="tick"
                  className="image-242"
                />
                <p className="paragraph-18px-copy ctas-paragraph">
                  Processes credit instantly with $0 processing fees
                </p>
              </div>
              <div className="div-block-793">
                <img
                  src={CheckCircle}
                  loading="lazy"
                  alt="tick"
                  className="image-242"
                />
                <p className="paragraph-18px-copy ctas-paragraph">
                  Integrates with Shopify, WooCommerce, and offers an SDK for
                  developers
                </p>
              </div>
            </div>

            <div className="space-y-8">
              <h2 className="heading-28px-copy-copy ctas-subheading">
                Stop paying per transaction and start moving money faster
              </h2>
              <div className='flex justify-end'>
                <CTAButton onClick={() => {
                  sendSlackMessage("Someone has clicked on the Install on Shopify button from the home page.", "#website-updates")
                  window.open("https://apps.shopify.com/pabloo-store-credit", "_blank")
                }} text="Install on Shopify" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
