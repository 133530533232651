//React imports
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

//Imports the components
import CarouselComponent from "../components/CarouselComponent.js";
import CalculatorComponent from "../components/calculator/CaculatorComponent.js";
import SolutionComponent from "../components/SolutionComponent.js";
import FooterComponent from "../components/home/FooterComponent.js";
import HeaderComponent from "../components/HeaderComponent.js";
import PricingComponent from "../components/pricing/PricingComponent.js";
import GuaranteeComponent from "../components/GuaranteeComponent.js";
import SecondaryPricingComponent from "../components/SecondaryPricingComponent.js";
import ProductDeepDiveSection from "./solutionsTest/ProductDeepDiveSection.js";
import ProductComponent from "../components/product/ProductComponent.js";

//Imports assets such as images
import pabloo_transparent_logo_cropped from "../assets/pabloo_transparent_logo_cropped.png";
import NavBarComponent from "../components/navBar/NavBarComponent.js";
import { handleSubmission } from "../models/notifications";

function ProductsPage() {
  return (
    <div>
      <div className="updated-page-wrapper">
        <NavBarComponent />

        {/* <HeaderComponent page="pricing" /> */}


        <ProductComponent />



        <FooterComponent />
      </div>
    </div>
  );
}

export default ProductsPage;
