import React, { useEffect, useState } from "react";
import SliderContainer from "./SliderContainer";
import {sendSlackMessage} from "../../models/notifications";

const CalculatorComponent = () => {
  const [gmv, setGMV] = useState(1500000); // Default value for GMV
  const [orderSize, setOrderSize] = useState(80); // Default value for Average Order Size
  const [returnPercentage, setReturnPercentage] = useState(30); // Default value for Return Percentage
  const [potentialSavings, setPotentialSavings] = useState(21000); // Default potential savings

  const sliderConfigurations = [
    {
      inputId: "cal-1-input",
      progressBarId: "cal-1-progress-bar",
      selectorId: "cal-1-selector",
      selectorValueId: "cal-1-selector-value",
      selectorValueWrapId: "cal-1-selector-value-wrap",
      min: 100000,
      max: 5000000,
      step: 100000,
      defaultValue: gmv,
      label: "Gross Merchandise Value (GMV)",
      labelStyle: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "white",
        marginBottom: "50px",
      },
    },
    {
      inputId: "cal-2-input",
      progressBarId: "cal-2-progress-bar",
      selectorId: "cal-2-selector",
      selectorValueId: "cal-2-selector-value",
      selectorValueWrapId: "cal-2-selector-value-wrap",
      min: 20,
      max: 150,
      step: 5,
      defaultValue: orderSize,
      label: "Average Order Size",
      labelStyle: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "white",
        marginBottom: "50px",
      },
    },
    {
      inputId: "cal-3-input",
      progressBarId: "cal-3-progress-bar",
      selectorId: "cal-3-selector",
      selectorValueId: "cal-3-selector-value",
      selectorValueWrapId: "cal-3-selector-value-wrap",
      min: 5,
      max: 80,
      step: 5,
      defaultValue: 30,
      label: "Return Percentage",
      labelStyle: {
        fontSize: "22px",
        fontWeight: "bold",
        color: "white",
        marginBottom: "50px",
      },
    },
  ];

  const calculateSavings = (gmv, orderSize, returnPercentage) => {
    // const baseSavings = 21000; // Initial potential savings

    // console.log('Calculating savings with values:', { gmv, orderSize, returnPercentage });

    const gmvAdjustment = 0.029 * (gmv * (returnPercentage * 0.01));
    // console.log('GMV Adjustment:', gmvAdjustment);

    const orderSizeAdjustment =
      ((gmv * (returnPercentage * 0.01)) / orderSize) * 0.3;
    // console.log('Order Size Adjustment:', orderSizeAdjustment);

    const totalSavings = gmvAdjustment + orderSizeAdjustment;
    // console.log('Total Savings before multiplication:', totalSavings);

    return parseInt(totalSavings * 2);
  };

  useEffect(() => {
    // console.log('Initial calculation on mount');
    setPotentialSavings(calculateSavings(gmv, orderSize, returnPercentage));
  }, []);

  const handleGMVChange = (value) => {
    // console.log('GMV Changed:', value);
    setGMV(value);
    setPotentialSavings(calculateSavings(value, orderSize, returnPercentage));
  };

  const handleOrderSizeChange = (value) => {
    // console.log('Order Size Changed:', value);
    setOrderSize(value);
    setPotentialSavings(calculateSavings(gmv, value, returnPercentage));
  };

  const handleReturnPercentageChange = (value) => {
    // console.log('Return Percentage Changed:', value);
    setReturnPercentage(value);
    setPotentialSavings(calculateSavings(gmv, orderSize, value));
  };

  return (
    <section
      id="SaaS-Calculator"
      className="calculator-section"
      style={{ background: "#F9F2EC" }}
    >
      <div className="calculator-container">
        <div className="calculator-left">
          <div className="calculator-left-flex-wrap">
            <h2 className="heading-48px calculator-text-contain-heading">
              How much will you save with Pabloo?
            </h2>
          </div>
          <div>
            Don't let processing fees eat into your profits. You save 3% on
            every dollar you move via Pabloo. We are the only checkout system
            that is anti-processing-fees!
          </div>
        </div>
        <div className="calculator-right">
          <div className="calculator-wrapper">
            {/*<div className="calculator-heading">Here's what the average Pabloo partner saves annually:</div>*/}
            <div className="calculator-heading">
              The average Pabloo client saves annually:
            </div>
            <SliderContainer
              config={sliderConfigurations[0]}
              onValueChange={handleGMVChange}
            />
            <SliderContainer
              config={sliderConfigurations[1]}
              onValueChange={handleOrderSizeChange}
            />
            {/*<SliderContainer config={sliderConfigurations[2]} onValueChange={handleReturnPercentageChange} />*/}
            <div className="calculator-content">
              <div className="calculator-content-output-wrap">
                <div className="calculator-content-sub-heading">
                  Your potential savings
                </div>
                <div className="calculator-content-text-wrap">
                  <div id="cal-output" className="calculator-content-text">
                    ${potentialSavings.toLocaleString()}
                  </div>
                </div>
              </div>
              <div className="calculator-content-button-wrap">
                <a
                  href="https://apps.shopify.com/pabloo-store-credit"
                  target="_blank"
                  className="pink-arrow-button w-inline-block"
                  onClick={() => {
                    sendSlackMessage("Someone has clicked on the Install on Shopify from the home page - Calculator view.", "#website-updates");

                    window.gtag("event", "conversion_button_clicked", {
                      button: "view_shopify_listing",
                      source: "Calculator view"
                    });
                  }}
                >
                  <div
                    className="button-panels-contain"
                    style={{ background: "#16a34a" }}
                  >
                    <div className="button-panel " />
                  </div>
                  <div className="button-text-wrapper">
                    <div className="button-text">Get started now</div>
                    <div className="button-arrow-embed _18px w-embed">
                      <svg
                        width={10}
                        height={17}
                        viewBox="0 0 10 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 2L8.75 8.75L2 15.5"
                          stroke="currentColor"
                          strokeWidth="2.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="w-embed w-script">
              {/* The script is being executed via useEffect from above */}
            </div>
          </div>
        </div>
      </div>
      <a id="calculator" href="#" className="calc-anchor w-inline-block" />
    </section>
  );
};

export default CalculatorComponent;
