import React from "react";

const PrivacyPolicyComponent = () => {
  return (
    <div className="updated-page-wrapper">
      <main className="main-wrapper">
        <div className="privacy-section">
          <div className="div-block-851">
            <div className="text-block-380">PRIVACY POLICY</div>
          </div>
          <div className="wrapper-1300px privacy">
            <div className="privacy-content-wrapper">
              <div className="rich-text-block-30 w-richtext">
                <p><strong>Introduction</strong></p>
                <p>Pabloo ("Pabloo", "we" or "us") provides software that helps online businesses and brands (our "Customers") run engaging marketing campaigns that are promoted to customers ("Campaign Users").</p>
                <p>Security & Privacy is an important part of our platform that we take very seriously. Therefore, this Privacy Policy governs the manner in which Pabloo collects, uses, maintains and discloses information collected from users (each, a "User") of the https://pabloo.com website ("Site"). This privacy policy applies to the Site and service offered by Pabloo.</p>
                <p>In order to ensure confidentiality and lawful processing of its, Visitors, Customers and Campaign Users personal data, Pabloo in its capacity of a data controller and of a processor, conducts its activities in strict compliance with the requirements set in the California Consumer Privacy Act (CCPA).</p>
                <p>This Privacy Policy applies to all Customers, Campaign Users and Visitors of our Site and Services.</p>

                <p><strong>What Information We Collect and How We Use It</strong></p>
                <p>We may collect the following types of information about you on our Website</p>

                <p><strong>Customers and Visitors of our Site</strong></p>
                <p>We may collect your personal data in a variety of ways, including, but not limited to, when you visit our Site, register on the Site, subscribe to our newsletter, fill out a form, or in connection with other activities, services, features or resources we make available.</p>

                <p><strong>Registration and Contact Information:</strong> As appropriate and depending on the Services you would like to use, Customers and Visitors may be asked to provide us with full name, username, email, address, credit card, phone number or billing information.</p>

                <p><strong>Payment Information:</strong> When you purchase the Services, we will also collect transaction information, which may include your credit card information, billing and mailing address, and other payment-related information.</p>

                <p><strong>Technical, Usage and Location Information:</strong> We automatically collect information on how you interact with the Site, such as the IP address from which you access the Site, date and time, information about your browser, operating system and computer or device, pages viewed and items clicked. We may also collect location information, including location information automatically provided by your computer or device. We use cookies and similar technologies to collect some of this information and persist your login to our Site.</p>

                <p><strong>Third Party Platforms:</strong> We may collect information when you interact with our advertisements and other content on third-party sites or platforms, such as social networking sites. This may include information such as Facebook Likes, profile information gathered from social networking sites during signup, or the fact that you viewed or interacted with our content.</p>

                <p><strong>Other Information:</strong> We may collect other information from you that is not specifically listed here. We may use any such information in accordance with this Privacy Policy or as otherwise permitted by you.</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default PrivacyPolicyComponent;
