import React, { useState } from "react";
import { SupportedPlatforms, BuiltForShopifyBanner } from "../assets/assets";

import "../styles/button.css";
import {sendSlackMessage} from "../models/notifications";

const IntegrationsComponent = () => {
  return (
    <section className="integrations-section">
      <div className="integrations-wrapper ">
        <div className="rounded-2xl flex items-center image-size-wrapper">
          {/*<img src={SupportedPlatforms} />*/}
          <img
            src={BuiltForShopifyBanner}
            className="shadow-2xl flex justify-center items-center image-size"
          />
        </div>
        {/*<div className="integrations-image-contain">*/}
        {/*  <div className="integrations-lottie" data-w-id="94f04897-6855-b051-c049-524ce9045681" data-animation-type="lottie" data-src="" data-loop={1} data-direction={1} data-autoplay={1} data-is-ix2-target={0} data-renderer="svg" data-default-duration="16.25" data-duration={0} />*/}
        {/*</div>*/}
        <div className="integrations-text-wrapper">
          <h2 className="heading-56px integrations-heading">
            Seamless Integration for{" "}
            <span className="pink-heading-span" style={{ color: "#15803d" }}>
              your success.
            </span>
          </h2>
          <p className="paragraph-18px integrations-paragraph">
            Pabloo is a recognized e-commerce leader. Pabloo earned the {" "}
            <strong
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  "https://shopify.dev/docs/apps/launch/built-for-shopify",
                  "_blank"
                );
              }}
            >
              Built for Shopify
            </strong>{" "}
            award for meeting Shopify's highest quality standards for speed, ease of use, and merchant value.
            We seamlessly integrate with
            all major e-commerce platforms, and offer an SDK for custom development to fit your unique needs.
          </p>
          <a
            href="https://apps.shopify.com/pabloo-store-credit"
            target="_blank"
            className="transparent-arrow-button w-inline-block"
            onClick={() => {
              sendSlackMessage("Someone has clicked on the Install on Shopify from the home page - seamless integration.", "#website-updates");

              window.gtag("event", "conversion_button_clicked", {
                button: "view_shopify_listing",
                source: "Seamless integration for your success"
              });
            }}
          >
            <div className="button-panels-contain">
              <div className="button-panel" />
            </div>
            <div className="button-text-wrapper">
              <div className="button-text trans">Get started now</div>
              <div className="button-arrow-embed _18px trans w-embed">
                <svg
                  width={10}
                  height={17}
                  viewBox="0 0 10 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L8.75 8.75L2 15.5"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default IntegrationsComponent;
