import React, { useState, useEffect, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../../styles/pricing-skpabloocss/main.css";
import "../../styles/pricing-skpabloocss/icons.css";
import "../../styles/pabloo-mobile-pricing-styling/mobile-styling.css";

const MobilePricingComponent = ({ frequency, handleFrequencyChange }) => {
  const [activeTab, setActiveTab] = useState("annually");
  const annuallyRef = useRef(null);
  const monthlyRef = useRef(null);

  useEffect(() => {
    const scripts = [];

    return () => {
      scripts.forEach(({ id }) => {
        const script = document.getElementById(id);
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, []);


  let listOfData = [
    { id: 1, title: "Unlimited deposits, unlimited withdraws", description: "Simple, flat-rate pricing. No hidden fees per order.", tier: "starter" },
    { id: 2, title: "Immediate refunds with credit", description: "Refund any order in full or in part with store credit. Also supports refunding more than the original amount for a better refund experience.", tier: "starter" },
    { id: 3, title: "Gift cards, discount codes, and custom offers", description: "Customers can stack store credit with discount codes, gift cards, promotional codes, and custom offers.", tier: "starter" },
    { id: 4, title: "Credit expiration and product exclusion", description: "Maintain control over your store credit program by establishing expiration timelines and defining any product exclusions.", tier: "starter" },
    { id: 5, title: "Shipping and Sales Tax", description: "Customers are able to apply their store credit towards their shipping fees and sales tax during their purchases.", tier: "starter" },
    { id: 6, title: "Dashboards and granular data tracking", description: "", tier: "starter" },
    { id: 7, title: "Direct integrations for Shopify and WooCommerce", description: "Pabloo integrates seamlessly with the leading e-commerce platforms.", tier: "starter" },
    { id: 8, title: "Loyalty program for north star metrics", description: "The Pabloo loyalty program incentivizes customer sign-ups, referrals, and purchases.", tier: "starter" },
    { id: 9, title: "White labeled and customizable", description: "Customizable views to perfectly match your shop's theme and branding, ensuring a flawless experience across all devices.", tier: "starter" },
    { id: 10, title: "Email notifications", description: "Customers are kept informed every time their store credit is added, deducted, or earn cashback.", tier: "starter" },
    { id: 11, title: "Everything in the Standard Subscription", description: "", tier: "plus" },
    { id: 12, title: "Customer support assistant", description: "A dedicated customer support assistant will work closely with you to understand your needs.", tier: "plus" },
    { id: 13, title: "Email & SMS notifications", description: "Customers are kept informed every time their store credit is added, deducted, or earn cashback.", tier: "plus" },
    { id: 14, title: "Save documents local", description: "All bookkeeping data can be rendered locally for further analysis and integration with downstream applications.", tier: "plus" },
    { id: 15, title: "Revert any completed transaction", description: "Handles any error you make when making a deposit or withdraw. Allows you revert store credit changes, including purchases.", tier: "plus" },
    { id: 16, title: "Legal currency retailing", description: "Your business can legally sell store credit to customers, offering customers flexible payment options and fostering long-term brand engagement.", tier: "plus" },
    { id: 17, title: "Opt-in affiliate program", description: "Each participating customer has an affiliate link; a portion of each sale generated through the link goes back to the affiliate.", tier: "plus" },
    { id: 18, title: "AI Abandoned cart solution (2.5x increase in checkout)", description: "Recover lost sales with our AI-powered abandoned cart solution (Boosts checkout rates by 2.3x).", tier: "plus" },
    { id: 19, title: "Interactive data with Pabloo AI chat", description: "Ask your data anything with our AI assistant.", tier: "plus" },
    { id: 20, title: "AI tool to reengage dormant customers", description: "Analyzes expected Customer Lifetime Value (CLTV) to determine the ideal store credit amount to gift each customer, driving repeat purchases within 60 days and optimizing long-term customer value.", tier: "plus" },
  ]


  function handleCheckout(tier, duration) {
    const pricingInfo = `tier ${tier} duration ${duration}`;
    window.gtag("event", "buy_now_click", {
      subscription_type: "Professional",
      pricing_info: pricingInfo,
      event_callback: () => {
        window.gtag("get", "G-8G0V7WWP5L", "client_id", (clientId) => {
          fetch("https://server.pabloo.com/website/handle_submission", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "buy_now_click",
              clientId: clientId,
              subscriptionType: "Professional",
              pricingInfo: pricingInfo,
            }),
          });
        });
      },
    });

    let link = "https://buy.stripe.com/aEUg2ngQ52SX5La14b";
    if (tier === "plus") {
      if (duration === "monthly") {
        link = "https://buy.stripe.com/aEUg2ngQ52SX5La14b";
      } else {
        link = "https://buy.stripe.com/6oEaI3eHXbptgpO3ck";
      }
    } else {
      if (duration === "monthly") {
        link = "https://buy.stripe.com/3cs03p2Zf1OT3D2eV3";
      } else {
        link = "https://buy.stripe.com/7sI9DZ57n8dh1uU00a";
      }
    }
    window.open(link, "_blank");
  }

  return (
    <div
      className="flex justify-center"
      style={{ marginTop: "-90px", marginBottom: "-150px" }}
    >
      <section className="pricing">
        <div className="container solid-background">
          <div className="pricing-tabs">
            <button
              className={`pricing-tab ${
                activeTab === "annually" ? "active" : ""
              }`}
              onClick={() => setActiveTab("annually")}
            >
              Annually
            </button>
            <button
              className={`pricing-tab ${
                activeTab === "monthly" ? "active" : ""
              }`}
              onClick={() => setActiveTab("monthly")}
            >
              Monthly
            </button>
          </div>

          <TransitionGroup>
            {activeTab === "annually" && (
              <CSSTransition
                nodeRef={annuallyRef}
                key="annually"
                timeout={300}
                classNames="fade"
              >
                <div ref={annuallyRef} className="pricing-tab-content">
                  <div className="">
                    <div className="pricing-pane">
                      <div className="pricing-pane__header">
                        <div className="pricing-pane__title">
                          Standard subscription
                        </div>
                        <div className="pricing-plan__tags justify-center">
                          <span className="pill">Solo entrepreneurs</span>
                          <span className="pill">Small teams</span>
                        </div>
                      </div>
                      <div className="pricing-pane__info">
                        <div className="pricing-pane__info-price">
                          <span className="pricing-plan__price">$49</span>
                        </div>
                        <p>
                          Monthly, billed yearly or <strong>$59/month</strong>,
                          billed monthly
                        </p>
                        <a
                          className="button button--primary pricing-plan__button"
                          style={{ width: "50%", marginBottom: "20px", fontWeight: "700" }}
                          onClick={() => {
                            handleCheckout("plus", "annually");
                          }}
                        >
                          Buy now
                        </a>
                      </div>
                      <div className="pricing-pane__list">
                        <ul className="" style={{margin: "0px", paddingLeft: "0px"}}>
                          {
                            listOfData.map((value) => {
                              if (value.tier === "starter") {
                                return (
                                    <li> <div className="font-semibold">{value.title}</div></li>
                                )
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="pricing-tab__pane">
                    <div className="pricing-pane popular">
                      <div className="pricing-pane__label">MOST POPULAR</div>
                      <div className="pricing-pane__header">
                        <div className="pricing-pane__title">
                          Professional subscription
                        </div>
                        <div className="pricing-plan__tags justify-center">
                          <span className="pill" style={{ marginLeft: "15px" }}>
                            Medium-sized teams
                          </span>
                          <span className="pill">Scaling</span>
                        </div>
                      </div>
                      <div className="pricing-pane__info">
                        <div className="pricing-pane__info-price">
                          <span className="pricing-plan__price">$120</span>
                        </div>
                        <p>
                          Monthly, billed yearly or <strong>$125/month</strong>,
                          billed monthly
                        </p>
                        <a
                          className="button button--primary pricing-plan__button"
                          style={{ width: "50%", marginBottom: "20px", fontWeight: "700" }}
                          onClick={() => {
                            handleCheckout("pro", "annually");
                          }}
                        >
                          Buy now
                        </a>
                      </div>
                      <div className="pricing-pane__list">
                        <ul className="" style={{margin: "0px", paddingLeft: "0px"}}>
                          {
                            listOfData.map((value) => {
                              if (value.tier === "plus") {
                                return (
                                    <li> <div className="font-semibold">{value.title}</div></li>
                                )
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            )}

            {activeTab === "monthly" && (
              <CSSTransition
                nodeRef={monthlyRef}
                key="monthly"
                timeout={300}
                classNames="fade"
              >
                <div ref={monthlyRef} className="pricing-tab-content">
                  <div className="">
                    <div className="pricing-pane">
                      <div className="pricing-pane__header">
                        <div className="pricing-pane__title">
                          Standard subscription
                        </div>
                        <div className="pricing-plan__tags justify-center">
                          <span className="pill">Solo entrepreneurs</span>
                          <span className="pill">Small teams</span>
                        </div>
                      </div>
                      <div className="pricing-pane__info">
                        <div className="pricing-pane__info-price">
                          <span className="pricing-plan__price">$59</span>
                        </div>
                        <p>
                          Monthly or <strong>$49/month</strong>, billed yearly
                        </p>
                        <a
                          className="button button--primary pricing-plan__button"
                          style={{ width: "50%", marginBottom: "20px", fontWeight: "700" }}
                          onClick={() => {
                            handleCheckout("plus", "monthly");
                          }}
                        >
                          Buy now
                        </a>
                      </div>
                      <div className="pricing-pane__list">
                        <ul className="" style={{margin: "0px", paddingLeft: "0px"}}>
                          {
                            listOfData.map((value) => {
                              if (value.tier === "starter") {
                                return (
                                    <li> <div className="font-semibold">{value.title}</div></li>
                                )
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="pricing-tab__pane">
                    <div className="pricing-pane popular">
                      <div className="pricing-pane__label">MOST POPULAR</div>
                      <div className="pricing-pane__header">
                        <div className="pricing-pane__title">
                          Professional subscription
                        </div>
                        <div className="pricing-plan__tags justify-center">
                          <span className="pill" style={{ marginLeft: "15px" }}>
                            Medium-sized teams
                          </span>
                          <span className="pill">Scaling</span>
                        </div>
                      </div>
                      <div className="pricing-pane__info">
                        <div className="pricing-pane__info-price">
                          <span className="pricing-plan__price">$125</span>
                        </div>
                        <p>
                          Monthly or <strong>$120/month</strong>, billed yearly
                        </p>
                        <a
                          className="button button--primary pricing-plan__button"
                          style={{ width: "50%", marginBottom: "20px", fontWeight: "700" }}
                          onClick={() => {
                            handleCheckout("pro", "monthly");
                          }}
                        >
                          Buy now
                        </a>
                      </div>
                      <div className="pricing-pane__list">
                        <ul className="" style={{margin: "0px", paddingLeft: "0px"}}>
                          {
                            listOfData.map((value) => {
                              if (value.tier === "plus") {
                                return (
                                    <li> <div className="font-semibold">{value.title}</div></li>
                                )
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      </section>
    </div>
  );
};

export default MobilePricingComponent;
