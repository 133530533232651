//React imports
import React from 'react';
import ReactDOM from 'react-dom/client';


//Imports the components
import PablooHeroComponent from '../components/home/PablooHeroComponent.js';
import CarouselComponent from '../components/CarouselComponent.js';
import StatsBlockComponent from '../components/home/StatsBlockComponent.js';
import TribeSection from '../components/tribe/TribeSection.js';
import SpendComponent from '../components/home/SpendComponent.js';
import PointsComponent from '../components/home/PointsComponent.js';
import CardsComponent from '../components/CardsComponent.js';
import IntegrationsComponent from '../components/IntegrationsComponent.js';
import CalculatorComponent from '../components/calculator/CaculatorComponent.js';
import StackComponent from '../components/home/StackComponent.js';
import TestimonialComponent from '../components/home/TestimonialComponent.js';
import SolutionComponent from '../components/SolutionComponent.js';
import FooterComponent from '../components/home/FooterComponent.js';
import NavBarComponent from '../components/navBar/NavBarComponent.js';

import SignupComponent from '../components/signUp/SignupComponent.js';
import PrivacyPolicyComponent from '../components/PrivacyPolicyComponent.js';


import HeaderComponent from '../components/HeaderComponent.js';

import TermsComponent from '../components/TermsComponent.js'


// import pricingPage from './pricingPage.jsx';


//Imports assets such as images
import { ProblemAndSolution } from "../components/home/problemAndSolutionView";



function termspage() {
    return (
        <div>
            <div className="updated-page-wrapper">

                <NavBarComponent/>
                

                <TermsComponent />


                <FooterComponent/>

            </div>
        </div>

    );
}

export default termspage;
