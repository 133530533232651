import ThankYouComponent from "../components/thankYou/thankYouComponent";


export default function ThankYou() {

    return (
        <div>
            <ThankYouComponent />
        </div>
    )
}