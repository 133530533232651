import React, { useEffect } from "react";
import { CaretRight, CaretLeft } from "../../assets/assets";
// import "../../styles/calculator/calculatorSlider.css";

const SliderComponent = ({ config, value, onChange }) => {
  useEffect(() => {
    const updateElements = () => {
      const selectorButton = document.querySelector(`#${config.selectorId}`);
      const selectorValueWrap = document.querySelector(
        `#${config.selectorValueWrapId}`
      );
      const progressBar = document.querySelector(`#${config.progressBarId}`);
      const selectorValue = document.querySelector(
        `#${config.selectorValueId}`
      );

      if (selectorButton && selectorValueWrap && progressBar && selectorValue) {
        const percentage =
          ((value - config.min) / (config.max - config.min)) * 100;

        selectorButton.style.left = `${percentage}%`;
        selectorValueWrap.style.left = `${percentage}%`;
        progressBar.style.width = `${percentage}%`;

        if (config.label === "Gross Merchandise Value (GMV)") {
          selectorValue.innerHTML =
            value < 1000000
              ? `$${(value / 1000).toFixed(0)}K`
              : `$${(value / 1000000).toFixed(1)}M`;
        } else if (config.label === "Average Order Size") {
          selectorValue.innerHTML = `$${value}`;
        } else if (config.label === "Return Percentage") {
          selectorValue.innerHTML = `${value}%`;
        }
      }
    };

    updateElements();
    window.addEventListener("resize", updateElements);

    return () => {
      window.removeEventListener("resize", updateElements);
    };
  }, [value, config]);

  return (
    <div className="calculator">
      <label htmlFor={config.inputId} style={config.labelStyle}>
        {config.label}
      </label>
      <div className="calculator-input-wrap">
        <div className="calculator-input-embed w-embed">
          <input
            type="range"
            min={config.min}
            max={config.max}
            value={value}
            step={config.step}
            id={config.inputId}
            onChange={onChange}
            style={{
              width: "100%",
              appearance: "none",
              height: "20px",
              borderRadius: "60px",
              background: "none",
              margin: "0px",
              position: "relative",
              zIndex: "4",
            }}
          />
          <style
            dangerouslySetInnerHTML={{
              __html: `
                #${config.inputId} {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 100%;
                  height: 12px;
                  border-radius: 60px;
                  background: none;
                  margin: 0px;
                  position: relative;
                  z-index: 5;
                }

                #${config.inputId}::-webkit-slider-thumb {
                  -webkit-appearance: none;
                  appearance: none;
                  width: 80px; /* Adjust size as needed */
                  height: 30px; /* Adjust size as needed */
                  position: relative;
                  top: -4px;
                  background: none;
                  display: hidden;
                  border-radius: 40%;
                  cursor: pointer;
                }

                #${config.inputId}::-moz-range-thumb {
                  width: 40px; /* Adjust size as needed */
                  height: 20px; /* Adjust size as needed */
                  background: none;
                  border-radius: 50%;
                  cursor: pointer;
                  border: none; /* Ensure no border is applied */
                }
                  
                #${config.inputId}::-webkit-slider-runnable-track {
                  width: 100%;
                  height: 15px;
                  cursor: pointer;
                  background: none;
                  border-radius: 60px;
                }

                #${config.inputId}::-moz-range-track {
                  width: 100%;
                  height: 12px;
                  cursor: pointer;
                  background: none;
                  border-radius: 60px;
                }
              `,
            }}
          />
        </div>
        <div className="calculator-track" />
        <div id={config.progressBarId} className="calculator-progress-bar" />
        <div id={config.selectorId} className="calculator-selector-wrap">
          <div className="calculator-selector-button">
            <img
              loading="lazy"
              src={CaretLeft}
              alt="Pabloo left arrow"
              className="calculator-selector-icons"
            />
            <img
              loading="lazy"
              src={CaretRight}
              alt="Pabloo right arrow"
              className="calculator-selector-icons"
            />
          </div>
        </div>
        <div
          id={config.selectorValueWrapId}
          className="calculator-selector-value-wrapper"
        >
          <div
            id={config.selectorValueId}
            className="calculator-selector-value"
          >
            {config.label === "Return Percentage"
              ? "30%"
              : `$${config.defaultValue}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
