import React from "react";
import PropTypes from "prop-types";

export default function HeaderComponent(props) {
  return (
    <div>
      <section className="hero-home-section">
        <div className="hero-wrapper-contain">
          <div
            data-w-id="9306ff74-f6c3-5268-880e-ae34eec4e2c2"
            className="hero-home-wrapper new"
          >
            <div className="hero-home-text-wrapper new">
              <div
                data-w-id="9306ff74-f6c3-5268-880e-ae34eec4e2c4"
                className="text-block-391"
              >
                {(props.page === "home" || !Boolean(props.page)) && (
                  <div>
                    Your Trusted Partner for <br />‍
                    <span
                      className="text-span-370 text-green-700"
                      style={{ color: "#15803d" }}
                    >
                      Store Credit
                    </span>
                  </div>
                )}
                {props.page === "pricing" && (
                  <div>
                    Make Every <br />‍
                    <span
                      className="text-span-370 text-green-700"
                      style={{ color: "#15803d" }}
                    >
                      Dollar
                    </span>{" "}
                    Count
                  </div>
                )}
                {props.page === "pricingAfterSignup" && (
                  <div>
                    <br />
                    Account Setup
                    {/*<span*/}
                    {/*  className="text-span-370 text-green-700"*/}
                    {/*  style={{ color: "#15803d" }}*/}
                    {/*>*/}
                    {/*{" "}5 minutes*/}
                    {/*</span>{" "}*/}
                  </div>
                )}
              </div>
              {(props.page === "home" || !Boolean(props.page)) && (
                <div className="paragraph-18px hero-home-paragraph new">
                  <div className="hero-platform-subheading-text">
                    Pabloo is the best solution for managing store credit.{" "}
                    <strong>Over a thousand professional retailers</strong> use
                    Pabloo to issue store credit, track customer balances, and
                    nurture customer relationships.{" "}
                  </div>
                </div>
              )}
              {props.page === "pricing" && (
                <div className="paragraph-18px hero-home-paragraph new">
                  <div className="hero-platform-subheading-text">
                    We empower businesses with{" "}
                    <strong className="font-bold">
                      unmatched store credit solutions,
                    </strong>{" "}
                    driving bottom-line impact through our product, services, and comprehensive data.
                  </div>
                </div>
              )}
              {props.page === "pricingAfterSignup" && (
                <div className="paragraph-18px hero-home-paragraph new">
                  <div className="hero-platform-subheading-text">
                    Select the account type that best suits you. Pabloo offers a
                    variety of products,
                    <strong className="font-bold">
                      {" "}
                      pushing you further with store credit.
                    </strong>{" "}
                    We drive bottom-line impact with our product, service, and
                    comprehensive data.
                  </div>
                </div>
              )}
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/652fdb2fc7f49adf2650b86d_Hero%20Bg.svg"
            alt=""
            className="hero-bkg"
          />
        </div>
        {props.children}
      </section>
    </div>
  );
}

HeaderComponent.propTypes = {
  page: PropTypes.string,
};
