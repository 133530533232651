import React from "react";

const CaseHero = () => {
  return (
    <div className="case-study-hero-scetion">
      <div className="wrapper-1280px case-study-hero-flex">
        <div className="case-study-hero-text-container">
          <div className="case-study-span-wrapper">
            <h1 className="case-study-hero-pink-text">read stories</h1>
            <div className="case-study-hero-pink-line" />
          </div>
          <h2 className="case-study-hero-heading mackinacpro">
            See how Pabloo is helping{" "}
            <span className="blue-span">thousands of retailers</span> with store
            credit.
          </h2>

          <div>
            <div className="hero-platform-subheading-text-case text-responsive">
              We are a fintech company that builds cutting-edge financial
              solutions to solve the toughest problems. We invented Store Credit
              to help e-commerce businesses increase revenue by reducing cart
              abandonment, while increasing cart size and retention. We
              developed AI algorithms to specifically target high conversion
              shoppers.
            </div>
          </div>
          <div className="case-study-button-rapper">
            <a
              href="#Case-Studies"
              className="read-stories-button w-inline-block"
            >
              <div>Read stories</div>
              <img
                src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0dcd77a46e8c72ee0e_ic-fluent-chevron-bottom.svg"
                loading="lazy"
                alt=""
                className="arrow-bottom-icon"
              />
            </a>
            <a
              data-w-id="d1b0ad38-80b1-a999-42be-8c45ade72fa1"
              href="/signup"
              className="success-story-button w-inline-block"
            >
              <div>Be our next success story</div>
              <img
                src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0d2cd42d05507dd859_ic-fluent-chevron-right.svg"
                loading="lazy"
                style={{
                  WebkitTransform:
                    "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  MozTransform:
                    "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  msTransform:
                    "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                  transform:
                    "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                }}
                alt=""
                className="arrow-right-icon"
              />
            </a>
          </div>
        </div>
        <div className="case-study-hero-image-container">
          <img
            sizes="(max-width: 767px) 90vw, (max-width: 991px) 520px, (max-width: 1279px) 60vw, 720px"
            src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FyeeSi.png?alt=media&token=31f74308-6857-4064-b83b-3d41cd2c4dc7"
            loading="lazy"
            alt="Pabloo Success Stories"
            className="case-study-hero-image rounded-2xl shadow-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default CaseHero;
