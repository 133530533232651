import React from "react";
import {sendSlackMessage} from "../../models/notifications";
import VideoPlayerFeatures from "../videoPlayer/VideoPlayerFeatures";
import "../../styles/video-player-styling/player.css"
import {PartnersImage} from "../../assets/assets";

export default function HeroSection() {
    return (
        <div className="case-study-hero-scetion">
            <div className="wrapper-1280px case-study-hero-flex">
                <div className="case-study-hero-text-container">
                    <div className="case-study-span-wrapper">
                        <h1 className="case-study-hero-pink-text">Partners</h1>
                        <div className="case-study-hero-pink-line"/>
                    </div>
                    <h2 className="case-study-hero-heading mackinacpro">
                        Premier Suite {" "}
                        <span className="blue-span">Quality</span>
                    </h2>

                    <div>
                        <div className="hero-platform-subheading-text-case text-responsive">
                            As your business grows, you face new challenges. How can you continue providing excellent
                            service as your number of customers swells?

                            <br />
                            <br />
                            Whether you're starting a new sales channel, evolving your tech stack, or re-platforming,
                            these apps offer a wide range of commerce integration possibilities with a high level of
                            creative control.


                        </div>
                    </div>
                    <div className="pr-2">
                        <div className="case-study-button-rapper">
                            <a
                                href="#partners"
                                className="read-stories-button w-inline-block mr-8"
                            >
                                <div>Explore partners</div>
                                <img
                                    src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0dcd77a46e8c72ee0e_ic-fluent-chevron-bottom.svg"
                                    loading="lazy"
                                    alt=""
                                    className="arrow-bottom-icon"
                                />
                            </a>
                            <a
                                data-w-id="d1b0ad38-80b1-a999-42be-8c45ade72fa1"
                                // href="/signup"
                                className="success-story-button w-inline-block"
                            >
                                <div
                                    className="cursor-pointer"
                                    onClick={() => {
                                        sendSlackMessage(`Someone clicked on the join partnerships from the partnerships page.`, "#website-updates")
                                        window.open("./partners/join", "_blank")
                                    }}
                                >Become a partner
                                </div>
                                <img
                                    src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0d2cd42d05507dd859_ic-fluent-chevron-right.svg"
                                    loading="lazy"
                                    style={{
                                        WebkitTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        MozTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        msTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        transform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    }}
                                    alt=""
                                    className="arrow-right-icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="video-player-features-page rounded">
                    <img className="rounded" src={PartnersImage} alt="Partnerships image" />

                </div>
            </div>
        </div>
    )
};

