import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { useNavigate } from "react-router-dom";

import { Component } from "react";

function PlantedMaterialStudy() {
    return (
        <div>
            <section className="section-58 case-study-hero-section">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-669">
                        <div className="case-study-hero-container">
                            <div className="case-study-hero-logo-container">
                                <img
                                    loading="lazy"
                                    alt=""
                                    src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FPlantedMaterialLogo.png?alt=media&token=c6f00ed8-8676-4abd-90f8-8871d4d557ed"
                                    sizes="(max-width: 479px) 93vw, (max-width: 767px) 60vw, (max-width: 991px) 57vw, 563.984375px"
                                    srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FPlantedMaterialLogo.png?alt=media&token=c6f00ed8-8676-4abd-90f8-8871d4d557ed 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FPlantedMaterialLogo.png?alt=media&token=c6f00ed8-8676-4abd-90f8-8871d4d557ed 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FPlantedMaterialLogo.png?alt=media&token=c6f00ed8-8676-4abd-90f8-8871d4d557ed 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FPlantedMaterialLogo.png?alt=media&token=c6f00ed8-8676-4abd-90f8-8871d4d557ed 2356w"
                                    className="case-study-hero-logo"
                                />
                            </div>
                            <div className="case-study-hero-heading-container">
                                <h1 className="case-study-hero-heading-2">
                                    A Convo With the CEO of Planted Materials
                                </h1>
                            </div>
                            <div className="case-study-hero-metrics-container">
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade208-93cb77c1">
                                    <h2 className="heading-241">100+</h2>
                                    <div className="text-block-293">hours saved</div>
                                </div>
                                <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade20d-93cb77c1">
                                    <h2 className="heading-241 w-dyn-bind-empty" />
                                    <div className="text-block-293 w-dyn-bind-empty" />
                                </div>
                                <div
                                    id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade212-93cb77c1"
                                    className="hide"
                                >
                                    <h2 className="heading-241">4x</h2>
                                    <div className="text-block-293">roi</div>
                                </div>
                            </div>
                        </div>
                        <div
                            id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade217-93cb77c1"
                            className="div-block-674"
                        >
                            <img
                                loading="lazy"
                                alt=""
                                src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d"
                                sizes="(max-width: 479px) 93vw, (max-width: 767px) 72vw, (max-width: 991px) 68vw, (max-width: 1919px) 676.796875px, 940px"
                                srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d"
                                className="image-210"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-60">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-681">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade21c-93cb77c1">
                            <div>
                                <div className="text-block-290">headquarters</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">Seattle, WA</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade223-93cb77c1">
                            <div>
                                <div className="text-block-290">industry</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">Environment</div>
                            </div>
                        </div>
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade22a-93cb77c1">
                            <div>
                                <div className="text-block-290">founded</div>
                            </div>
                            <div className="div-block-679">
                                <div className="text-block-288">2020</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-59">
                <div className="w-layout-blockcontainer w-container">
                    <div className="div-block-670">
                        <div id="w-node-_0b4162c0-2d42-0e8a-54db-73bdd6ade234-93cb77c1">
                            <div className="div-block-686">
                                <p className="case-study-hero-sub-heading">
                                    The Pabloo team sat down with Noah Belkhous, the co-founder
                                    and CEO of Planted Materials — a clean-tech company focused on
                                    repurposing organic waste into renewable materials. Green
                                    Water Labs is the biochemical extension of Planted Materials,
                                    that is utilizing the organic compounds found in plant waste
                                    to create non-toxic alternatives to everyday chemicals in our
                                    homes. Green Water Labs’ first commercial product, Algae
                                    Control, is a plant-based natural aquarium cleaner treatment
                                    effective against many aquatic algae types and cyanobacterias.
                                    Despite being primarily used in aquatic environments, the
                                    solution is also effective in cleaning algae off roofs, decks,
                                    patios, walkways, sidings, and more — providing a safer
                                    cleaning option for homeowners! Best of all, Algae Control is
                                    safe for various ecosystems and does not harm kids, pets,
                                    plants, fish, or any other aquatic living organisms when used
                                    according to provided dosage guidelines.
                                </p>
                            </div>
                            <div className="div-block-683">
                                <div className="rich-text-block-28 w-richtext">
                                    <p>‍</p>
                                    <h4>
                                        Tell us a bit about your background before Green Water Labs?
                                    </h4>
                                    <p>
                                        My background comes from being a longtime entrepreneur —
                                        I’ve always known that I wanted to start and run something
                                        that is truly my own. In my early college years that became
                                        directed towards leaving some sort of positive impact on our
                                        planet so that’s how Green Water Labs was materialized.
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    The lack of SaaS renewal tracking also led to negotiations
                    being done at the last minute. Trying to gather the relevant
                    pricing benchmarks and contract designing expertise for each
                    SaaS tool, drained time and resources. But optimizing SaaS
                    costs was the need of the hour.
                  </p> */}
                                    <p>‍</p>
                                    <h4>How did you come up with Green Water Labs?</h4>
                                    <p>
                                        Green Water Labs started when one of my childhood buddies
                                        (Greg), who went to college for synthetic chemistry and
                                        biology and I stayed in touch about potentially starting a
                                        project together. Ultimately Greg moved up here in Seattle
                                        to work at a CBD lab and he and I started looking at how
                                        much waste was generated by the cannabis industry — they
                                        extract all the CBD and THC from the plant for various
                                        products and what’s left is solvent soaked marijuana plants
                                        that are unrecyclable. We wanted to repurpose that but found
                                        that the market was already saturated. However, a buddy of
                                        mine had a business where he was removing about 750K tons of
                                        aquatic plant waste from local aquatic ecosystems and we
                                        started exploring the idea of doing something with that. We
                                        ended up moving into the warehouse next door and started
                                        processing all that waste and realized that there were some
                                        very valuable chemical compounds. A year and a half later we
                                        were having trouble achieving the purity levels of the
                                        chemical compounds we were initially going for but had
                                        already identified several other products that we could
                                        repurpose into our current product.
                                    </p>
                                    <p>‍</p>
                                    {/* <p>
                    Wodify also evaluated SaaS management solutions such as
                    Tropic and Vendr but eventually chose  because the
                    concierge onboarding enabled them to adopt the solution
                    quickly and smoothly.
                  </p> */}
                                    <p>‍</p>
                                    <h4>What makes you passionate about Green Water Labs?</h4>
                                    <p>
                                        I’ve always been passionate about sustainability in a
                                        nutshell. In my eyes, we as species are trying to evolve and
                                        become better — it’s one giant learning experience. We’re
                                        all learning from our mistakes and are improving on past
                                        processes. We’ve gotten so far with fossil fuels and
                                        petroleum-based products but at the end of the day, we know
                                        that there are better ways to do that, better ways for
                                        ourselves — so that we don’t have toxic microplastics in our
                                        bodies or continue to pollute the earth with this stuff. So
                                        for me, it’s all about leaving a lasting change on the
                                        planet — leave somewhere better than you found it.
                                        Additionally, it’s a challenge to build an amazing brand and
                                        company but also a huge learning experience to push myself
                                        out of my comfort zone to find innovative ways to challenge
                                        the petroleum and pesticide industries. The bottom line is
                                        I’m trying to make a positive impact while pushing myself to
                                        the limits in the process.
                                    </p>
                                    <p>‍</p>
                                    <h4>How did you find your business partners?</h4>
                                    <p>
                                        Greg and I grew up together in Iowa, we tore up the streets
                                        of Des Moines in high school, played sports together, and
                                        had a ton of fun. We went our separate ways in college but
                                        we always kept in touch and always had the idea up in the
                                        air. I think we’ve both always had an out-of-the-box
                                        thinking process, even in high school we always knew we
                                        didn’t want to do things the traditional way and that always
                                        stuck. When we eventually reunited, we decided let’s finally
                                        send it and figure out how we can make this work.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        How did the first few months look versus how they look now?
                                    </h4>
                                    <p>
                                        I don’t know if you’ve seen Breaking Bad but that’s
                                        essentially what the first few months looked like. It was
                                        Greg and I in a warehouse, buying a bunch of lumber and
                                        equipment, building tables, setting up the shop, and buying
                                        a bunch of random scientific equipment to fit the most
                                        rudimentary chemical extraction and separation processes you
                                        can find. Now we’re in an accredited lab, we have solid
                                        equipment, offices, and working spaces. It feels a lot more
                                        real now — the operation center and RND are built out. It’s
                                        much more structured compared to initially when we knew what
                                        we wanted to do but were not necessarily clear on how that
                                        was going to be achieved.
                                    </p>
                                    <p>‍</p>
                                    <h4>How did you keep yourself motivated along the way?</h4>
                                    <p>
                                        Deep meditation and learning how to remain calm in all
                                        situations whether it’s going well or when it feels like it
                                        may have all just been a giant mistake. So just staying calm
                                        through it all because you never know what turn you’re going
                                        to go around next. I think there was definitely a time when
                                        I prepared to sell most of my possessions and move to an
                                        island to just forget about it all –but having a ton of
                                        faith in ourselves that we started this and were going to
                                        see it through — it’s all dependent on how much work you put
                                        in and that’s what you’re going to get out. And I think
                                        that’s the ultimate self-test as a founder — can you work
                                        through failure, can you work out issues between team
                                        members, can you work through the hard days when there’s so
                                        much to do and no one there to support you. That’s part of
                                        the fun for me, it’s like yes, I can do this shit, and we’re
                                        just going to keep doing it.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        What has been the most challenging part of your journey?
                                    </h4>
                                    <p>
                                        I would say the pivoting. When we found our initial compound
                                        and realized we could sell a gram of that stuff for almost
                                        $80,000 — so theoretically I could throw in about $100,000
                                        and realize that in no time. But we got stuck on the idea
                                        only to find out wecouldn’t do that with our current
                                        resources. Therefore having to pivot into something
                                        completely different was a little uncomfortable at first. I
                                        probably would’ve never started this if I knew 3 years down
                                        the line I’d be selling a product that helps people keep
                                        their tanks free of algae. That was not the initial mindset
                                        but at the same time, that’s why holding the long-term
                                        vision near and dear throughout the whole process and making
                                        sure everything relates to that is a way to get through it.
                                    </p>
                                    <p>‍</p>
                                    <h4>What’s been your biggest mistake?</h4>
                                    <p>
                                        It’s hard to pinpoint that but I guess the biggest mistake
                                        was not sitting down for the first 6 months and doing a lot
                                        more work on paper to figure out ok here’s all the products
                                        we can make, here’s how we’re going to be able to sell them
                                        and this is how it’s going to go. But I think the biggest
                                        lesson I learned out of this whole process is that this shit
                                        takes a tremendous amount of time and effort; especially in
                                        something like manufacturing a pesticide and having to deal
                                        with government regulations — the timeline on that stuff is
                                        so slow and there’s only so much you can push past that.
                                    </p>
                                    <p>‍</p>
                                    <h4>
                                        You said that you put about $100,000 of your own money into
                                        Green Water Labs in the beginning, were you a bit hesitant
                                        about that?
                                    </h4>
                                    <p>
                                        No man. I was stoked. I think it’s all about risking it for
                                        the biscuit and putting yourself on the line. That’s how I
                                        motivate myself like it’s all or nothing.
                                    </p>
                                    <p>‍</p>
                                    <h4>What does success look like for you long term?</h4>
                                    <p>
                                        I think success for me is looking back and saying there’s
                                        nothing I wish I should’ve said fuck it and gone and done.
                                        Initially, it used to be about money… but after getting a
                                        bunch of money and seeing how it doesn’t change the bottom
                                        line, and in turn, realizing that happiness comes from
                                        within. For me now, it’s just having no regrets. I want to
                                        push myself to the limits and explore whatever excites me
                                        and find out what else is out there that I didn’t even know
                                        about. The main thing is not looking back and saying damn I
                                        wish I would’ve gone and done that.
                                    </p>
                                    <p>‍</p>
                                    <h4>What’s your formula for success?</h4>
                                    <p>Patience, Perseverance, and Progress.</p>
                                    <p>‍</p>
                                    <p>
                                        That’s really what it comes down to, you can’t give up the
                                        faith, you have to just keep doing it, and you have to be
                                        patient about it and ultimately you have to be finding
                                        progress because if not then you have to rethink the hard
                                        work you’re putting in and redirect it to find how you can
                                        find that progress. And for me failing is one of the best
                                        forms of progress you can have because it’s a hard-wired
                                        lesson.
                                    </p>
                                    <p>‍</p>
                                    <h4>How do you stay persistent during turbulent times?</h4>
                                    <p>
                                        I think it all comes down to self-love and not giving up on
                                        yourself. It can be super easy to just walk away but for me,
                                        it’s my personal reputation: me, myself, and I have our
                                        standards that we hold each other to.
                                    </p>
                                    <p>‍</p>
                                    <p>
                                        We were honored and inspired to hear about Noah Belkhous’
                                        origin story, journey with Planted Materials/Green Water
                                        Labs and his overall vision, not only for his umbrella
                                        company and it’s subsidiary, but for a better world. To
                                        learn more about Planted Materials and Green Water Labs
                                        visit https://greenwaterlabs.com/ and for more information
                                        on Noah, visit https://www.linkedin.com/in/noahbelkhous/.
                                    </p>
                                    {/* <h4>Results</h4>
                  <p>
                    With the help of , Wodify’s was able to put SaaS
                    buying, and management on autopilot. They were able to
                    customize each SaaS contract specifically for their Wodify’s
                    use cases and ensure they are getting the best deal.
                  </p>
                  <p>‍</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PlantedMaterialStudy;
