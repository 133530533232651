// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-size {
  width: 100%;
  border-radius: 1rem;
}

.image-size-wrapper {
  width: 50%;
}

@media (max-width: 768px) {
  .transparent-arrow-button {
    padding: 18px 20px;
    font-size: 17px;
    margin-left: 76px;
  }
  .image-size {
    width: 100%;
    border-radius: 1rem;
  }
  .image-size-wrapper {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,kBAAkB;IAClB,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF","sourcesContent":[".image-size {\n  width: 100%;\n  border-radius: 1rem;\n}\n\n.image-size-wrapper {\n  width: 50%;\n}\n\n@media (max-width: 768px) {\n  .transparent-arrow-button {\n    padding: 18px 20px;\n    font-size: 17px;\n    margin-left: 76px;\n  }\n  .image-size {\n    width: 100%;\n    border-radius: 1rem;\n  }\n  .image-size-wrapper {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
