import React, { useState, useEffect } from 'react';
import SliderComponent from './SliderComponent';

const SliderContainer = ({ config, onValueChange }) => {
  const [value, setValue] = useState(config.defaultValue);

  useEffect(() => {
    setValue(config.defaultValue);
  }, [config.defaultValue]);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    setValue(newValue);
    onValueChange(newValue);
  };

  return (
    <SliderComponent config={config} value={value} onChange={handleChange} />
  );
};

export default SliderContainer;
