// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#button-style {
  margin-top: 30px;
  padding-bottom: 30px;
}

.button-left {
  float: left;
}

.button-right {
  float: right;
}

@media (max-width: 768px) {
  #button-style {
    margin-top: 30px;
    margin-left: 25vw;
    padding-bottom: 0px;
  }

  .button-right {
    padding-left: 100px;
    margin-left: 100px;
  }

  #button-center {
    margin-right: -6vw;
    float: right;
  }
  
  #button-center-pricing {
    padding-left: 20vw;
  }

  .no-show-class {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/contact/button-center.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":["#button-style {\n  margin-top: 30px;\n  padding-bottom: 30px;\n}\n\n.button-left {\n  float: left;\n}\n\n.button-right {\n  float: right;\n}\n\n@media (max-width: 768px) {\n  #button-style {\n    margin-top: 30px;\n    margin-left: 25vw;\n    padding-bottom: 0px;\n  }\n\n  .button-right {\n    padding-left: 100px;\n    margin-left: 100px;\n  }\n\n  #button-center {\n    margin-right: -6vw;\n    float: right;\n  }\n  \n  #button-center-pricing {\n    padding-left: 20vw;\n  }\n\n  .no-show-class {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
