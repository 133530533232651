import React from "react";
import { MoneyBackGuarantee } from "../assets/assets";

const GuaranteeComponent = () => {
  return (
    <section
      id="sp-xrfg0z"
      className="sp-el-section"
      style={{
        width: "100%",
        maxWidth: "100%",
        padding: "0px 30px",
        marginTop: "-30px",
      }}
    >
      <div
        id="sp-war8co"
        className="sp-el-row sm:sp-flex sp-w-full  sp-m-auto sp-justify-between"
        style={{
          width: "auto",
          maxWidth: "1140px",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <div
          id="sp-yg781o"
          className="sp-el-col  sp-w-full"
          style={{ width: "calc(18% - 0px)", padding: "0px" }}
        >
          <div
            id="sp-pjk5dx"
            className="sp-spacer"
            style={{ height: "16px" }}
          />
          <figure
            className="sp-image-wrapper"
            // style={{ margin: "0px", padding: "0px", textAlign: "center" }}
          >
            <picture>
              <img
                src={MoneyBackGuarantee}
                alt="pabloo-guarantee-image"
                srcSet={`
                    ${MoneyBackGuarantee} 480w, 
                    ${MoneyBackGuarantee} 800w, 
                    ${MoneyBackGuarantee} 1200w
                  `}
                sizes="(max-width: 480px) 480px, (max-width: 800px) 800px, 1200px"
              />
            </picture>
          </figure>
        </div>
        <div
          id="sp-ern4ow"
          className="sp-el-col  sp-w-full"
          style={{ width: "calc(82% - 0px)" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "#sp-ern4ow .sp-col-top .pabloo-shape-fill { fill: undefined; } #sp-ern4ow .sp-col-top svg { width: undefined%; height: undefinedpx; transform: translateX(-50%); } #sp-ern4ow .sp-col-bottom .pabloo-shape-fill { fill: undefined; } #sp-ern4ow .sp-col-bottom svg { width: undefined%; height: undefinedpx; transform: translateX(-50%); }",
            }}
          />
          <div className="sp-col-shape sp-col-top" style={{ zIndex: 0 }}>
            <div />
          </div>
          <div className="sp-col-shape sp-col-bottom" style={{ zIndex: 0 }}>
            <div />
          </div>
          <div id="sp-zyxlp9" className="sp-spacer" />
          <h3
            id="sp-p2obbk"
            className="sp-el-block"
            style={{
              fontSize: "28px",
              fontWeight: "bold",
              textAlign: "left",
              margin: "0px",
            }}
          >
            100% No-Risk Money Back Guarantee!
          </h3>
          <div
            id="sp-st2se4"
            className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-st2se4 mobile-center"
          >
            <p>
              We're excited to have you experience Pabloo. Over the next 14
              days, if Pabloo isn’t the best fit, simply reach out! We’ll
              happily refund 100% of your money.
            </p>
          </div>
        </div>
      </div>
      <div
        id="sp-ozqdtg"
        className="sp-el-row sm:sp-flex sp-w-full  sp-m-auto sp-justify-between"
        style={{
          width: "auto",
          maxWidth: "1140px",
          borderRadius: "0px 0px 8px 8px",
          paddingTop: "1px",
          marginTop: "1px",
        }}
      >
        <div
          id="sp-ms4hm8"
          className="sp-el-col  sp-w-full"
          style={{
            width: "calc(100% - 0px)",
            padding: "25px",
            paddingTop: "1px",
            marginTop: "1px",
          }}
        >
          <div
            id="sp-r9qypy"
            className=""
            style={{
              fontSize: "14px",
              color: "rgb(75, 85, 99)",
              marginTop: "-20px",
              textAlign: "left",
            }}
          >
            <p style={{ textAlign: "center" }}>
              All pricing is in USD. You can change plans or cancel your account
              at any time.
              <br />* Special introductory pricing, all renewals are at full
              price.
            </p>
          </div>
        </div>
      </div>
      <div
        id="sp-lazgse"
        className="sp-el-row sm:sp-flex sp-w-full  sp-m-auto sp-justify-between"
      >
        <div
          id="sp-vbvltl"
          className="sp-el-col  sp-w-full"
          style={{ width: "calc(100% - 0px)" }}
        >
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html:
                "#sp-vbvltl .sp-col-top .pabloo-shape-fill { fill: undefined; } #sp-vbvltl .sp-col-top svg { width: undefined%; height: undefinedpx; transform: translateX(-50%); } #sp-vbvltl .sp-col-bottom .pabloo-shape-fill { fill: undefined; } #sp-vbvltl .sp-col-bottom svg { width: undefined%; height: undefinedpx; transform: translateX(-50%); }",
            }}
          />
          <div className="sp-col-shape sp-col-top" style={{ zIndex: 0 }}>
            <div />
          </div>
          <div className="sp-col-shape sp-col-bottom" style={{ zIndex: 0 }}>
            <div />
          </div>
          <div
            id="sp-emp05z"
            className="sp-spacer"
            style={{ height: "20px" }}
          />
        </div>
      </div>
    </section>
  );
};

export default GuaranteeComponent;
