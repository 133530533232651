import PropTypes from "prop-types";
import React, { useState } from "react";
import "../../styles/contact/button-center.css";
import "../../styles/contact/leftButtonStyling.css";

export default function CTAButton(props) {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleClick = () => {
    if (!isSubmitted) {
      props.onClick();
      if (props.isValid) {
        setIsSubmitted(true);
      }
    }
  };

  return (
    <div className="" style={{ marginTop: "-1px" }}>
      <div
        data-w-id="41fdb07b-387c-bef8-9004-83ed2dd63276"
        className={`left-primary-button home pricing-cta text-white ${
          isSubmitted ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleClick}
        style={{ cursor: isSubmitted ? "not-allowed" : "pointer" }}
      >
        <div className="flex justify-center items-center space-x-4">
          {!isSubmitted && (
            <div className="">
              <svg
                width={10}
                height={17}
                viewBox="0 0 10 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                transform="matrix(-1,0,0,1,0,0)"
              >
                <path
                  d="M2 2L8.75 8.75L2 15.5"
                  stroke="white"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
          <div className="ml-2" style={{ paddingLeft: "15px" }}>
            {isSubmitted ? "Submitted" : props.text}
          </div>
          {isSubmitted && (
            <div className="button-checkmark-embed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
              </svg>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CTAButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isValid: PropTypes.bool,
};
