import React from "react";
import {sendSlackMessage} from "../../models/notifications";
import VideoPlayerFeatures from "../videoPlayer/VideoPlayerFeatures";
import "../../styles/video-player-styling/player.css"

export default function HeroSection() {
    return (
        <div className="case-study-hero-scetion">
            <div className="wrapper-1280px case-study-hero-flex">
                <div className="case-study-hero-text-container">
                    <div className="case-study-span-wrapper">
                        <h1 className="case-study-hero-pink-text">Features</h1>
                        <div className="case-study-hero-pink-line"/>
                    </div>
                    <h2 className="case-study-hero-heading mackinacpro">
                        Become {" "}
                        <span className="blue-span">Perfect</span>
                    </h2>

                    <div>
                        <div className="hero-platform-subheading-text-case text-responsive">
                            Discover a suite of robust features designed to enhance your e-commerce business.
                            Immediate Refunds with Credit allows you to refund orders using store credit, even
                            exceeding the original amount. Simplify payments by applying store credit to
                            Shipping and Sales Tax during checkout. Dive into Dashboards and Granular Data Tracking to
                            monitor key metrics and customer behavior.
                            Customize views with our White Labeled and Customizable feature for a flawless brand
                            experience across all devices.
                        </div>
                    </div>
                    <div className="pr-2">
                        <div className="case-study-button-rapper">
                            <a
                                href="#features"
                                className="read-stories-button w-inline-block mr-8"
                            >
                                <div>Explore features</div>
                                <img
                                    src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0dcd77a46e8c72ee0e_ic-fluent-chevron-bottom.svg"
                                    loading="lazy"
                                    alt=""
                                    className="arrow-bottom-icon"
                                />
                            </a>
                            <a
                                data-w-id="d1b0ad38-80b1-a999-42be-8c45ade72fa1"
                                // href="/signup"
                                className="success-story-button w-inline-block"
                            >
                                <div
                                    onClick={() => {
                                        sendSlackMessage(`Someone clicked on the Install on Shopify button from the features page.`, "#website-updates")
                                        window.open("https://apps.shopify.com/pabloo-store-credit", "_blank")
                                    }}
                                >Install on Shopify now
                                </div>
                                <img
                                    src="https://cdn.prod.website-files.com/61e8ec6584cc457e5ccb9501/625d6d0d2cd42d05507dd859_ic-fluent-chevron-right.svg"
                                    loading="lazy"
                                    style={{
                                        WebkitTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        MozTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        msTransform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                        transform:
                                            "translate3d(0px, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                                    }}
                                    alt=""
                                    className="arrow-right-icon"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="video-player-features-page">
                    <VideoPlayerFeatures/>

                </div>
            </div>
        </div>
    )
        ;
};

