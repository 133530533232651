//React imports
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';


//Imports the components

import FooterComponent from '../components/home/FooterComponent.js';
import NavBarComponent from '../components/navBar/NavBarComponent.js';

import SignupComponent from '../components/signUp/SignupComponent.js';

import HeaderComponent from '../components/HeaderComponent.js';


// import pricingPage from './pricingPage.jsx';


//Imports assets such as images
import { ProblemAndSolution } from "../components/home/problemAndSolutionView";
import {sendSlackMessage} from "../models/notifications";



function SignupPage() {
    useEffect(() => {

        sendSlackMessage("Visitor is visiting the Signup page", "#website-updates")
    }, [])

    return (
        <div>
            <div className="updated-page-wrapper">

                <div style={{
                    minHeight: "100vh",
                    background: "#faf2eb"
                }}>
                    <SignupComponent />
                </div>
                <FooterComponent />

            </div>
        </div>

    );
}

export default SignupPage;
