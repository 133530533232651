import React, { useState, useEffect } from "react";
import DesktopPricingComponent from "./DesktopPricingComponent";
import MobilePricingComponent from "./MobilePricingComponent";

const PricingComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [frequency, setFrequency] = useState("annually");

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleFrequencyChange = (selectedFrequency) => {
    setFrequency(selectedFrequency);
    window.gtag("event", "pricing_frequency_change", {
      frequency: selectedFrequency,
      event_callback: () => {
        window.gtag("get", "G-8G0V7WWP5L", "client_id", (clientId) => {
          fetch("https://server.pabloo.com/website/handle_submission", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "pricing_frequency_change",
              clientId: clientId,
              frequency: selectedFrequency,
            }),
          });
        });
      },
    });
  };

  return isMobile ? (
    <MobilePricingComponent
      frequency={frequency}
      handleFrequencyChange={handleFrequencyChange}
    />
  ) : (
    <DesktopPricingComponent
      frequency={frequency}
      handleFrequencyChange={handleFrequencyChange}
    />
  );
};

export default PricingComponent;
