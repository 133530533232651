export { default as PablooLogoCircle } from './pabloo_logo_circle.png'
// export { default as PablooLogoWithText } from './logo_with_text.svg'
export { default as PablooLogoWithText } from './logo_with_text_png.png'
export { default as BuiltForShopifyBadge } from './built_for_shopify.png'
export { default as PablooTrust } from './pabloo-trust.svg'
export { default as CheckCircle } from './check_circle.svg'
export { default as MoneyBackGuarantee } from './money_back_guarantee.png'
export { default as frontOne} from './front-1.svg'
export { default as frontTwo} from './front-2.svg'
export { default as frontThree} from './front-3.svg'
export { default as frontFour} from './front-4.svg'
export { default as frontFive} from './front-5.svg'
export { default as seedArrow} from './button-arrow-right.svg'
export { default as BenMiller} from './ben_miller.png'
export { default as Man1 } from "./man1.png"
export { default as Woman1 } from "./woman1.png"
export { default as DashboardImage } from "./dashboard_image.png"
export { default as LoyaltyProgramImage } from "./loyalty_program_image.png"
export { default as MobileCompatibility } from "./mobileCompatibility.png"
export { default as SupportedPlatforms } from "./supportedPlatforms.png"
export { default as UpsellProduct } from "./upsellProduct.png"
export { default as CustomerInterface } from "./customerInterface.png"
export { default as PablooDashboardImage } from "./pabloo_dashboard_image.png"
export { default as CurrencyRetailing } from "./currency_retailing.png"
export { default as WoocommerceLogo } from "./woocommerce-logo.png"
export { default as Refunds1 } from "./refunds.png"
export { default as ExcelImage } from "./excelImage.png"
export { default as AnnoyingCustomersRefunds } from "./annoyingCustomersRefunds.png"
export { default as AdsCampaignOnExcel } from "./adsCampaignOnExcel.png"
// export { default as ShopifyDashboard } from "./shopifyDashboard.png"
export { default as ShopifyDashboard } from "./shopify_dashboard1.png"
export { default as CustomerSupportChatDashboard } from "./customer_support_chat_dashboard.png"
export { default as InactiveCustomers } from "./inactive_customers.png"
export { default as PablooOnShopify } from "./pabloo_on_shopify.png"
export { default as BuiltForShopifyBanner } from "./built_for_shopify_banner.png"
export { default as StoreCreditCheckoutMobile } from "./store_credit_checkout_mobile.png"  // TODO: Use this image somewhere
export { default as PricingCollage } from "./pricingCollage.mp4"
export { default as StoreCreditGif } from "./store_credit_gif.gif"
export { default as CaretLeft } from "./CaretLeft.svg";
export { default as CaretRight } from "./CaretRight.svg";
