import React from "react";
// import "./cash.css";

const ProductDeepDiveSection = () => {
  return (
    <div class="product-deep-dive-section_grid__eMpwD">
      <div>
        <img
          src="https://images.ctfassets.net/jwea2w833xe7/3of29GeLEx3Ftc7euTaVcJ/5c5367e8cf6bca116ef68901d9333374/deepdive-img1.webp"
          class="product-deep-dive-section_deepDiveImage__HOXqC image_lazyImage__d2FAN image_isLoaded__sWHYi"
          alt=""
        />
      </div>
      <div class="product-deep-dive-section_deepDiveText__I2cnc">
        <div
          data-identifier="rich-text"
          class="formatted-text_formattedText__2_2NW"
        >
          <div class="product-page-text_size-1__rn5rs">
            <h3 class="product-page-heading-text_productPageHeading__7H9a7 product-page-heading-text_h4__ZEzVt product-page-heading-text_bold__Nmldk">
              File your federal and state taxes for free
            </h3>
            <p class="product-page-text_size-1__rn5rs">
              Even if your taxes are complicated, it costs $0 to file—no
              upsells, no hidden fees. Plus, we include free audit defense with
              every return.
            </p>
          </div>
        </div>
      </div>
      <div>
        <img
          src="https://images.ctfassets.net/jwea2w833xe7/3rC6lGkqcbEiJCN9JwuyPi/8ed468c00d1315f53dfd5ce2434e6968/deepdive-img2.webp"
          class="product-deep-dive-section_deepDiveImage__HOXqC image_lazyImage__d2FAN image_isLoaded__sWHYi"
          alt=""
        />
      </div>
      <div class="product-deep-dive-section_deepDiveText__I2cnc">
        <div
          data-identifier="rich-text"
          class="formatted-text_formattedText__2_2NW"
        >
          <div class="product-page-text_size-1__rn5rs">
            <h3 class="product-page-heading-text_productPageHeading__7H9a7 product-page-heading-text_h4__ZEzVt product-page-heading-text_bold__Nmldk">
              Easy from your computer or phone
            </h3>
            <p class="product-page-text_size-1__rn5rs">
              Most people can file in minutes with our step-by-step
              instructions. You can even snap a photo of your W-2 to add it
              automatically.
            </p>
          </div>
        </div>
      </div>
      <div>
        <img
          src="https://images.ctfassets.net/jwea2w833xe7/7GJ5cNiQLUBjreSl3FAne9/d6a6ccd34ae1fccc4c3e0f61c82f1455/deepdive-img3.png"
          class="product-deep-dive-section_deepDiveImage__HOXqC image_lazyImage__d2FAN image_isLoaded__sWHYi"
          alt=""
        />
      </div>
      <div class="product-deep-dive-section_deepDiveText__I2cnc">
        <div
          data-identifier="rich-text"
          class="formatted-text_formattedText__2_2NW"
        >
          <div class="product-page-text_size-1__rn5rs">
            <h3 class="product-page-heading-text_productPageHeading__7H9a7 product-page-heading-text_h4__ZEzVt product-page-heading-text_bold__Nmldk">
              Accurate calculations guaranteed
            </h3>
            <p class="product-page-text_size-1__rn5rs">
              Leave the math to us. We’ll make sure it’s right. Plus with our
              max refund guarantee, you’ll get every dollar back you deserve.¹
            </p>
          </div>
        </div>
      </div>
      <div>
        <img
          src="https://images.ctfassets.net/jwea2w833xe7/1nDrK63BRZwifo72TCfTYp/9fcfdfc1f5fcede40fd5beafa67b67bb/deepdive-img4-5days-discl.webp"
          class="product-deep-dive-section_deepDiveImage__HOXqC image_lazyImage__d2FAN image_isLoaded__sWHYi"
          alt=""
        />
      </div>
      <div class="product-deep-dive-section_deepDiveText__I2cnc">
        <div
          data-identifier="rich-text"
          class="formatted-text_formattedText__2_2NW"
        >
          <div class="product-page-text_size-1__rn5rs">
            <h3 class="product-page-heading-text_productPageHeading__7H9a7 product-page-heading-text_h4__ZEzVt product-page-heading-text_bold__Nmldk">
              Get your refund up to 5 days early
            </h3>
            <p class="product-page-text_size-1__rn5rs">
              Deposit your refund into Cash App to get it faster than many
              banks.²
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDeepDiveSection;
