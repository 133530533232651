// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.padding {
  padding-bottom: 50px;
  width: auto;
}

.custom-div {
  background: #200010;
  opacity: 0.95;
  border-radius: 1rem;
}

.w-col {
  float: left;
  width: 100%;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

/* Responsive styles for mobile view */
@media (max-width: 768px) {
  .custom-div {
    margin-top: 30%;
    margin-bottom: 10%;
    padding-bottom: 57%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/signup-mobile.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA,sCAAsC;AACtC;EACE;IACE,eAAe;IACf,kBAAkB;IAClB,mBAAmB;EACrB;AACF","sourcesContent":[".padding {\n  padding-bottom: 50px;\n  width: auto;\n}\n\n.custom-div {\n  background: #200010;\n  opacity: 0.95;\n  border-radius: 1rem;\n}\n\n.w-col {\n  float: left;\n  width: 100%;\n  min-height: 1px;\n  padding-left: 10px;\n  padding-right: 10px;\n  position: relative;\n}\n\n/* Responsive styles for mobile view */\n@media (max-width: 768px) {\n  .custom-div {\n    margin-top: 30%;\n    margin-bottom: 10%;\n    padding-bottom: 57%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
