import HeroSection from "./heroSection";
// import "../../styles/features/features.css"

export default function ListOfFeatures() {


    // TODO: Make pages for each of these
    // https://crisp.chat/en/livechat/livechat-customization/
    let featuresList = [
        {
            id: 2,
            title: "Process refunds instantly with store credit",
            description: "Refund any order in full or in part with store credit. Also supports refunding more than the original amount for a better refund experience.",
            tier: "starter"
        },
        {
            id: 3,
            title: "Gift cards, discount codes, and custom offers",
            description: "Customers can stack store credit with discount codes, gift cards, promotional codes, and custom offers.",
            tier: "starter"
        },
        {
            id: 5,
            title: "Shipping and Sales Tax",
            description: "Customers are able to apply their store credit towards their shipping fees and sales tax during their purchases.",
            tier: "starter"
        },
        {id: 6, title: "Dashboards and granular data tracking", description: "", tier: "starter"},
        {
            id: 6,
            title: "All platform support",
            description: "Use Pabloo on your computer or on your phone.",
            tier: "starter"
        },
        {
            id: 7,
            title: "Direct integrations for Shopify and WooCommerce",
            description: "Pabloo integrates seamlessly with the leading e-commerce platforms.",
            tier: "starter"
        },
        {
            id: 8,
            title: "Loyalty program for north star metrics",
            description: "The Pabloo loyalty program incentivizes customer sign-ups, referrals, and purchases.",
            tier: "starter"
        },
        {
            id: 9,
            title: "White labeled and customizable",
            description: "Customizable views to perfectly match your shop's theme and branding, ensuring a flawless experience across all devices.",
            tier: "starter"
        },


        {
            id: 1,
            title: "Unlimited deposits, unlimited withdraws",
            description: "Simple, flat-rate pricing. No hidden fees per order.",
            tier: "plus"
        },
        {
            id: 4,
            title: "Credit expiration and product exclusion",
            description: "Maintain control over your store credit program by establishing expiration timelines and defining any product exclusions.",
            tier: "plus"
        },
        {
            id: 12,
            title: "Customer support assistant",
            description: "A dedicated customer support assistant will work closely with you to understand your needs.",
            tier: "plus"
        },
        {
            id: 13,
            title: "Email & SMS notifications",
            description: "Customers are kept informed every time their store credit is added, deducted, or earn cashback.",
            tier: "plus"
        },
        {
            id: 14,
            title: "Save documents local",
            description: "All bookkeeping data can be rendered locally for further analysis and integration with downstream applications.",
            tier: "plus"
        },  // Exporting customer data
        {
            id: 15,
            title: "Revert any completed transaction",
            description: "Handles any error you make when making a deposit or withdraw. Allows you revert store credit changes, including purchases.",
            tier: "plus"
        },
        {
            id: 16,
            title: "Legal currency retailing",
            description: "Your business can legally sell store credit to customers, offering customers flexible payment options and fostering long-term brand engagement.",
            tier: "plus"
        },
        {
            id: 17,
            title: "Opt-in affiliate program",
            description: "Each participating customer has an affiliate link; a portion of each sale generated through the link goes back to the affiliate.",
            tier: "plus"
        },
        {
            id: 18,
            title: "AI Abandoned cart solution (2.5x increase in checkout)",
            description: "Recover lost sales with our AI-powered abandoned cart solution (Boosts checkout rates by 2.3x).",
            tier: "plus"
        },
        {
            id: 19,
            title: "Interactive data with Pabloo AI chat",
            description: "Ask your data anything with our AI assistant.",
            tier: "plus"
        },
        {
            id: 20,
            title: "AI tool to reengage dormant customers",
            description: "Analyzes expected Customer Lifetime Value (CLTV) to determine the ideal store credit amount to gift each customer, driving repeat purchases within 60 days and optimizing long-term customer value.",
            tier: "plus"
        },


        {
            id: 1,
            title: "Website chat widget",
            description: "Engage with people that are browsing your website and get closer to your customers.",
            tier: "pro",
            badge: "most_used",
            image: ""
        },
        {
            id: 2,
            title: "Cash App & Venmo Transfers",
            description: "Customers can purchase store credit directly from Cash App or Venmo account.",
            tier: "pro",
            badge: "new"
        },
        {
            id: 4,
            title: "Full Checkout Support",
            description: "Supports Shopify Checkout, draft orders, and Wholesale.",
            tier: "plus",
            badge: ""
        },
        {
            id: 5, title: "Recurring Deposits", description: "Customer(s) recieve store credit on a recurring basis.",
            tier: "pro", badge: ""
        },
        {
            id: 6, title: "In-App Chat Support", description: "Get access to 24/7 support chat from within the app.",
            tier: "pro", badge: ""
        },
        {
            id: 8,
            title: "Purchase Triggers",
            description: "Engage with customers that exhibit purchase behavior, and need a little convincing.",
            tier: "pro",
            badge: ""
        },
        {
            id: 9,
            title: "Templates & Customer Segments",
            description: "Create default templates to use for store credit activities and build customer segments.",
            tier: "plus",
            badge: ""
        },
    ]


    return (
        <div>
            <div>
                <link rel="stylesheet" href="https://crisp.chat/static/stylesheets/pricing/pricing.min.css?a9b928e"
                      type="text/css"/>
                <script src="https://crisp.chat/static/javascripts/libs.min.js?a9b928e"
                        type="text/javascript"></script>
                <script src="https://crisp.chat/static/javascripts/common.min.js?a9b928e"
                        type="text/javascript"></script>
                <script src="https://crisp.chat/static/javascripts/lottie.min.js?a9b928e"
                        type="text/javascript"></script>
                <script src="https://crisp.chat/static/javascripts/pricing.min.js?a9b928e"
                        type="text/javascript"></script>
                <script defer data-domain="crisp.chat"
                        src="https://plausible.io/js/script.tagged-events.js"></script>

                <HeroSection/>

                <div id="body" data-page="pricing" style={{background: "#fff"}}>
                    <div className="body-secondary">
                        <div className="features-details body-main flex justify-center bg-green-900">
                            <div className="body-wrapper">
                                <h2 id="features"
                                    className="features-details-title body-main-title mackinacpro font-bold text-white"
                                    style={{color: "#fff"}}>
                                    The gold standard for professionals
                                </h2>
                                <div className="features-details-features">

                                    <div className="common-list">
                                        <div className="common-list-itself">
                                            <div className="common-list-itself-one">
                                                <div className="common-list-itself-one-grid">

                                                    {
                                                        featuresList.map((feature) => (
                                                            <div>
                                                                {cellView(
                                                                    feature.title,
                                                                    feature.description,
                                                                    feature.image,
                                                                    feature.badge,
                                                                    feature.url,
                                                                    feature.tier
                                                                )}
                                                            </div>
                                                        ))
                                                    }


                                                    {cellView(
                                                        "Request a feature",
                                                        "Submit a request for a feature or integration.",
                                                        "",
                                                        "",
                                                        "",
                                                        "starter"
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    // TODO: Add support for the image and the badge and the url
    function cellView(title, description, image, badge, url, tier) {
        return (
            <a href={Boolean(url) ? `/${url}` : "#"} data-disabled-soft="false" data-plan="basic"
               data-selected="true" className="common-list-itself-one-grid-one">
                {/*<div data-feature="livechat"*/}
                {/*     className="feature-details-one-illustration">*/}
                {/*    <div*/}
                {/*        className="feature-details-one-illustration-livechat-one lazy"></div>*/}
                {/*    <div*/}
                {/*        className="feature-details-one-illustration-livechat-two lazy"></div>*/}
                {/*</div>*/}
                <div className="common-list-itself-one-grid-one-wrapper">
                    <h3 className="common-list-itself-one-grid-one-title font-sans-bold">
                        {title}
                    </h3>
                    {tier === "starter" &&
                        <div
                            className="common-list-itself-one-grid-one-most-used font-sans-bold font-extrabold bg-green-900"
                            style={{color: "#014737", background: "#FFF"}}>
                            Free
                        </div>
                    }
                    {tier === "plus" &&
                        <div
                            className="common-list-itself-one-grid-one-most-used font-sans-bold font-extrabold bg-green-900"
                            style={{color: "#FFF", background: "#014737"}}>
                            Plus
                        </div>
                    }
                    {tier === "pro" &&
                        <div
                            className="common-list-itself-one-grid-one-most-used font-sans-bold font-extrabold text-green-900"
                            style={{background: "#f7e8c7", color: "#013427"}}>
                            Professional
                        </div>
                    }
                </div>
                <p className="common-list-itself-one-grid-one-description font-sans-regular">
                    {description}
                </p>
            </a>
        )
    }
}