import React from "react";

import '../../styles/contact/style.css';
import ContactForm from "./contactForm";

const ContactComponent = () => {
  return (
    <section className="lf we zi yk sl">
      <div className="hf y xi wk rc">
        <div className="zb kd ok yd uk ol">
          <div className="wb ld je sd">
            <div className="bd">
              <h2 className="uf bg hg jg dj cl">Contact Us</h2>
              <p className="bb vf cg fg kg dl wj gl">Clarity gives you the blocks &amp; components you need to create
                a truly professional website.</p>
            </div>
            <div className="zb kd wd fb li fk">
              <div>
                <h3 className="zf dg ig mg eg">USA OFFICE Hours</h3>
                <p className="ub vf dg jg">
                  Monday-Friday<br />
                  8:00 am to 5:00 pm </p>
              </div>
              <div>
                <h3 className="zf dg ig mg eg">Our Address</h3>
                <p className="ub vf dg jg">8502 Preston Rd. Ingle, <br />Maine 98380, USA</p>
              </div>
              <div>
                <h3 className="zf dg ig mg eg">Canada Office Hours</h3>
                <p className="ub vf dg jg">8502 Preston Rd. Ingle, <br />Maine 98380, USA</p>
              </div>
              <div>
                <h3 className="zf dg ig mg eg">Get In Touch</h3>
                <p className="ub vf dg jg">
                  +1-246-888-0653<br />
                  +1-222-632-0194 </p>
              </div>
            </div>
          </div>
          <ContactForm />
        </div>
      </div>
    </section>
  );
}

export default ContactComponent;