import React, { useState } from "react";
import "../../styles/pricing-skpabloocss/main.css";
import "../../styles/pricing-skpabloocss/icons.css";
import { CheckCircle } from "../../assets/assets";
import PricingToggle from "./pricingToggle.jsx";
import { sendSlackMessage } from "../../models/notifications.js";

const frequencies = [
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
];

const DesktopPricingComponent = ({ frequency, handleFrequencyChange }) => {
  const getStandardSubscriptionLink = () => {
    return frequency === "monthly"
      ? "https://buy.stripe.com/aEUg2ngQ52SX5La14b"
      : "https://buy.stripe.com/6oEaI3eHXbptgpO3ck";
  };

  const getMacOnlyLicenseLink = () => {
    return frequency === "monthly"
      ? "https://buy.stripe.com/3cs03p2Zf1OT3D2eV3"
      : "https://buy.stripe.com/7sI9DZ57n8dh1uU00a";
  };

  return (
    <div className="flex justify-center">
      <div className="pricing-box py-4" style={{ maxWidth: "80%" }}>
        <div className="flex justify-center pt-4">
          <div>
            <div className="flex justify-center">
              <PricingToggle
                frequency={frequency}
                onFrequencyChange={handleFrequencyChange}
              />
            </div>
            <div className="pricing-plan__cta-subtitle mt-2 text-sm p-1">
              Save over 15% by paying annually
            </div>
          </div>
        </div>
        <div
          className="pricing-plans space-x-4"
          style={{
            paddingTop: "20px",
            paddingBottom: "24px",
            justifyContent: "center",
          }}
        >
          <div className="flex justify-center">
            <div
              className="pricing-plan pricing-plan--license"
              style={{ marginRight: "24px" }}
            >
              <div>
                <div className="skpabloo-selection-box skpabloo-selection-box--crosses">
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-left"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-right"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-left"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-right"/>
                </div>
                <div className="pricing-plan__inner-padding">
                  <div className="pricing-plan__tags">
                    <span className="pill">Solo entrepreneurs</span>
                    <span className="pill">Small teams</span>
                  </div>
                  <h2 className="pricing-plan__name">Standard subscription</h2>
                  <span className="pricing-plan__price">
                    {frequency === "monthly" ? "$59" : "$49"}
                  </span>
                  <p className="pricing-plan__billing pricing-plan__billing--big">
                    {frequency === "monthly"
                        ? "Monthly"
                        : "Monthly, billed yearly"}
                  </p>
                </div>
                <div className="pricing-plan__divider-line"/>
                <div className="pricing-plan__inner-padding">
                  <p className="pricing-plan__billing pricing-plan__billing--small">
                    {frequency === "monthly" ? (
                        <span>
                        Or <strong>$49/month</strong>, billed yearly
                      </span>
                    ) : (
                        <span>
                        Or <strong>$59/month</strong>, billed monthly
                      </span>
                    )}
                  </p>
                  <div className="pricing-plan__features">
                    <div className="pricing-plan__feature-group">
                      <p className="pricing-plan__feature-title">
                        <svg
                            width={24}
                            height={24}
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                                d="M19 6.75l.25 9.5H4.75V7L19 6.75z"
                                stroke="#212123"
                                strokeWidth="1.5"
                                fillOpacity=".2"
                                fill="#000"
                            />
                            <rect
                                fill="#212123"
                                x={2}
                                y={17}
                                width={20}
                                height="1.5"
                                rx=".75"
                            />
                          </g>
                        </svg>
                        Store credit technology
                      </p>
                      <ul className="pricing-plan__feature-list space-y-0 m-0 p-0">
                        {rowView(
                            "Unlimited deposits, unlimited withdraws",
                            "Simple, flat-rate pricing. No hidden fees per order."
                        )}
                        {rowView(
                            "Immediate refunds with credit",
                            "Refund any order in full or in part with store credit. Also supports refunding more than the original amount for a better refund experience."
                        )}
                        {/*{rowView("Discount code and gift card compatibility")}*/}
                        {rowView(
                            "Gift cards, discount codes, and custom offers",
                            "Customers can stack store credit with discount codes, gift cards, promotional codes, and custom offers."
                        )}
                        {rowView(
                            "Credit expiration and product exclusion",
                            "Maintain control over your store credit program by establishing expiration timelines and defining any product exclusions."
                        )}
                        {rowView(
                            "Shipping and Sales Tax",
                            "Customers are able to apply their store credit towards their shipping fees and sales tax during their purchases.",
                            true
                        )}
                        {rowView("Dashboards and granular data tracking", null)}
                        {rowView(
                            "Direct integrations for Shopify and WooCommerce",
                            "Pabloo integrates seamlessly with the leading e-commerce platforms."
                        )}
                      </ul>
                    </div>

                    <div className="pricing-plan__feature-group">
                      <div className="flex items-center space-x-2">
                        <p className="flex items-center pricing-plan__feature-title h-8">
                          <svg
                              width={24}
                              height={24}
                              xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                                transform="translate(2 5)"
                                fill="none"
                                fillRule="evenodd"
                            >
                              <rect
                                  stroke="#212123"
                                  strokeWidth="1.5"
                                  x=".75"
                                  y=".75"
                                  width="18.5"
                                  height="12.5"
                                  rx={1}
                              />
                              <path fill="#212123" d="M1 3h18v1.5H1z"/>
                              <path fill="#212123" d="M5 4h1.5v9H5z"/>
                              <path
                                  fillOpacity=".2"
                                  fill="#000"
                                  d="M6 4h13v9H6z"
                              />
                            </g>
                          </svg>
                          Customer relationship
                        </p>
                      </div>

                      <ul className="pricing-plan__feature-list m-0 p-0">
                        {rowView(
                            "Loyalty program for north star metrics",
                            "The Pabloo loyalty program incentivizes customer sign-ups, referrals, and purchases."
                        )}
                        {rowView(
                            "White labeled and customizable",
                            "Customizable views to perfectly match your shop's theme and branding, ensuring a flawless experience across all devices."
                        )}
                        {rowView(
                            "Email notifications",
                            "Customers are kept informed every time their store credit is added, deducted, or earn cashback.",
                            true
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <a
                    // href={getStandardSubscriptionLink()}
                    href="https://apps.shopify.com/pabloo-store-credit"
                    target="_blank"
                    className="button button--primary pricing-plan__button"
                    style={{
                      paddingTop: "0px",
                      marginTop: "0px",
                      background: "#16a34a",
                    }}
                    onClick={() => {
                      const pricingInfo = getStandardSubscriptionLink() === "https://buy.stripe.com/aEUg2ngQ52SX5La14b" ? "$59 per month" : "$588 per year";

                      sendSlackMessage(`Someone has clicked on the Install on Shopify from the pricing page - Plus - ${pricingInfo}.`, "#website-updates");
                      window.gtag("event", "conversion_button_clicked", {
                        button: "view_shopify_listing",
                        source: "Pricing - Plus",
                      });

                      window.gtag("event", "buy_now_click", {
                        subscription_type: "Standard",
                        pricing_info: pricingInfo,
                        event_callback: () => {
                          window.gtag(
                              "get",
                              "G-8G0V7WWP5L",
                              "client_id",
                              (clientId) => {
                                fetch(
                                    "https://server.pabloo.com/website/handle_submission",
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        type: "buy_now_click",
                                        clientId: clientId,
                                        subscriptionType: "Standard",
                                        pricingInfo: pricingInfo,
                                      }),
                                    }
                                );
                              }
                          );
                        },
                      });
                    }}
                >
                  Start for free
                </a>
                <p className="pricing-plan__cta-subtitle cursor-pointer"
                   style={{
                     fontWeight: "500",
                     textDecorationLine: "underline"
                   }}
                   onClick={() => {
                     window.open(getStandardSubscriptionLink(), "_blank");

                     sendSlackMessage(`$$ Someone has clicked on the Buy Now from the pricing page - Plus.`, "#website-updates");
                     window.gtag("event", "conversion_button_clicked", {
                       button: "buy_now",
                       source: "Pricing - Plus",
                     });
                   }}
                >
                  Or purchase Standard license key
                </p>
              </div>
            </div>
            <div className="pricing-plan pricing-plan--license">
              <img
                  className="pricing-plan__sticker pricing-plan__sticker--license entrance entrance--zoom"
                  alt="Sticker"
                  data-controller="entrance"
                  style={{"--entrance-animation-delay": "500ms"}}
              />
              <div>
                <div className="skpabloo-selection-box skpabloo-selection-box--crosses">
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-left"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-right"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-left"/>
                  <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-right"/>
                </div>
                <div className="pricing-plan__inner-padding">
                  <div className="pricing-plan__tags">
                    <span className="pill">Medium-sized teams</span>
                    <span className="pill">Scaling</span>
                  </div>
                  <h2 className="pricing-plan__name">
                    Professional subscription
                  </h2>
                  <span className="pricing-plan__price">
                    {frequency === "monthly" ? "$125" : "$120"}
                  </span>
                  <p className="pricing-plan__billing pricing-plan__billing--big">
                    {frequency === "monthly"
                        ? "Monthly"
                        : "Monthly, billed yearly"}
                  </p>
                </div>
                <div className="pricing-plan__divider-line"/>
                <div className="pricing-plan__inner-padding">
                  <p className="pricing-plan__billing pricing-plan__billing--small">
                    {frequency === "monthly" ? (
                        <span>
                        Or <strong>$120/month</strong>, billed yearly
                      </span>
                    ) : (
                        <span>
                        Or <strong>$125/month</strong>, billed monthly
                      </span>
                    )}
                  </p>
                  <div className="pricing-plan__features">
                    <div className="pricing-plan__feature-group">
                      <ul className="pricing-plan__feature-list m-0 p-0">
                        {rowView("Everything in the Standard Subscription", "")}
                        {rowView(
                            "Customer support assistant",
                            "A dedicated customer support assistant will work closely with you to understand your needs.",
                            false,
                            true
                        )}
                      </ul>
                    </div>
                    <div className="pricing-plan__feature-group pricing-plan__feature-group--excludes">
                      <p className="pricing-plan__feature-title">
                        Essential for professionals
                      </p>
                      {rowView(
                          "Email & SMS notifications",
                          "Customers are kept informed every time their store credit is added, deducted, or earn cashback.",
                          true
                      )}
                      {rowView(
                          "Save documents local",
                          "All bookkeeping data can be rendered locally for further analysis and integration with downstream applications."
                      )}

                      {rowView(
                          "Revert any completed transaction",
                          "Handles any error you make when making a deposit or withdraw. Allows you revert store credit changes, including purchases."
                      )}

                      {rowView(
                          "Legal currency retailing",
                          "Your business can legally sell store credit to customers, offering customers flexible payment options and fostering long-term brand engagement.",
                          false,
                          true
                      )}

                      {rowView(
                          "Opt-in affiliate program",
                          "Each participating customer has an affiliate link; a portion of each sale generated through the link goes back to the affiliate."
                      )}
                    </div>

                    <div className="pricing-plan__feature-group pricing-plan__feature-group--excludes">
                      <p className="pricing-plan__feature-title">
                        Groundbreaking products
                      </p>
                      <ul className="pricing-plan__feature-list m-0 p-0">
                        {rowView(
                            "AI Abandoned cart solution (2.5x increase in checkout)",
                            "Recover lost sales with our AI-powered abandoned cart solution (Boosts checkout rates by 2.3x).",
                            true
                        )}
                        {rowView(
                            "Interactive data with Pabloo AI chat",
                            "Ask your data anything with our AI assistant."
                        )}
                        {rowView(
                            "AI tool to reengage dormant customers",
                            "Analyzes expected Customer Lifetime Value (CLTV) to determine the ideal store credit amount to gift each customer, driving repeat purchases within 60 days and optimizing long-term customer value.",
                            true
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <a
                    // href={getMacOnlyLicenseLink()}
                    href="https://apps.shopify.com/pabloo-store-credit"
                    target="_blank"
                    className="button button--primary pricing-plan__button"
                    style={{
                      paddingTop: "0px",
                      marginTop: "0px",
                      background: "#16a34a",
                    }}
                    onClick={() => {
                      const pricingInfo = getMacOnlyLicenseLink() === "https://buy.stripe.com/3cs03p2Zf1OT3D2eV3" ? "$125 per month" : "$1440 per year";

                      sendSlackMessage(`Someone has clicked on the Install on Shopify from the pricing page - Pro - ${pricingInfo}.`, "#website-updates");
                      window.gtag("event", "conversion_button_clicked", {
                        button: "view_shopify_listing",
                        source: "Pricing - Pro",
                      });

                      window.gtag("event", "buy_now_click", {
                        subscription_type: "Professional",
                        pricing_info: pricingInfo,
                        event_callback: () => {
                          window.gtag(
                              "get",
                              "G-8G0V7WWP5L",
                              "client_id",
                              (clientId) => {
                                fetch(
                                    "https://server.pabloo.com/website/handle_submission",
                                    {
                                      method: "POST",
                                      headers: {
                                        "Content-Type": "application/json",
                                      },
                                      body: JSON.stringify({
                                        type: "buy_now_click",
                                        clientId: clientId,
                                        subscriptionType: "Professional",
                                        pricingInfo: pricingInfo,
                                      }),
                                    }
                                );
                              }
                          );
                        },
                      });
                    }}
                >
                  Start for free
                </a>

                <p className="pricing-plan__cta-subtitle cursor-pointer"
                   style={{
                     fontWeight: "500",
                     textDecorationLine: "underline"
                   }}
                   onClick={() => {
                     window.open(getMacOnlyLicenseLink(), "_blank");

                     sendSlackMessage(`$$ Someone has clicked on the Buy Now from the pricing page - Pro.`, "#website-updates");
                     window.gtag("event", "conversion_button_clicked", {
                       button: "buy_now",
                       source: "Pricing - Pro",
                     });
                   }}
                >
                  Or purchase Professional license key
                </p>
              </div>
            </div>
          </div>

          {/*{bottomSection()}*/}
        </div>
      </div>
    </div>
  );

  function rowView(text, tooltipText, isNew, hasUnderline) {
    return (
        <li className="flex items-center hover:bg-orange-100 bg-opacity-30 rounded py-1">
          <div
              className={`${
                  tooltipText
                      ? "pricing-plan__feature__tooltip tooltip tooltip--new"
                      : ""
              } w-full h-full`}
              aria-label={tooltipText ? tooltipText : ""}
          >
            <div className="pricing-plan__feature">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--orange"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
              />
            </svg>
            {hasUnderline && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={152}
                height={8}
                viewBox="0 0 152 8"
                className="pricing-plan__feature--underlined"
              >
                <path
                  d="M1 6C53.716.317 99.547.243 151.047 4.623"
                  stroke="currentColor"
                  strokeWidth={2}
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                />
              </svg>
            )}
            {text}
          </div>
        </div>
        {isNew && (
          <div
            className="text-xs font-bold text-white p-1.5 py-1 rounded-full mr-4"
            style={{ background: "#f26725" }}
          >
            NEW
          </div>
        )}
      </li>
    );
  }

  function rowView2(text, tooltipText, isNew) {
    return (
      <li className="flex items-center hover:bg-orange-100 bg-opacity-30 rounded py-1">
        <div
          className={`${
            tooltipText
              ? "pricing-plan__feature__tooltip tooltip tooltip--new"
              : ""
          } w-full h-full`}
          aria-label={tooltipText ? tooltipText : ""}
        >
          <li className="pricing-plan__feature">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
              />
            </svg>
            {text}
          </li>
        </div>
        {isNew && (
          <div
            className="text-xs font-bold text-white p-1.5 py-1 rounded-full mr-4"
            style={{ background: "#f26725" }}
          >
            NEW
          </div>
        )}
      </li>
    );
  }

  function bottomSection() {
    return (
      <div className="pricing-plan pricing-plan--business pricing-plan__business">
        <div
          className="pricing-plan__business__sticker-wrapper entranc entrance--zoom"
          data-controller="entrance"
          style={{ "--entrance-animation-delay": "500ms" }}
        >
          <img
            alt="Sticker"
          />
        </div>
        <div
          className="pricing-plan__box"
          style={{ height: "500px", boxShadow: "0 0 0 0" }}
        >
          <div className="skpabloo-selection-box skpabloo-selection-box--crosses">
            <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-left" />
            <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-top-right" />
            <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-left" />
            <div className="skpabloo-selection-box__inner-box skpabloo-selection-box__inner-box-bottom-right" />
          </div>
          <div className="pricing-plan__business__inner pricing-plan__business__inner--left">
            <div className="pricing-plan__inner-padding">
              <div className="pricing-plan__tags">
                <span className="pill">Medium-sized teams</span>
                <span className="pill">Large organizations</span>
              </div>
              <h2 className="pricing-plan__name">Premium subscription</h2>
              <span className="pricing-plan__price">$20</span>
              <p className="pricing-plan__billing pricing-plan__billing--big">
                Monthly / per Editor, billed yearly
              </p>
            </div>
            <div className="pricing-plan__divider-line" />
            <div className="pricing-plan__inner-padding">
              <p className="pricing-plan__billing pricing-plan__billing--small">
                Available with <strong>yearly billing only</strong>
              </p>
              <a
                href="/support/contact/business"
                className="button button--secondary pricing-plan__business__button pricing-plan__business__button--desktop"
              >
                Get in touch
              </a>
            </div>
          </div>
          <div className="pricing-plan__business__inner pricing-plan__business__inner--right">
            <p className="pricing-plan__business__text">
              Let <strong>Pabloo</strong>, bring in the money
            </p>
            <div className="pricing-plan__features">
              <div className="pricing-plan__feature-group">
                <ul className="pricing-plan__feature-list">
                  {rowView2(
                    "Direct integrates into your customer support portal",
                    "SDK extension that extends your customer support portal."
                  )}
                  {rowView2("Cash-out option", "")}

                  <li className="pricing-plan__feature">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
                      />
                    </svg>
                    Store Credit for Abandoned Cart
                  </li>
                  {rowView2(
                    "Pay-By-Bank integrations",
                    "Customers can pay directly from their bank account, significantly reducing processing fees for your business."
                  )}
                  <li className="pricing-plan__feature">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
                      />
                    </svg>
                    AI Sales Predictor using Store Credit
                  </li>
                  <li className="pricing-plan__feature pricing-plan__feature--with-tooltip pricing-plan__feature--permissions">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
                      />
                    </svg>
                    Powerful permissions directory
                    <span
                      className="pricing-plan__feature__tooltip tooltip tooltip--new"
                      aria-label="Gain a detailed view of all Workspace Members and Guests — and easily manage which documents they can access."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        className="tooltip__icon pricing-plan__feature__tooltip-icon"
                      >
                        <defs>
                          <clipPath id="prefix__a__information">
                            <path d="M7.245 0c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801A5.452 5.452 0 01.801 10.93C.278 9.953 0 8.795 0 7.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 013.07.801C4.047.278 5.205 0 6.755 0h.49z" />
                          </clipPath>
                          <clipPath id="prefix__b__information">
                            <path d="M1 0a1 1 0 110 2 1 1 0 010-2z" />
                          </clipPath>
                        </defs>
                        <g
                          clipPath="url(#prefix__a__information)"
                          transform="translate(1 1)"
                        >
                          <path
                            id="background"
                            fill="rgba(255, 255, 255, 0.15)"
                            d="M0 0h14v14H0V0z"
                          />
                          <path
                            d="M7 10V6.25H6m-.5 4h3"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            fill="none"
                            strokeLinecap="round"
                            strokeMiterlimit={10}
                          />
                          <g
                            clipPath="url(#prefix__b__information)"
                            transform="translate(6 2)"
                          >
                            <path
                              d="M0 0h2v2H0V0z"
                              stroke="currentColor"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </li>
                  <li className="pricing-plan__feature">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
                      />
                    </svg>
                    Permissions Groups
                    <span
                      className="pricing-plan__feature__tooltip tooltip tooltip--new"
                      aria-label="Assign pre-made groups of Workspace Members to quickly manage group permissions"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        className="tooltip__icon pricing-plan__feature__tooltip-icon"
                      >
                        <defs>
                          <clipPath id="prefix__a__information">
                            <path d="M7.245 0c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801A5.452 5.452 0 01.801 10.93C.278 9.953 0 8.795 0 7.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 013.07.801C4.047.278 5.205 0 6.755 0h.49z" />
                          </clipPath>
                          <clipPath id="prefix__b__information">
                            <path d="M1 0a1 1 0 110 2 1 1 0 010-2z" />
                          </clipPath>
                        </defs>
                        <g
                          clipPath="url(#prefix__a__information)"
                          transform="translate(1 1)"
                        >
                          <path
                            id="background"
                            fill="rgba(255, 255, 255, 0.15)"
                            d="M0 0h14v14H0V0z"
                          />
                          <path
                            d="M7 10V6.25H6m-.5 4h3"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            fill="none"
                            strokeLinecap="round"
                            strokeMiterlimit={10}
                          />
                          <g
                            clipPath="url(#prefix__b__information)"
                            transform="translate(6 2)"
                          >
                            <path
                              d="M0 0h2v2H0V0z"
                              stroke="currentColor"
                              fill="currentColor"
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </li>
                  <li className="pricing-plan__feature">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      className="pricing-plan__feature__checkmark pricing-plan__feature__checkmark--purple"
                    >
                      <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16Zm3.416 4.376a.75.75 0 0 0-1.04.208l-3.444 5.163-1.856-2.227a.75.75 0 0 0-1.152.96l2.5 3a.75.75 0 0 0 1.2-.064l4-6a.75.75 0 0 0-.208-1.04Z"
                      />
                    </svg>
                    Archiving Projects and documents
                  </li>
                </ul>
              </div>
            </div>
            <p className="pricing-plan__business__text">
              Have <strong>over 25 Editors</strong> in your team? We’ll also
              throw in priority support, a dedicated customer success manager,
              as well as customized reviews and terms.
            </p>
            <a
              href="/signup/"
              className="button button--secondary pricing-plan__business__button pricing-plan__business__button--mobile"
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
    );
  }
};

function infoIcon(onClick) {
  return (
    <div
      onMouseOver={() => {
        onClick(true);
      }}
      onMouseLeave={() => {
        onClick(false);
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        className="tooltip__icon bg-gray-200 rounded-full"
      >
        <defs>
          <clipPath id="prefix__a__information">
            <path d="M7.245 0c1.55 0 2.708.278 3.686.801a5.452 5.452 0 012.268 2.268c.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686a5.452 5.452 0 01-2.268 2.268c-.978.523-2.136.801-3.686.801h-.49c-1.55 0-2.708-.278-3.686-.801A5.452 5.452 0 01.801 10.93C.278 9.953 0 8.795 0 7.245v-.49c0-1.55.278-2.708.801-3.686A5.452 5.452 0 013.07.801C4.047.278 5.205 0 6.755 0h.49z" />
          </clipPath>
          <clipPath id="prefix__b__information">
            <path d="M1 0a1 1 0 110 2 1 1 0 010-2z" />
          </clipPath>
        </defs>
        <g clipPath="url(#prefix__a__information)" transform="translate(1 1)">
          <path
            id="background"
            fill="rgba(255, 255, 255, 0.15)"
            d="M0 0h14v14H0V0z"
          />
          <path
            d="M7 10V6.25H6m-.5 4h3"
            stroke="currentColor"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeMiterlimit={10}
          />
          <g clipPath="url(#prefix__b__information)" transform="translate(6 2)">
            <path d="M0 0h2v2H0V0z" stroke="currentColor" fill="currentColor" />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default DesktopPricingComponent;
