import HeroSection from "./heroSection";
import {sendSlackMessage} from "../../models/notifications";
import {getIPAddress} from "../../models/locationHelpers";

export default function ListOfPartners() {

    let partnersList = [
        {
            id: 1,
            image: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fup_promote_banner.webp?alt=media&token=eb2ee712-af83-4e9c-84c7-741bc62f0047",
            logo: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fup_promote_logo.webp?alt=media&token=bfee2fdc-621f-4337-a957-25a7e114db5b",
            title: "UpPromote Affiliate Marketing",
            description: "UpPromote has everything you need to run your affiliate program completed with custom rules, commissions, and trackers in 4 steps.",
            badge: "Built for Shopify",
            cta: "https://partners.secomapp.com/apps/affiliate/Pabloo_app"
        },
        {
            id: 2,
            image: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fdoran_banner.webp?alt=media&token=b2073691-87d2-4054-8249-bef9e07b6cac",
            logo: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fdoran_logo.webp?alt=media&token=3b0ef2c7-3d52-4f42-9584-30980a54ecd1",
            title: "Doran Product Reviews App & QA",
            description: "Send review requests with discounts to gather photo reviews and video reviews by product reviews app.",
            badge: "Free",
            cta: "https://apps.shopify.com/doran-reviews-questions?utm_source=pabloo"
        },
        {
            id: 3,
            image: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fklaviyo_banner.webp?alt=media&token=5837c4a5-e1c1-48a4-acf8-170ec37f6214",
            logo: "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/partnerships%2Fexternal_partnerships%2Fklaviyo_logo.webp?alt=media&token=4e8e726c-4815-4d3f-92a7-52d81d7a4381",
            title: "Klaviyo: Email Marketing & SMS",
            description: "Enable 1:1 communications at scale and grow your business by connecting with your customers through text or email.",
            badge: "Free",
            cta: "https://apps.shopify.com/klaviyo-email-marketing?st_source=autocomplete"
        },
    ]
    return (
        <div>
            <div>

                <div id="body" style={{background: "#fff"}}>
                    <div className="">
                        <div className="flex justify-center bg-green-900">
                            <div className="mx-20 my-20">
                                <h2 id="partners"
                                    className="font-bold text-white"
                                    style={{color: "#fff"}}>
                                    Get the best apps for bigger businesses
                                </h2>
                                <div className="mt-12">
                                    <div className="grid grid-cols-3 gap-4">

                                        {
                                            partnersList.map((feature) => (
                                                <div>
                                                    {cellView(
                                                        feature.title,
                                                        feature.description,
                                                        feature.image,
                                                        feature.logo,
                                                        feature.badge,
                                                        feature.url,
                                                        feature.tier,
                                                        feature.cta
                                                    )}
                                                </div>
                                            ))
                                        }


                                        {cellView(
                                            "Request a partnership",
                                            "Submit a proposal for your app or agency. If it meets the Pabloo standard, we’ll welcome you as a partner.",
                                            "",
                                            "",
                                            "",
                                            "starter",
                                            "",
                                            "/partners/join"
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    // TODO: Add support for the image and the badge and the url
    function cellView(title, description, image, logo, badge, url, tier, cta) {
        return (
            <div
                className="bg-gray-100 flex flex-col cursor-crosshair rounded px-8 pt-2 pb-4">

                <div className="">
                    <h3 className="font-bold flex">
                        {
                            Boolean(logo) &&
                            <div className="w-16 h-16 mr-2">
                                <img className="rounded aspect-square" src={logo} alt={`${title} logo`}/>
                            </div>
                        }
                        <div>
                            {title}
                        </div>
                    </h3>

                    {
                        Boolean(image) &&
                        <div>
                            <img className="rounded" src={image} alt={title}/>
                        </div>
                    }
                    {/*{tier === "starter" &&*/}
                    {/*    <div*/}
                    {/*        className="flex font-extrabold bg-green-900 rounded py-1 px-2 text-sm"*/}
                    {/*        style={{color: "#014737", background: "#FFF", width: "fit-content"}}>*/}
                    {/*        Free*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{tier === "plus" &&*/}
                    {/*    <div*/}
                    {/*        className="flex font-extrabold bg-green-900 rounded py-1 px-2 text-sm"*/}
                    {/*        style={{color: "#FFF", background: "#014737", width: "fit-content"}}>*/}
                    {/*        Plus*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{tier === "pro" &&*/}
                    {/*    <div*/}
                    {/*        className="flex font-extrabold bg-green-900 rounded py-1 px-2 text-sm"*/}
                    {/*        style={{background: "#f7e8c7", color: "#013427", width: "fit-content"}}>*/}
                    {/*        Professional*/}
                    {/*    </div>*/}
                    {/*}*/}
                    {/*{tier === "premium" &&*/}
                    {/*    <div*/}
                    {/*        className="flex font-extrabold bg-green-900 rounded py-1 px-2 text-sm"*/}
                    {/*        style={{background: "#f7e8c7", color: "#013427", width: "fit-content"}}>*/}
                    {/*        Premium*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
                <p className="text-left mt-2 mb-4">
                    {description}
                </p>

                <div className="flex">
                    <div
                        className="py-2 px-2.5 font-semibold text-sm cursor-pointer bg-green-900 text-gray-100 rounded text-wrap"
                        onClick={() => {
                            getIPAddress((ipAddress) => {
                                sendSlackMessage(`${ipAddress} is visiting an app partner: ${title}`, "#website-updates")
                            })
                            window.open(cta, "_blank")
                        }}
                    >
                        Learn more
                    </div>
                </div>
            </div>
        )
    }
}