import React from "react";
import {
  BuiltForShopifyBadge,
  PablooTrust,
  frontOne,
  frontTwo,
  frontThree,
  frontFour,
  frontFive,
  seedArrow,
  WoocommerceLogo,
} from "../../assets/assets";

import "../../styles/hero-pablooprod/normalize.css";
import styles from "../../styles/hero-pablooprod/style-global.css";
import {sendSlackMessage} from "../../models/notifications";

const PablooHeroComponent = () => {
  return (
    <div>
      <section
        id="sp-cgvhiz"
        className="sp-el-section pabloo-site-header-wrapper"
      >
        <div
          id="sp-bodumc"
          className="sp-el-row sm:flex w-full mx-auto justify-between"
        >
          <div id="sp-ri084u" className="sp-el-col w-full">
            <div
              id="sp-qdze2v"
              className="sp-el-block sp-shortcode-block-qdze2v pabloo-global-header sp-shortcode-wrapper"
            >
              <span>
                <div
                  className="relative z-10 w-full bg-white left-[-254px] p-10 flex font-medium text-lg text-gray-700 top-[60px] hidden shadow-md"
                  id="pabloo-desktop-menu-hover-help"
                ></div>
              </span>
            </div>
          </div>
        </div>
      </section>

      <section
        id="sp-bf2x0i"
        className="sp-el-section spBgfull"
        style={{ backgroundColor: "#fff4e400" }}
      >
        <div
          id="sp-w0z2ku"
          className="sp-el-row sm:flex w-full mx-auto justify-between pabloo-top-6-advantages"
        >
          <div id="sp-fbjtpp" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-rx7rbcq36q">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-noip8gjbhf">
              <div></div>
            </div>
            <figure id="sp-c3954l" className="sp-image-wrapper sp-el-block">
              <div>
                <span>
                  <img
                    src={frontOne}
                    alt="Pabloo zero processing fees"
                    width="45"
                    height="44"
                    className="sp-image-block-c3954l"
                    id="sp-8qg7evbmks"
                  />
                </span>
              </div>
            </figure>
            <div
              id="sp-rcew2o"
              className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-rcew2o"
            >
              <p>
                $0 Processing
                <br />
                Fees
              </p>
            </div>
          </div>

          <div id="sp-w7f6ni" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-57tae757ul">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-92wt676ghl">
              <div></div>
            </div>
            <figure id="sp-rxxrmh" className="sp-image-wrapper sp-el-block">
              <div>
                <span>
                  <img
                    src={frontTwo}
                    alt="Pabloo instant money transfer"
                    width="44"
                    height="44"
                    className="sp-image-block-rxxrmh"
                    id="sp-w7exd9s190"
                  />
                </span>
              </div>
            </figure>
            <div
              id="sp-gugx9z"
              className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-gugx9z"
            >
              <p>
                Instant Money
                <br />
                Transfer
              </p>
            </div>
          </div>

          <div id="sp-w3r3vo" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-2627k6knak">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-ek25ed39xq">
              <div></div>
            </div>
            <figure id="sp-eo00pm" className="sp-image-wrapper sp-el-block">
              <div>
                <span>
                  <img
                    src={frontThree}
                    alt="Pabloo store credit sales"
                    width="45"
                    height="44"
                    className="sp-image-block-eo00pm"
                    id="sp-8rniogyxaz"
                  />
                </span>
              </div>
            </figure>
            <div
              id="sp-x3lz7g"
              className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-x3lz7g"
            >
              <p>
                Store Credit
                <br />
                Sales
              </p>
            </div>
          </div>
          <div id="sp-kp3890" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-c28tj6pyqc">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-7sg8l5x19l">
              <div></div>
            </div>
            <figure id="sp-bn2fcb" className="sp-image-wrapper sp-el-block">
              <div>
                <span>
                  <img
                    src={frontFour}
                    alt="Pabloo one-thousand partners"
                    width="44"
                    height="44"
                    className="sp-image-block-bn2fcb"
                    id="sp-4paaylpy56"
                  />
                </span>
              </div>
            </figure>
            <div
              id="n3bicd"
              className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-n3bicd mce-content-body html4-captions"
            >
              <p>
                1,000+
                <br />
                Partners
              </p>
            </div>
          </div>
          <div id="sp-m64u5n" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-v9zbz4zfds">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-35ryj8r7un">
              <div></div>
            </div>
            <figure id="sp-seomfz" className="sp-image-wrapper sp-el-block">
              <div>
                <span>
                  <img
                    src={frontFive}
                    alt="Pabloo advanced account assistant"
                    width="44"
                    height="44"
                    className="sp-image-block-seomfz"
                    id="sp-istm31lqm1"
                  />
                </span>
              </div>
            </figure>
            <div
              id="sp-lbrdh0"
              className="sp-css-target sp-text-wrapper sp-el-block sp-text-wrapper-lbrdh0"
            >
              <p>
                Advanced
                <br /> Account Assistant
              </p>
            </div>
          </div>
        </div>
        {/* cut off for icons */}
        <div
          id="sp-m3i63r"
          className="sp-el-row sm:flex w-full mx-auto justify-between"
        >
          <div id="sp-ui35qo" className="sp-el-col w-full">
            <div className="sp-col-shape sp-col-top" id="sp-vqh2jd1uj8">
              <div></div>
            </div>
            <div className="sp-col-shape sp-col-bottom" id="sp-xbkc7v8bui">
              <div></div>
            </div>
            <div id="sp-pwnhx4" className="sp-spacer"></div>

            {/*<div className="flex justify-center bg-green-400">*/}
            {/*  <div id="sp-button-parent-cii6rt" className="sp-button-wrapper sp-el-block grid grid-cols-1 space-x-4 bg-orange-400 justify-center">*/}
            {/*    <div>*/}
            {/*      <input className="text-white text-2xl py-2 px-4 rounded-lg border-none h-16" style={{background: "#44403c80"}}  />*/}
            {/*    </div>*/}
            {/*    <a href="/pricing" id="sp-cii6rt" target rel className="sp-button inline-block text-white rounded-full py-4 px-8 text-lg home-hero-button">*/}
            {/*    <span>*/}
            {/*      <strong>Get Pabloo Now</strong>*/}
            {/*    </span>*/}
            {/*      <img src={seedArrow} alt="Pabloo_Arrow" className="sp-ml-2" />*/}
            {/*    </a>*/}
            {/*  </div>*/}
            {/*</div>*/}


            {/*<div classname="w-full bg-orange-300">*/}
            {/*  <div className="w-full h-screen flex justify-center rounded-xl" style={{*/}
            {/*    // backgroundImage: "linear-gradient(135deg, #fbeded, #FEC45B)"*/}
            {/*  }}>*/}

            {/*    /!*<video className="h-full w-full rounded-lg" controls>*!/*/}
            {/*    /!*  <source src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/pabloo%2Fpabloo_video.mp4?alt=media&token=42512ef9-1637-47d7-9649-3699e1e9c231" type="video/mp4" />*!/*/}
            {/*    /!*  Your browser does not support the video tag.*!/*/}
            {/*    /!*</video>*!/*/}
            {/*    <div className="w-3/4 h-full rounded-xl border-12" style={{*/}
            {/*      borderImage: "linear-gradient(135deg, #fbeded, #FEC45B)",*/}

            {/*      border: "10px solid transparent",*/}
            {/*      padding: "15px"*/}
            {/*    }}>*/}
            {/*      /!*<iframe src="https://player.vimeo.com/video/981496018?h=9a341d61c8" className="w-full h-full"*!/*/}
            {/*      /!*        frameBorder="0" allow="" allowFullScreen></iframe>*!/*/}
            {/*      <iframe className="w-1/2 h-1/3 rounded" src="https://www.youtube.com/embed/mVTksfdKOzo?si=hqDbM2Lb3Wl_hPVF"*/}
            {/*              title="YouTube video player" frameBorder="0"*/}
            {/*              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"*/}
            {/*              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div id="mobile-margin" className="flex justify-center">
              {/*TODO: Send merchant to pricing page and submit their email - this only works if they provide an email */}
              <a
                href="https://apps.shopify.com/pabloo-store-credit"
                target="_blank"
                id="sp-cii6rt"
                rel="noopener noreferrer"
                className="sp-button inline-block text-white rounded-full py-4 px-8 text-lg home-hero-button"
                onClick={() => {
                  sendSlackMessage("Someone has clicked on the Install on Shopify from the home page - Hero section.", "#website-updates");

                  window.gtag("event", "conversion_button_clicked", {
                    button: "view_shopify_listing",
                    source: "Hero section"
                  });
                }}
              >
                <span>
                  <strong>Start for free</strong>
                </span>
                <img src={seedArrow} alt="Pabloo_Arrow" className="sp-ml-2" />
              </a>
            </div>

            <div id="sp-rufcae" className="sp-spacer"></div>
            <div id="sp-bjowye" className="sp-spacer"></div>
          </div>
        </div>
        <div
          id="sp-aqq5vl"
          className="sp-el-row sm:flex w-full mx-auto justify-between"
        >
          <div
            id="sp-cgf214"
            className={`grid grid-cols-3 gap-0 gap-x-8 ${styles.spCgf214}`}
          >
            <div className="flex justify-center bg-white px-4">
              <img
                src={WoocommerceLogo}
                alt="Pabloo Woocommerce Integration"
                className="woocommerce-logo"
              />
            </div>

            <div className={`flex justify-center bg-white py-2`}>
              <img
                src={BuiltForShopifyBadge}
                alt="Pabloo Shopify App"
                className="shopify-badge"
              />
            </div>

            <div className="flex justify-center bg-white ">
              <img
                src={PablooTrust}
                alt="Pabloo Resources Secure"
                className="pabloo-trust"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PablooHeroComponent;
