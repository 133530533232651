import React from "react";

const TermsComponent = () => {
    return (
        <div>
            <style dangerouslySetInnerHTML={{ __html: "\n        * {\n            color: #202124;\n        }\n    " }} />
            <div className="updated-page-wrapper">
                <main className="main-wrapper">
                    <div className="privacy-section">
                        <div className="wrapper-1300px">
                            <div className="privacy-content-wrapper">
                                <div className="privacy-rich-text w-richtext">
                                    <div className="w-embed w-script">
                                        <div id="ajaxContent" />
                                        <div className="WordSection1">
                                            <p className="MsoNormal" align="center" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '.0001pt', marginLeft: '56.65pt', textAlign: 'center', border: 'none' }}><b><span lang="EN-IN" style={{ background: 'white' }}>TERMS OF SERVICE</span></b></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><a name="_heading=h.gjdgxs" /><b><span lang="EN-IN" style={{ background: 'white' }}>&nbsp;</span></b></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><a name="_heading=h.qz1flbou0z64" /><span lang="EN-IN" style={{ background: 'white' }}>1. <strong>Welcome</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Welcome to the Pabloo website (the "Site") . The Site and any related services, functions, software or platforms (collectively, the "Services") were created by us in order to provide you a much easier way to run competitions and campaigns on your website & social networks. These Pabloo Terms & Conditions (the "Terms") constitute a set of rules by which we operate such services.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>By using or accessing the Services, you acknowledge that you agree to the Terms, are subject to them and to our Privacy Policy. IF YOU DO NOT AGREE TO BE FULLY BOUND BY ALL OF THESE TERMS, PLEASE DO NOT ACCESS THE SITE AND DO NOT USE THE SERVICES. You should read through all the Terms carefully, as they constitute a legally binding agreement between you and us. Please note that we reserve the right, at our sole discretion, to revise modify or change or remove portions or all of these, at any time. The date when these Terms were last updated is indicated at the end of the Terms.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>2. <strong>Registration and Access</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>In order to access the Services, you must first create a campaign to use them by providing complete information requested and setting up your parameters. The information provided can be updated at any time later by accessing your campaign page.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Upon signing in to your campaign and using the Service, all such users shall also become subject to these Terms and each user will be responsible for the activity that occurs through their campaign and information shared.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>We urge you to keep your account password secure and to always log off from the Services when leaving your computer unattended. We strongly recommend notifying us immediately of unauthorized use of your account or of any related security breach by writing to us at pabloo@pabloo.com.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>3. <strong>Ownership of the Services Provided to You</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>The underlying platforms of the Services are either the proprietary property of Pabloo or the proprietary property of our licensors or licensees. By using our Services, Pabloo grants you a personal, revocable, non-assignable, and non-exclusive license to use the Services for the term of the campaign in accordance with the Terms, unless terminated earlier.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>“Pabloo” and other trademarks, slogans, service marks and trade names which appear via the Service are proprietary to Pabloo. You shall not remove or alter any copyright notice, trademark, or other proprietary or restrictive notice or legend affixed to any material provided by Pabloo. Except as expressly set forth herein, no right, license, or interest to such trademarks are generated or granted hereunder.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Please note that Pabloo facilitates companies running competitions and campaigns which as a user you can enter. As an entrant you acknowledge that "Competition Owner" is fully responsible for ensuring that their competition complies with all applicable rules & regulations in the countries for which it is operating. Pabloo is not responsible for; or liable for the failure of any Competition Owner to comply with these rules.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>"Competition Owners" must also be responsible for ensuring that their competition fully complies with all rules & regulations of any social network or Third Party on which is it run. Pabloo will not be held responsible for the loss of any data or accounts due to actions from Third Parties that relate to activity that is against their guidelines.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Pabloo can be used on any website in any country, by using Pabloo you acknowledge that you are responsible for compliance with any local laws.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Pabloo may require you to provide personally identifiable information in order to participate in certain promotions. In the event that you choose to provide personally identifiable information, you acknowledge and agree that Pabloo may use any information you provide consistent with Pabloo and these Terms of Service.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>4. <strong>Technical Support</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Technical support is provided to you by Pabloo via email during regular business hours (i.e. Monday to Friday from 9AM CET to 6PM AEST) by sending your technical inquiries to pabloo@pabloo.com. Pabloo shall respond to via email to your support request within a reasonable time-period.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>5. <strong>Third Party Applications & Reliability</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>While the primary purpose of the Services is to aggregate third party websites, applications, interfaces and other materials ("Third Party Applications"), it should be made clear that Pabloo does not own or control any of these. As Pabloo serves only as collector of such Third Party Applications for your convenience, it does not in any way endorse, recommend or make any recommendations in relation to any such Third Party Applications. Similarly, while Pabloo monitors the working condition of the connectors to such Third Party Applications and is committed to resolving any issues that may arise from a third party changing the login procedure of its application, it is not liable, nor can it be held responsible, for any changes or the inoperability of such Third Party Applications, whether temporary or permanent.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Pabloo also assumes no responsibility for the content, guidelines, privacy policies, or practices of any third party and by using the Services you expressly relieve Pabloo from any and all liability arising from your use of any Third Party Applications.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>You further acknowledge that such Third Party Applications may be protected by copyrights, patents, trademarks, trade secrets and/or other proprietary rights, and therefore you undertake not to modify, publish, transmit, distribute, perform, participate in the transfer or sale, create derivative works of, or in any way exploit, any of the Third Party Applications, in whole or in part, and undertake to abide by the relevant terms of use of such Third Party Applications.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>6. <strong>Right to Shut Down, Deny or Limit Access, Remove Content</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>Pabloo reserves the right to suspend or terminate your account at any time and for any or no reason at all, at our sole discretion. Accordingly, Pabloo reserves the right to remove any user content, which is abusive, illegal, disruptive, or that otherwise fails to conform to our standards, and to limit or revoke your access in full or in part to the Services, in its sole discretion, at any time, and for any reason, including, but not limited to technical difficulties or violation of these Terms.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>7. <strong>Your Feedback</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>While we welcome any feedback or comments from you, please note that any unsolicited ideas, feedback or suggestions pertaining to our business, such as ideas for new or improved products or technologies, website or tool enhancements, processes, materials, marketing plans or new product names, will automatically upon submission become the property of Pabloo, without any compensation to you. While under no obligation to review such submissions or to keep such submissions confidential, Pabloo may use or redistribute any such submission and its contents for any purpose and in any way it deems.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>To provide such submissions or feedback, please email pabloo@pabloo.com</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>8. <strong>No Warranty</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>YOU ACKNOWLEDGE, THAT TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THAT THE SERVICES, THE SITE AND OTHER MATERIALS PROVIDED TO YOU UNDER THIS AGREEMENT ARE PROVIDED ON AN “AS IS” BASIS. USE OF THE SERVICES AND RELIANCE ON THE SITE CONTENT IS DONE SOLELY AT YOUR OWN RISK. Pabloo DISCLAIMS ANY AND ALL WARRANTIES RELATING TO THE SERVICE, THE SITE, THEIR CONTENT AND OTHER MATERIALS, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>WE DO NOT WARRANT THAT THE SITE OR THE SERVICES PROVIDED WILL BE ERROR-FREE, THAT ACCESS THERETO WILL BE UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVERS THAT MAKE OUR SERVICES AVAILABLE ARE OR WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</span></p>

                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>9. <strong>Confidentiality and Privacy</strong></span></p>
                                            <p className="MsoNormal" style={{ marginTop: '0in', marginRight: '32.55pt', marginBottom: '8.0pt', marginLeft: '56.65pt', textAlign: 'justify', lineHeight: '107%' }}><span lang="EN-IN" style={{ background: 'white' }}>We are committed to safeguarding any personal information that may be collected through our Services and to ensuring that you are fully informed as to how your personal information will be used and stored.</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default TermsComponent;
