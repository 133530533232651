import React, { useEffect } from 'react';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/dist/css/splide.min.css';

import bonaventura_logo from '../assets/bonaventura_logo.png';
import fabcycle_logo from '../assets/fabcycle_logo.png';
import gameology_logo from '../assets/gameology_logo.png';
import ferf_logo from '../assets/ferf_logo.png';
import kef_logo from '../assets/kef_logo.png';
import levelsix_logo from '../assets/levelsix_logo.png';
import mcobeauty_logo from '..//assets/mcobeauty_logo.png';
import mrsofttop_logo from '../assets/mrsofttop_logo.png';
import rackcity_logo from '../assets/rackcity_logo.png';


const setupSplide = (splideId, speed, gap) => {
  const splideElement = document.querySelector(splideId);
  if (splideElement) {
    return new Splide(splideId, {
      type: 'loop',
      drag: 'free',
      gap: gap,
      arrows: false,
      pagination: false,
      fixedWidth: true,
      autoScroll: {
        speed: speed,
      },
      extensions: { AutoScroll },
    }).mount({ AutoScroll });
  }
  return null;
};

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = resolve;
    script.onerror = reject;
    document.head.appendChild(script);
  });
};

const CarouselComponent = () => {
  useEffect(() => {
    const loadScriptsAndSetupSplide = async () => {
      try {
        await loadScript('https://tools.refokus.com/automatic-tabs/bundle.v1.0.0.js');

        // console.log('Setting up Splide instances...');
        setupSplide('#logos-train', 0.6, '80px');
        setupSplide('#features-train-1', 0.3, '20px');
        setupSplide('#features-train-2', 0.5, '20px');
        setupSplide('#features-train-3', 0.4, '20px');

        const reviewSlider = document.querySelector('#reviewslider div:nth-child(2)');
        if (reviewSlider) {
          reviewSlider.click();
        }
      } catch (error) {
        console.error('Error loading scripts:', error);
      }
    };

    loadScriptsAndSetupSplide();
  }, []);

  return (
    <div className="logos-main-wrapper">
      <div id="logos-train" className="splide">
        <div className="splide__track">
          <div className="logo-colour-overlay new"></div>
          <div className="splide__list">
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={ferf_logo} alt="Ferf" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={fabcycle_logo} alt="Fabcycle" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={gameology_logo} alt="Gameology" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={rackcity_logo} alt="Rack City" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={mrsofttop_logo} alt="Mr.Soft Top" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={mcobeauty_logo} alt="ModelCo" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={kef_logo} alt="Kef" className="logo-tain-image puuffco" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={levelsix_logo} alt="Level Six" className="logo-tain-image" />
              </div>
            </div>
            <div className="splide__slide">
              <div className="logo-image-contain">
                <img loading="lazy" src={bonaventura_logo} alt="Bonaventura" className="logo-tain-image" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="logos-white-gradient new"></div>
      <div className="logos-white-gradient right new"></div>
    </div>
  );
};

export default CarouselComponent;
