import NavBarComponent from "../navBar/NavBarComponent";
import HeaderComponent from "./HeaderComponent";
import PablooHeroComponent from "../home/PablooHeroComponent";
import CarouselComponent from "../CarouselComponent";
import StatsBlockComponent from "../home/StatsBlockComponent";
import { ProblemAndSolution } from "../home/problemAndSolutionView";
import FooterComponent from "../home/FooterComponent";

export default function ThankYouComponent() {
  return (
    <div>
      <div className="updated-page-wrapper">
        <NavBarComponent />
        <HeaderComponent>
          {/*<div className="my-8">*/}
          {/*  <CarouselComponent />*/}
          {/*</div>*/}
        </HeaderComponent>
        {/*<div className="mb-10">*/}
        {/*    <StatsBlockComponent />*/}
        {/*</div>*/}
        {/*<div className="mb-40">*/}
        {/*    <ProblemAndSolution />*/}
        {/*</div>*/}
        <div>
          <FooterComponent />
        </div>
      </div>
    </div>
  );
}
