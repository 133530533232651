import React, { useState, useEffect, useRef } from "react";
import pabloo_transparent_logo_cropped from "../../../assets/pabloo_transparent_logo_cropped.png";
import { PablooLogoWithText } from "../../../assets/assets";
import { useNavigate } from "react-router-dom";
import "../../../styles/main.css"


const DesktopNavBarComponent = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos > prevScrollPos) {
      setVisible(false);
      backgroundColorRef.current.style.backgroundColor = "#FFFFFF";
      outterDiv.current.style.backgroundColor = "#16a34a";
      outterDiv.current.style.color = "#FFFFFF";
      outterDiv.current.style.border = "2px";
      innerDiv.current.style.backgroundColor = "#16a34a";
      innerDiv.current.style.color = "#FFFFFF";
    } else {
      setVisible(true);
      backgroundColorRef.current.style.backgroundColor = "#FFFFFF00";
      outterDiv.current.style.backgroundColor = "#FFFFFF00";
      outterDiv.current.style.color = "#16a34a";
      outterDiv.current.style.border = "2px solid #16a34a";
      innerDiv.current.style.backgroundColor = "#FFFFFF00";
      innerDiv.current.style.color = "#16a34a";
    }
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const backgroundColorRef = useRef();
  const outterDiv = useRef();
  const innerDiv = useRef();

  const handleSectionClick = (section) => {
    const event = new CustomEvent("scrollToSection", {
      detail: { section },
    });
    window.dispatchEvent(event);
    navigate(`/?section=${section}`);
  };

  useEffect(() => {
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = () => resolve(script);
        script.onerror = () =>
          reject(new Error(`Failed to load script ${src}`));
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        await loadScript(
          "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Ftribe-query.js?alt=media&token=cb0874bb-4c9d-49b9-b392-ea338184edd7"
        );
        await loadScript(
          "https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2FpablooFlow.js?alt=media&token=61b7ddf9-9928-4a1b-b9da-0a4512a2f909"
        );
        setScriptsLoaded(true);
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScripts();

    return () => {
      const jQueryScript = document.querySelector(
        'script[src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Ftribe-query.js?alt=media&token=cb0874bb-4c9d-49b9-b392-ea338184edd7"]'
      );
      const webflowScript = document.querySelector(
        'script[src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2FpablooFlow.js?alt=media&token=61b7ddf9-9928-4a1b-b9da-0a4512a2f909"]'
      );
      if (jQueryScript) jQueryScript.remove();
      if (webflowScript) webflowScript.remove();
    };
  }, []);

  return (
    <div
      ref={backgroundColorRef}
      data-w-id="71804d9f-56d6-3e28-5ac2-6231ebcc7be3"
      data-animation="default"
      data-collapse="medium"
      data-duration={400}
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="hello-navigation-bar w-nav"
    >
      <div className="hello-navbar-container">
        <a href="/" aria-current="true">
          <div className="logo-contain">
            <div className="logo-text-contain">
              <img
                src={PablooLogoWithText}
                loading="lazy"
                alt="Pabloo logo"
                className="logo-image"
              />
            </div>
          </div>
        </a>
        <nav
          role="navigation"
          className="navigation-menu w-nav-menu"
          style={{
            height: "60px",
            marginTop: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            marginBottom: "0px",
          }}
        >
          <div className="navigation-inner-wrapper white">
            <div className="nav-links-contain">
              <div
                  data-hover="true"
                  data-delay={0}
                  className="navbar-dropdown w-dropdown"
              >
                <div
                    className="navbar-dropdown-toggle w-dropdown-toggle"
                    onClick={() => handleSectionClick("product")}
                >
                  <div className="navigation-link mobile-padding">
                    Product
                  </div>
                </div>
              </div>
              <div className="dropdown-links-divider"/>
              <div className="dropdown-links-divider"/>
              <div
                  data-hover="true"
                  data-delay={0}
                  className="navbar-dropdown w-dropdown"
              >
                <div
                    className="navbar-dropdown-toggle w-dropdown-toggle"
                    onClick={() => {
                      window.open("/case-study");
                    }}
                >
                  <div className="navbar-dropdown-text navigation-link mobile-padding">
                    Resources
                  </div>
                </div>
              </div>
              <div className="dropdown-links-divider"/>
              <div className="dropdown-links-divider"/>
              <div
                  data-hover="true"
                  data-delay={0}
                  className="navbar-dropdown w-dropdown"
              >
                <div
                    className="navbar-dropdown-toggle w-dropdown-toggle"
                    onClick={() => handleSectionClick("testimonials")}
                >
                  <div className="navbar-dropdown-text navigation-link mobile-padding">
                    Testimonials
                  </div>
                </div>
              </div>
              <div className="dropdown-links-divider"/>
              <div className="dropdown-links-divider"/>
              <div
                  data-hover="true"
                  data-delay={0}
                  className="navbar-dropdown w-dropdown"
              >
                <div
                    className="navbar-dropdown-toggle w-dropdown-toggle"
                    onClick={() => {
                      window.open("/pricing");
                    }}
                >
                  <div className="navbar-dropdown-text navigation-link mobile-padding">
                    Pricing
                  </div>
                </div>
              </div>
              <div className="dropdown-links-divider"/>
              <div className="dropdown-links-divider"/>
              <div
                  data-hover="true"
                  data-delay={0}
                  className="navbar-dropdown w-dropdown"
              >
                <div
                    className="navbar-dropdown-toggle w-dropdown-toggle"
                    onClick={() => {
                      window.open("/features");
                    }}
                >
                  <div className="navbar-dropdown-text navigation-link mobile-padding">
                    Features
                  </div>
                </div>
              </div>
              <div className="dropdown-links-divider"/>
              <div className="dropdown-links-divider"/>
              <div className="navigation-button-contain in-menu bottom">
                <a
                    href="/login"
                    target="_blank"
                    className="transparent-arrow-button _16px navigation login w-inline-block"
                    style={{marginRight: "76px"}}
                >
                  <div className="button-panels-contain">
                    <div className="button-panel"/>
                  </div>
                  <div className="button-text-wrapper">
                    <div
                        data-w-id="71804d9f-56d6-3e28-5ac2-6231ebcc7cfc"
                        className="button-text trans"
                    >
                      Login
                    </div>
                  </div>
                </a>
                <a
                    href="/signup"
                    className="pink-arrow-button _16px navigation w-inline-block"
                >
                  <div className="button-panels-contain">
                    <div className="button-panel"/>
                  </div>
                  <div className="button-text-wrapper">
                    <div className="button-text pink">Start for free</div>
                    <div className="button-arrow-embed _16px pink w-embed">
                      <svg
                          width={10}
                          height={17}
                          viewBox="0 0 10 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{width: "100%", height: "100%"}}
                      >
                        <path
                            d="M2 2L8.75 8.75L2 15.5"
                            stroke="currentColor"
                            strokeWidth="2.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="navigation-button-contain out-menu">
          <a
              href="/login"
              target="_blank"
              className="navigation-link login w-nav-link"
          >
            Login
          </a>
          <div
              className="cursor-pointer"
              onClick={() => {
                window.open("/signup", "_self");
              }}
          >
            <div
                ref={outterDiv}
                className="text-white px-1.5 py-1 bg-green-700 rounded-3xl h-12 w-52 flex justify-center items-center border rounded"
                style={{
                  background: "#FFFFFF00",
                  color: "#16a34a",
                border: "2px solid #16a34a",
              }}
            >
              <div className="button-text-wrapper flex justify-center items-center">
                <div
                  ref={innerDiv}
                  className="button-text"
                  style={{ background: "#FFFFFF00", color: "#16a34a" }}
                >
                  Start for free
                </div>
                <div className="button-arrow-embed w-embed">
                  <svg
                    width={6}
                    height={12}
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <path
                      d="M1 1.5L5 6L1 10.5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="navigation-menu-button w-nav-button"
        >
          <div>
            <div href="#" className="hamburger-wrapper">
              <div className="burger-line a"></div>
              <div className="burger-line b"></div>
              <div className="burger-line c"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopNavBarComponent;
