import React from "react";

const CaseStudyComponent = () => {
  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n        .text-limited {\n            overflow: hidden;\n            text-overflow: ellipsis;\n            display: -webkit-box;\n            line-height: 20px;\n            max-height: 64px;\n            -webkit-line-clamp: 3;\n            -webkit-box-orient: vertical;\n        }\n    ",
        }}
      />
      <div id="Case-Studies" className="case-study-section">
        <div className="wrapper-1280px case-study-flex">
          <div className="case-study-container">
            <div className="case-study-list-wrapper w-dyn-list">
              <div role="list" className="case-study-grid w-dyn-items">
                <div
                  role="listitem"
                  className="case-study-collection-item w-dyn-item"
                >
                  <a
                    href="/case-study?page=parks-project"
                    target="_blank"
                    className="case-study-item-wrapper w-inline-block"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620"
                      loading="lazy"
                      alt="Pabloo Case Studies and Success Stories"
                      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FparkProject.png?alt=media&token=be683457-2cfe-4a5f-bdde-a1bb2ab3a620 800w"
                      className="case-study-hero-item-image"
                    />
                    <div className="case-study-item-text-wrapper">
                      <div className="case-study-item-pink-tag">
                        Parks Project
                      </div>
                      <div className="case-study-item-heading">
                        Passion, Purpose, and Parks Project — An Interview With
                        Keith Eshelman, Co-Founder and CEO
                      </div>
                      <div className="case-study-item-para-small text-limited">
                        Keith Eshelman, the co-founder, and CEO of Parks
                        Project- an e-commerce apparel brand on the mission to
                        preserve parklands for generations to come by educating,
                        advocating, volunteering, and activating park supporters
                        to get involved in conservation. As a nature enthusiast,
                        Keith has been able to turn his passion into a
                        profitable business with the purpose to leave national
                        parklands better than they found them. To date, Parks
                        Project has contributed over 2.5 million dollars to help
                        fund vital projects in national parks around the United
                        States.
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  role="listitem"
                  className="case-study-collection-item w-dyn-item"
                >
                  <a
                    href="/case-study?page=balodana"
                    target="_blank"
                    className="case-study-item-wrapper w-inline-block"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35"
                      loading="lazy"
                      alt=""
                      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fbalodona.png?alt=media&token=d72cc278-bfb9-490c-a8b1-12c2b950cd35 800w"
                      className="case-study-hero-item-image"
                    />
                    <div className="case-study-item-text-wrapper">
                      <div className="case-study-item-pink-tag">Balodana</div>
                      <div className="case-study-item-heading">
                        Tech Marketing Pioneer Turned Fashion Entrepreneur: Dana
                        Todd’s Journey
                      </div>
                      <div className="case-study-item-para-small text-limited">
                        Dana Todd, the founder, and CEO of Balodana — an
                        e-commerce marketplace specializing in custom
                        made-to-measure clothing for women. Dana began her
                        professional journey in digital marketing and held
                        various positions as Chief Marketing Officer across
                        several industries before starting Balodana. In 2017,
                        Dana had a frustrating shopping experience related to
                        sizing and, after conducting further research, she
                        discovered that over 75% of women faced the same problem
                        with trying to navigate the inconsistent and inaccurate
                        sizing in traditional retail and online shopping. This
                        motivated her to launch Balodana. Today, Balodana is the
                        only online marketplace that provides thousands of
                        made-to-measure styles for women of all shapes, made
                        precisely to each customer’s measurements from talented
                        designers worldwide.
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  role="listitem"
                  className="case-study-collection-item w-dyn-item"
                >
                  <a
                    href="/case-study?page=planted-material"
                    target="_blank"
                    className="case-study-item-wrapper w-inline-block"
                  >
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d"
                      loading="lazy"
                      alt=""
                      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"
                      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FplantedMeterials.png?alt=media&token=f801a861-8fcc-49f6-8c67-2702843be26d 800w"
                      className="case-study-hero-item-image"
                    />
                    <div className="case-study-item-text-wrapper">
                      <div className="case-study-item-pink-tag">
                        Planted Materials
                      </div>
                      <div className="case-study-item-heading">
                        A Convo With the CEO of Planted Materials
                      </div>
                      <div className="case-study-item-para-small text-limited">
                        The Pabloo team sat down with Noah Belkhous, the
                        co-founder and CEO of Planted Materials — a clean-tech
                        company focused on repurposing organic waste into
                        renewable materials. Green Water Labs is the biochemical
                        extension of Planted Materials, that is utilizing the
                        organic compounds found in plant waste to create
                        non-toxic alternatives to everyday chemicals in our
                        homes. Green Water Labs' first commercial product, Algae
                        Control, is a plant-based natural aquarium cleaner
                        treatment effective against many aquatic algae types and
                        cyanobacterias. Despite being primarily used in aquatic
                        environments, the solution is also effective in cleaning
                        algae off roofs, decks, patios, walkways, sidings, and
                        more — providing a safer cleaning option for homeowners!
                        Best of all, Algae Control is safe for various
                        ecosystems and does not harm kids, pets, plants, fish,
                        or any other aquatic living organisms when used
                        according to provided dosage guidelines.
                      </div>
                    </div>
                  </a>
                </div>

                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study?page=stzy"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fstzy.png?alt=media&token=e224725e-203f-49ee-8ef4-99f2347b463e"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fstzy.png?alt=media&token=e224725e-203f-49ee-8ef4-99f2347b463e 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fstzy.png?alt=media&token=e224725e-203f-49ee-8ef4-99f2347b463e 800w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">STZY</div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        US Naval Officer Turned Founder & CEO Anthony Cosby’s*/}
                {/*        Journey*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        In continuation of our founder’s series, this week the*/}
                {/*        Pabloo team talked with Anthony Cosby, a retired U.S.*/}
                {/*        Naval Officer turned founder and CEO of STZY — an*/}
                {/*        eCommerce brand that specializes in selling quality*/}
                {/*        athletic socks and slides. Anthony’s team shares a deep,*/}
                {/*        genuine respect for the effort, dedication, and hunger*/}
                {/*        required for student-athletes in need around the world*/}
                {/*        and STZY’s core mission is to impact the lives of these*/}
                {/*        young athletes by supporting their personal commitment*/}
                {/*        to bettering themselves by empowering them to Keep.*/}
                {/*        Pushing. Forward. At STZY, product=purpose, and for the*/}
                {/*        team, it’s beyond just providing the highest quality*/}
                {/*        socks. STZY is determined to help these student-athletes*/}
                {/*        reach their dreams, goals, and aspirations as they*/}
                {/*        believe that our future is our youth.*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study?page=betterplace-case"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FyeeSi.png?alt=media&token=31f74308-6857-4064-b83b-3d41cd2c4dc7"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FyeeSi.png?alt=media&token=31f74308-6857-4064-b83b-3d41cd2c4dc7 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FyeeSi.png?alt=media&token=31f74308-6857-4064-b83b-3d41cd2c4dc7 800w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">YEE SI</div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        Yu Chen: A Designer and CEO Who Folded the Fashion World*/}
                {/*        With His Origami Handbag*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        Yu Chen is a Chinese-American designer and entrepreneur*/}
                {/*        best known as the co-founder of YEE SI, the world’s*/}
                {/*        first foldable handbag brand. Chen is also a recipient*/}
                {/*        of the Hurun US Innovation Outstanding Awards and one of*/}
                {/*        Forbes’ 60 Outstanding Chinese in North America. Chen*/}
                {/*        immigrated to the United States from China and began his*/}
                {/*        career at prominent design companies, learning from top*/}
                {/*        minds in the design world.{" "}*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study/purplle-case-study"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d 800w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d 1080w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d 1600w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FkwasiPaul.png?alt=media&token=8b4c29ad-f9b1-4178-817f-aaef2dcedd7d 1667w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">Kwasi Paul</div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        The Intersection of Culture and Fashion: A Talk with Sam*/}
                {/*        Boakye, Founder of Kwasi Paul*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        Sam Boakye, a fashion designer turned serial*/}
                {/*        entrepreneur. In 2020, Sam Boakye founded Kwasi Paul, an*/}
                {/*        e-commerce apparel brand with a strong emphasis on*/}
                {/*        heritage, fluidity, and the unknown. As a*/}
                {/*        first-generation Ghanaian-American, Sam founded Kwasi*/}
                {/*        Paul with a mission to explore the juxtaposition between*/}
                {/*        the Western world and the African Diaspora through the*/}
                {/*        stitching of a unique fashion identity.*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study/tabby"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fcarved.png?alt=media&token=d5656cb2-c6b0-4466-80be-fc0758d5bb3e"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fcarved.png?alt=media&token=d5656cb2-c6b0-4466-80be-fc0758d5bb3e 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2Fcarved.png?alt=media&token=d5656cb2-c6b0-4466-80be-fc0758d5bb3e 800w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">Carved</div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        Finding Success in the Trough of Sorrow: The Story of*/}
                {/*        Carved's Founder and CEO, John Webber*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        John Webber, the founder, and CEO of Carved — an*/}
                {/*        e-commerce brand that specializes in selling unique,*/}
                {/*        artistic, and handcrafted phone cases along with*/}
                {/*        wallets, and other accessories for electronic devices.*/}
                {/*        John is a serial entrepreneur with an extensive*/}
                {/*        background in the e-commerce space but in 2011, he*/}
                {/*        founded Carved on a deep desire to define something new,*/}
                {/*        to create a place where natural materials intersect*/}
                {/*        modern technology and over a decade later, he’s still*/}
                {/*        committed to this mission.*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study/drip-capital"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FactualVeggies.png?alt=media&token=71cedf5a-f417-4e0d-9556-d33ba5b7d197"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FactualVeggies.png?alt=media&token=71cedf5a-f417-4e0d-9556-d33ba5b7d197 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FactualVeggies.png?alt=media&token=71cedf5a-f417-4e0d-9556-d33ba5b7d197 800w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">*/}
                {/*        Actual Veggies*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        From Tech to Food: A Serial Entrepreneur’s Evolution*/}
                {/*        into the Plant-Based Food Industry*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        Jason Rosenbaum, a serial entrepreneur, and VC mentor,*/}
                {/*        who serves as the co-founder and CEO of Actual Veggies,*/}
                {/*        an e-commerce food brand that specializes in crafting*/}
                {/*        veggie burgers from genuine vegetables. In 2019, Jason*/}
                {/*        decided to abstain from meat, and in doing so, he*/}
                {/*        recognized a glaring gap in the market for authentic*/}
                {/*        plant-based burger options. Disappointed by the*/}
                {/*        prevalence of imitation meat products, he launched*/}
                {/*        Actual Veggies. Today, the company is dedicated to*/}
                {/*        producing fresh, flavorful, and chef-crafted veggie*/}
                {/*        burgers that highlight the unique flavors and textures*/}
                {/*        of vegetables.*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}
                {/*<div*/}
                {/*  role="listitem"*/}
                {/*  className="case-study-collection-item w-dyn-item"*/}
                {/*>*/}
                {/*  <a*/}
                {/*    href="/case-study/urban-company"*/}
                {/*    target="_blank"*/}
                {/*    className="case-study-item-wrapper w-inline-block"*/}
                {/*  >*/}
                {/*    <img*/}
                {/*      src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FfiveFounders.png?alt=media&token=4f8cdb52-d3da-46c3-a732-22ed30ee5714"*/}
                {/*      loading="lazy"*/}
                {/*      alt=""*/}
                {/*      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"*/}
                {/*      srcSet="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FfiveFounders.png?alt=media&token=4f8cdb52-d3da-46c3-a732-22ed30ee5714 500w, https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fcase-studies-pics%2FfiveFounders.png?alt=media&token=4f8cdb52-d3da-46c3-a732-22ed30ee5714 800w"*/}
                {/*      className="case-study-hero-item-image"*/}
                {/*    />*/}
                {/*    <div className="case-study-item-text-wrapper">*/}
                {/*      <div className="case-study-item-pink-tag">*/}
                {/*        Five Purpose-Driven Founders*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-heading">*/}
                {/*        Beyond the Bottom Line: The Philosophy of Five*/}
                {/*        Purpose-Driven Founders*/}
                {/*      </div>*/}
                {/*      <div className="case-study-item-para-small text-limited">*/}
                {/*        Over the past few weeks, the Pabloo team engaged in*/}
                {/*        profound conversations with 5 trailblazing founders from*/}
                {/*        diverse industries and delved deep into their individual*/}
                {/*        journeys of entrepreneurship. Each interview culminated*/}
                {/*        with a simple yet, thought-provoking question — what is*/}
                {/*        your secret to success?*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </a>*/}
                {/*</div>*/}




                {/* for additional future case studies below */}
                {/* <div
                  role="listitem"
                  className="case-study-collection-item w-dyn-item"
                >
                  <a
                    href="/case-study/airmeet"
                    target="_blank"
                    className="case-study-item-wrapper w-inline-block"
                  >
                    <img
                      src="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6bfeed723f5b1736874b_webisite%20cover%20image-01.png"
                      loading="lazy"
                      alt=""
                      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"
                      srcSet="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6bfeed723f5b1736874b_webisite%20cover%20image-01-p-500.png 500w, https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6bfeed723f5b1736874b_webisite%20cover%20image-01.png 800w"
                      className="case-study-hero-item-image"
                    />
                    <div className="case-study-item-text-wrapper">
                      <div className="case-study-item-pink-tag">Airmeet</div>
                      <div className="case-study-item-heading">
                        How Airmeet uses  to procure 3X faster and
                        optimize their SaaS spending.
                      </div>
                      <div className="case-study-item-para-small text-limited">
                        Airmeet was losing precious business hours trying to
                        negotiate with multiple SaaS vendors. 's
                        assisted buying and benchmark data knowledge expedites
                        their SaaS procurement process while getting better
                        deals.
                      </div>
                    </div>
                  </a>
                </div>
                <div
                  role="listitem"
                  className="case-study-collection-item w-dyn-item"
                >
                  <a
                    href="/case-study/crownpeak"
                    target="_blank"
                    className="case-study-item-wrapper w-inline-block"
                  >
                    <img
                      src="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6c2be299cf5e69e70140_webisite%20cover%20image-04.png"
                      loading="lazy"
                      alt=""
                      sizes="(max-width: 767px) 80vw, (max-width: 991px) 472px, (max-width: 1279px) 38vw, 472px"
                      srcSet="https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6c2be299cf5e69e70140_webisite%20cover%20image-04-p-500.png 500w, https://cdn.prod.website-files.com/61f0f4eb82e5f048b4edb319/65dd6c2be299cf5e69e70140_webisite%20cover%20image-04.png 800w"
                      className="case-study-hero-item-image"
                    />
                    <div className="case-study-item-text-wrapper">
                      <div className="case-study-item-pink-tag">Crownpeak</div>
                      <div className="case-study-item-heading">
                        How  optimized SaaS across multiple offices for
                        Crownpeak and saved 30% on their annual SaaS expense
                      </div>
                      <div className="case-study-item-para-small text-limited">
                        Crownpeak were often surprised by their SaaS renewal
                        dates and found it challenging to proactively negotiate
                        SaaS renewals.  dashboard provided timely
                        reminders and assisted in getting in the best deals.
                      </div>
                    </div>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseStudyComponent;
