import React, { useEffect } from "react";
import "../../styles/products-styling/cleaned/product.css";

const ProductComponent = () => {
  return (
    <div
      className="page-template page-template-elementor_header_footer page
      page-id-1652 wp-custom-logo elementor-default
      elementor-template-full-width elementor-kit-159 elementor-page
      elementor-page-1652"
    >
      <div
        data-elementor-type="wp-page"
        data-elementor-id={1652}
        className="elementor elementor-1652"
        data-elementor-post-type="page"
      >
        <div
          className="elementor-element elementor-element-df61ea6 e-flex e-con-boxed e-con e-parent"
          data-id="df61ea6"
          data-element_type="container"
          data-settings='{"background_background":"classic","shape_divider_bottom":"tilt"}'
        >
          <div className="e-con-inner">
            <div
              className="elementor-shape elementor-shape-bottom"
              data-negative="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 100"
                preserveAspectRatio="none"
              >
                <path
                  className="elementor-shape-fill"
                  d="M0,6V0h1000v100L0,6z"
                />
              </svg>
            </div>
            <div
              className="elementor-element elementor-element-287395a elementor-widget__width-initial elementor-widget elementor-widget-icon-box"
              data-id="287395a"
              data-element_type="widget"
              data-widget_type="icon-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-icon-box-wrapper">
                  <div className="elementor-icon-box-content">
                    <h1 className="elementor-icon-box-title">
                      <span> Task Management </span>
                    </h1>
                    <p className="elementor-icon-box-description">
                      With our task management you will be sure to get through
                      your daily to-do lists, plan your day and week accordingly
                      and much more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-37ea6f4 elementor-icon-list--layout-inline elementor-align-left elementor-widget-mobile__width-inherit elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list"
              data-id="37ea6f4"
              data-element_type="widget"
              data-widget_type="icon-list.default"
            >
              <div className="elementor-widget-container">
                <ul className="elementor-icon-list-items elementor-inline-items">
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="#tasks">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fas fa-tasks" />{" "}
                      </span>
                      <span className="elementor-icon-list-text">Tasks</span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="#subtasks">
                      <span className="elementor-icon-list-icon">
                        <i aria-hidden="true" className="fas fa-file-alt" />{" "}
                      </span>
                      <span className="elementor-icon-list-text">Subtasks</span>
                    </a>
                  </li>
                  <li className="elementor-icon-list-item elementor-inline-item">
                    <a href="#integration-tasks">
                      <span className="elementor-icon-list-icon">
                        <i
                          aria-hidden="true"
                          className="fas fa-project-diagram"
                        />{" "}
                      </span>
                      <span className="elementor-icon-list-text">
                        Integration tasks
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-13b0a15 e-flex e-con-boxed e-con e-parent"
          data-id="13b0a15"
          data-element_type="container"
          id="tasks"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-1ee596c e-con-full e-flex e-con e-child"
              data-id="1ee596c"
              data-element_type="container"
              data-settings='{"background_background":"classic"}'
            >
              <div
                className="elementor-element elementor-element-a0880e0 elementor-widget elementor-widget-menu-anchor"
                data-id="a0880e0"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-menu-anchor" id="tasks" />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-8ee0213 elementor-widget elementor-widget-image"
                data-id="8ee0213"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    decoding="async"
                    width={373}
                    height={453}
                    src="https://planzer.io/wp-content/uploads/2023/01/62d5698a920dec3f3e45bcbf_tasks-solved.svg"
                    className="attachment-full size-full wp-image-1674"
                    alt="tasks solved"
                    title="Task Management"
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-8039c31 e-con-full e-flex e-con e-child"
              data-id="8039c31"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-86264bd elementor-align-left animated-fast elementor-invisible elementor-widget elementor-widget-button"
                data-id="86264bd"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">Tasks</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-04e3ec4 elementor-widget elementor-widget-icon-box"
                data-id="04e3ec4"
                data-element_type="widget"
                data-widget_type="icon-box.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-content">
                      <h2 className="elementor-icon-box-title">
                        <span>
                          Plan your day ahead and get through your tasks
                        </span>
                      </h2>
                      <p className="elementor-icon-box-description">
                        By planning your day ahead you allow yourself to start
                        thinking solutions already at the planning point. This
                        means when you reach the point where you actually need
                        to work on the task, then you often have the solution
                        already, and solve it much faster. - And by the way, all
                        the tasks you don't solve? We automatically move to the
                        next day for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-8c0b439 e-flex e-con-boxed e-con e-parent"
          data-id="8c0b439"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-363e4a7 im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="363e4a7"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Recurring-tasks-icon.png"
                      className="attachment-full size-full wp-image-1734"
                      alt="Recurring tasks icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Recurring tasks
                    </h3>
                    <p className="elementor-image-box-description">
                      If you have recurring tasks each week then set up a task
                      as recurring to save time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-43a1a89 im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="43a1a89"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Add-to-backlog-icon.png"
                      className="attachment-full size-full wp-image-1729"
                      alt="Add to backlog icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Add to the backlog
                    </h3>
                    <p className="elementor-image-box-description">
                      We have added a backlog where you can add all the tasks
                      you don't know when to solve.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-926997b im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="926997b"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Filter-by-tags-icon.png"
                      className="attachment-full size-full wp-image-1737"
                      alt="Filter by tags icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Filter by tags
                    </h3>
                    <p className="elementor-image-box-description">
                      To faster create an overview of specific tasks, you can
                      add tags to your tasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-656778a e-flex e-con-boxed e-con e-parent"
          data-id="656778a"
          data-element_type="container"
          id="subtasks"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-229ba6d e-con-full e-flex e-con e-child"
              data-id="229ba6d"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-aab20a5 elementor-widget elementor-widget-menu-anchor"
                data-id="aab20a5"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-menu-anchor" id="subtasks" />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-a7504da elementor-align-left animated-fast elementor-invisible elementor-widget elementor-widget-button"
                data-id="a7504da"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">Subtasks</span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-75e417e elementor-widget elementor-widget-icon-box"
                data-id="75e417e"
                data-element_type="widget"
                data-widget_type="icon-box.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-content">
                      <h2 className="elementor-icon-box-title">
                        <span> Elaborate your tasks with subtasks </span>
                      </h2>
                      <p className="elementor-icon-box-description">
                        Using subtasks allows you to specify a task even more
                        and to split it up. Some times a task can seem
                        overwhelming just by the task title. Splitting it up in
                        minor tasks makes it easier to comprehend, and creates a
                        bigger possibility for you to solve the task.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-1f388bb e-con-full e-flex e-con e-child"
              data-id="1f388bb"
              data-element_type="container"
              data-settings='{"background_background":"classic"}'
            >
              <div
                className="elementor-element elementor-element-5126345 elementor-widget elementor-widget-image"
                data-id={5126345}
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    loading="lazy"
                    decoding="async"
                    width={905}
                    height={631}
                    src="https://planzer.io/wp-content/uploads/2023/01/62e0f43816206dbd12765d0f_organize-your-emails.webp"
                    srcSet="https://planzer.io/wp-content/uploads/2023/01/62e0f43816206dbd12765d0f_organize-your-emails.webp 905w, https://planzer.io/wp-content/uploads/2023/01/62e0f43816206dbd12765d0f_organize-your-emails-300x209.webp 300w, https://planzer.io/wp-content/uploads/2023/01/62e0f43816206dbd12765d0f_organize-your-emails-768x535.webp 768w, https://planzer.io/wp-content/uploads/2023/01/62e0f43816206dbd12765d0f_organize-your-emails-600x418.webp 600w"
                    sizes="(max-width: 905px) 100vw, 905px"
                    className="lazy attachment-full size-full wp-image-1678"
                    alt="organize-your-emails"
                    title="Task Management"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-eaae3f2 e-flex e-con-boxed e-con e-parent"
          data-id="eaae3f2"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-136561f im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="136561f"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2022/12/Plan-Your-Day.svg"
                      className="attachment-full size-full wp-image-546"
                      alt="Plan Your Day"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Solve tasks faster
                    </h3>
                    <p className="elementor-image-box-description">
                      By splitting up your tasks in subtasks you will see
                      yourself increase your speed in solving your tasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-1d2c35c im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="1d2c35c"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Avoid-getting-stuck-icon.png"
                      className="attachment-full size-full wp-image-1730"
                      alt="Avoid getting stuck icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Avoid getting stuck
                    </h3>
                    <p className="elementor-image-box-description">
                      A big task with just a title, easily get's you stuck, but
                      by breaking it up in subtasks you avoid this.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-ea4471c im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="ea4471c"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Finish-subtasks-icon.png"
                      className="attachment-full size-full wp-image-1733"
                      alt="Finish subtasks icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Finish subtasks
                    </h3>
                    <p className="elementor-image-box-description">
                      The feeling of check-marking a task when it's done is just
                      amazing, remember to do it for your subtasks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-f1228cf e-flex e-con-boxed e-con e-parent "
          data-id="f1228cf"
          data-element_type="container"
        >
          <div className="e-con-inner ">
            <div
              className="elementor-element elementor-element-f406ec5 elementor-widget elementor-widget-template "
              data-id="f406ec5"
              data-element_type="widget"
              data-widget_type="template.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-template">
                  <div
                    data-elementor-type="container"
                    data-elementor-id={1770}
                    className="elementor elementor-1770"
                    data-elementor-post-type="elementor_library"
                  >
                    <div
                      className="elementor-element elementor-element-43c693e0 e-flex e-con-boxed e-con e-parent points-section"
                      data-id="43c693e0"
                      data-element_type="container"
                      data-settings='{"background_background":"classic"}'
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-75867125 elementor-widget__width-initial elementor-widget elementor-widget-heading"
                          data-id={75867125}
                          data-element_type="widget"
                          data-widget_type="heading.default"
                        >
                          <div className="elementor-widget-container">
                            <h2 className="elementor-heading-title elementor-size-default">
                              Ready to start planning your day?
                            </h2>
                          </div>
                        </div>
                        <div
                          className="elementor-element elementor-element-58fe34d3 elementor-align-right elementor-mobile-align-justify elementor-widget__width-initial elementor-widget elementor-widget-button"
                          data-id="58fe34d3"
                          data-element_type="widget"
                          data-widget_type="button.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="elementor-button-wrapper">
                              <a
                                className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                                href="/signup"
                                target="_blank"
                              >
                                <span className="elementor-button-content-wrapper">
                                  <span className="elementor-button-icon">
                                    <i aria-hidden="true" className="fas" />{" "}
                                  </span>
                                  <span className="elementor-button-text">
                                    Sign up now
                                  </span>
                                </span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-6ae8ef6 e-flex e-con-boxed e-con e-parent"
          data-id="6ae8ef6"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-95f4f13 e-con-full e-flex e-con e-child"
              data-id="95f4f13"
              data-element_type="container"
              data-settings='{"background_background":"classic"}'
            >
              <div
                className="elementor-element elementor-element-9e6d907 elementor-widget elementor-widget-menu-anchor"
                data-id="9e6d907"
                data-element_type="widget"
                data-widget_type="menu-anchor.default"
              >
                <div className="elementor-widget-container">
                  <div
                    className="elementor-menu-anchor"
                    id="integration-tasks"
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-7e97d70 elementor-widget elementor-widget-image"
                data-id="7e97d70"
                data-element_type="widget"
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    loading="lazy"
                    decoding="async"
                    width={349}
                    height={419}
                    src="https://planzer.io/wp-content/uploads/2023/01/lines-in-colors.svg"
                    className="attachment-full size-full wp-image-1707"
                    alt="lines in colors"
                    title="Task Management"
                  />
                </div>
              </div>
              <div
                className="elementor-element elementor-element-e0d7628 elementor-absolute elementor-widget elementor-widget-image"
                data-id="e0d7628"
                data-element_type="widget"
                data-settings='{"_position":"absolute"}'
                data-widget_type="image.default"
              >
                <div className="elementor-widget-container">
                  <img
                    loading="lazy"
                    decoding="async"
                    width={254}
                    height={268}
                    src="https://planzer.io/wp-content/uploads/2023/01/integration-tasks.svg"
                    className="attachment-large size-large wp-image-1706"
                    alt="integration-tasks"
                    title="Task Management"
                  />
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-3f5911a e-con-full e-flex e-con e-child"
              data-id="3f5911a"
              data-element_type="container"
            >
              <div
                className="elementor-element elementor-element-65d4e04 elementor-align-left animated-fast elementor-invisible elementor-widget elementor-widget-button"
                data-id="65d4e04"
                data-element_type="widget"
                data-settings='{"_animation":"fadeInUp"}'
                data-widget_type="button.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-button-wrapper">
                    <a
                      className="elementor-button elementor-size-sm"
                      role="button"
                    >
                      <span className="elementor-button-content-wrapper">
                        <span className="elementor-button-text">
                          Integration tasks
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="elementor-element elementor-element-e0df2b7 elementor-widget elementor-widget-icon-box"
                data-id="e0df2b7"
                data-element_type="widget"
                data-widget_type="icon-box.default"
              >
                <div className="elementor-widget-container">
                  <div className="elementor-icon-box-wrapper">
                    <div className="elementor-icon-box-content">
                      <h2 className="elementor-icon-box-title">
                        <span>
                          {" "}
                          Pull in tasks from our native integrations{" "}
                        </span>
                      </h2>
                      <p className="elementor-icon-box-description">
                        To improve your productivity even further, we have built
                        native integrations to help you work faster. Simply add
                        your Notion or Trello account for example, and then we
                        will pull in your tasks. All there is left for you to do
                        is to plan your tasks, and start solving them.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-a1cd467 e-flex e-con-boxed e-con e-parent"
          data-id="a1cd467"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-155c3b7 im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="155c3b7"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/9-integrations-icon.png"
                      className="attachment-full size-full wp-image-1728"
                      alt="9 integrations icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      9 integrations
                    </h3>
                    <p className="elementor-image-box-description">
                      At the moment we support 5 integrations, but we're
                      constantly working on many more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-68f847b im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="68f847b"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2023/01/Recurring-tasks-icon.png"
                      className="attachment-full size-full wp-image-1734"
                      alt="Recurring tasks icon"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Seamless data sync
                    </h3>
                    <p className="elementor-image-box-description">
                      We synchronise all your data in real-time, no need to
                      refresh, just pull in your tasks and plan your day.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-dd30db4 im-bx-r elementor-widget__width-initial elementor-position-top elementor-widget elementor-widget-image-box"
              data-id="dd30db4"
              data-element_type="widget"
              data-widget_type="image-box.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-image-box-wrapper">
                  <figure className="elementor-image-box-img">
                    <img
                      loading="lazy"
                      decoding="async"
                      width={87}
                      height={87}
                      src="https://planzer.io/wp-content/uploads/2022/12/Time-blocking.svg"
                      className="attachment-full size-full wp-image-547"
                      alt="Time blocking"
                      title="Task Management"
                    />
                  </figure>
                  <div className="elementor-image-box-content">
                    <h3 className="elementor-image-box-title">
                      Drag your tasks
                    </h3>
                    <p className="elementor-image-box-description">
                      When we pull in your tasks, then all you have to do is to
                      drag your tasks to your overview.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="elementor-element elementor-element-89e89f8 e-flex e-con-boxed e-con e-parent"
          data-id="89e89f8"
          data-element_type="container"
        >
          <div className="e-con-inner">
            <div
              className="elementor-element elementor-element-402082c elementor-widget elementor-widget-template"
              data-id="402082c"
              data-element_type="widget"
              data-widget_type="template.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-template">
                  <div
                    data-elementor-type="container"
                    data-elementor-id={1566}
                    className="elementor elementor-1566"
                    data-elementor-post-type="elementor_library"
                  >
                    <div
                      className="elementor-element elementor-element-633576a6 e-flex e-con-boxed e-con e-parent"
                      data-id="633576a6"
                      data-element_type="container"
                    >
                      <div className="e-con-inner">
                        <div
                          className="elementor-element elementor-element-2a4fb96c e-flex e-con-boxed e-con e-child"
                          data-id="2a4fb96c"
                          data-element_type="container"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="e-con-inner">
                            <div
                              className="elementor-element elementor-element-672681a4 e-con-full e-flex e-con e-child"
                              data-id="672681a4"
                              data-element_type="container"
                            >
                              <div
                                className="elementor-element elementor-element-79038120 elementor-widget elementor-widget-heading"
                                data-id={79038120}
                                data-element_type="widget"
                                data-widget_type="heading.default"
                              >
                                <div className="elementor-widget-container">
                                  <h2 className="elementor-heading-title elementor-size-default">
                                    Ready to start planning your day?
                                    <br />
                                    Sign up for free.
                                  </h2>
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-30942c0e elementor-align-left elementor-widget elementor-widget-button"
                                data-id="30942c0e"
                                data-element_type="widget"
                                data-widget_type="button.default"
                              >
                                <div className="elementor-widget-container">
                                  <div className="elementor-button-wrapper">
                                    <a
                                      className="elementor-button elementor-button-link elementor-size-sm elementor-animation-shrink"
                                      href="/signup"
                                      target="_blank"
                                    >
                                      <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-icon">
                                          <i
                                            aria-hidden="true"
                                            className="fas"
                                          />{" "}
                                        </span>
                                        <span className="elementor-button-text">
                                          Start now for free
                                        </span>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2e0d5022 e-con-full e-flex e-con e-child"
                              data-id="2e0d5022"
                              data-element_type="container"
                            >
                              <div
                                className="elementor-element elementor-element-71772c73 elementor-absolute elementor-widget elementor-widget-image"
                                data-id="71772c73"
                                data-element_type="widget"
                                data-settings='{"_position":"absolute"}'
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={237}
                                    height={299}
                                    src="https://planzer.io/wp-content/uploads/2022/12/productivity.svg"
                                    className="attachment-large size-large wp-image-705"
                                    alt="productivity"
                                    title="Task Management"
                                  />
                                </div>
                              </div>
                              <div
                                className="elementor-element elementor-element-60ddee1b elementor-widget elementor-widget-image"
                                data-id="60ddee1b"
                                data-element_type="widget"
                                data-widget_type="image.default"
                              >
                                <div className="elementor-widget-container">
                                  <img
                                    loading="lazy"
                                    decoding="async"
                                    width={376}
                                    height={426}
                                    src="https://planzer.io/wp-content/uploads/2022/12/planzer-tasks-solved.svg"
                                    className="lazy attachment-medium_large size-medium_large wp-image-564"
                                    alt="planzer-tasks-solved"
                                    title="Task Management"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductComponent;
