export function sendSlackMessage(message, channel = "#user-engagement") {
    /*
    Supports: #user-engagement, #website-updates
     */
    let myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    let raw = JSON.stringify({
        "message": message,
        "channel": channel
    });
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw,
    };
    fetch("https://server.pabloo.com/virtual_bank/send_slack_message", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}



export function handleSubmission(jsonData, callbackFunction) {
    // Takes a dictionary object, and sends the submission to the backend
    // Sends Slack message and saves data to Firebase if applicable

    fetch("https://server.pabloo.com/website/handle_submission", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonData),
    }).then((result) => {
        callbackFunction(result)
    })
        .catch((error) => {
            console.log("An error occured", error);
        });
}
