//React imports
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';

//Imports the components
import CarouselComponent from '../../components/CarouselComponent.js';
import CalculatorComponent from '../../components/calculator/CaculatorComponent.js';
import SolutionComponent from '../../components/SolutionComponent.js';
import FooterComponent from '../../components/home/FooterComponent.js';
import HeaderComponent from '../../components/HeaderComponent.js';
import PricingComponent from '../../components/pricing/PricingComponent.js';
import GuaranteeComponent from '../../components/GuaranteeComponent.js';
import SecondaryPricingComponent from '../../components/SecondaryPricingComponent.js';

import NavBarComponent from '../../components/navBar/NavBarComponent.js';




function PricingPageAfterSignup() {
    useEffect(() => {
        const sendPageVisitEvent = () => {

        };
    }, []);

    return (
        <div>
            <div className="updated-page-wrapper">

                {/* stupid banner at top of page */}
                <div className="new-hello-bar">
                  <div className="hello-bar-link cursor-pointer"><strong className="bold-text-34">Use promo code <strong className="bold-text-35">storecredit</strong> for 15% off your Professional subscription. </strong><strong className="bold-text-35 ml-4">➔</strong></div>
                </div>

                <HeaderComponent page="pricingAfterSignup" />


                <section className="section-68" style={{ paddingTop: '1px', paddingBottom: '80px' }}>
                    <PricingComponent />
                </section>

                <section className="section-68 space-y-8" style={{ paddingTop: '0px', paddingBottom: '80px' }}>
                    {/*<GuaranteeComponent />*/}

                    <CarouselComponent />
                </section>

            </div>
        </div>
    );
}


export default PricingPageAfterSignup;
