import React, { useEffect, useState } from "react";
import {
  DashboardImage,
  InactiveCustomers,
  LoyaltyProgramImage,
  Refunds1,
} from "../../assets/assets";

const SpendComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    // console.log("This si the index", selectedIndex)
  }, []);

  return (
    <div>
      <script
        src="https://firebasestorage.googleapis.com/v0/b/pabloo-inc.appspot.com/o/website_development%2Fsolution-jquery.js?alt=media&token=ecb5cc8b-5f79-421c-b888-000d848375a8"
        type="text/javascript"
      ></script>
      <section className="spend-section new">
        <div className="spend-wrapper">
          <h2 className="heading-56px spend-heading">
            Nurturing stronger customer relationships that{" "}
            <span className="pink-heading-span" style={{ color: "#15803d" }}>
              triple your sales potential.
            </span>
          </h2>
          <div
            data-current="Tab 1"
            data-easing="ease"
            data-duration-in={300}
            data-duration-out={100}
            className="tabs-4 w-tabs"
          >
            <div className="tabs-menu-7 w-tab-menu">
              <div>
                <div
                  className="grid-cols-8 px-0 md:px-4 pt-2 md:pt-8"
                  style={{
                    display: "grid",
                  }}
                >
                  <div
                    className="flex items-center col-span-3 cursor-pointer"
                    onClick={() => {
                      setSelectedIndex(0);
                    }}
                  >
                    <div className="navigation-link">Re-engage</div>
                  </div>
                  <div className="col-span-1">{chevronArrow()}</div>

                  <div
                    className="flex items-center cursor-pointer col-span-2"
                    onClick={() => {
                      setSelectedIndex(1);
                    }}
                  >
                    <div className="navigation-link">Refund</div>
                  </div>

                  <div className="col-span-1">{chevronArrow()}</div>

                  <div
                    className="flex items-center cursor-pointer col-span-1"
                    onClick={() => {
                      setSelectedIndex(2);
                    }}
                  >
                    <div className="navigation-link">Reward</div>
                  </div>
                </div>

                <div className="text-center md:text-left">
                  <div className="a new tripleR-body-box p-4 pt-8">
                    {selectedIndex === 0 && (
                      <div className="space-y-4">
                        <h2 className="heading-40px spend-block-heading">
                          Re-engage inactive customers with{" "}
                          <strong className="text-green-500">
                            bespoke store credit campaigns
                          </strong>
                        </h2>
                        <p className="paragraph-18px spend-block-paragraph">
                          Inactive customers who haven't interacted with your
                          brand for a specified timeframe are automatically
                          enrolled in a bespoke re-engagement program.
                        </p>
                      </div>
                    )}

                    {selectedIndex === 1 && (
                      <div className="space-y-4">
                        <h2 className="heading-40px spend-block-heading">
                          Keep refunds{" "}
                          <strong className="text-green-500">
                            within your business
                          </strong>{" "}
                        </h2>
                        {/*<p className="paragraph-18px spend-block-paragraph new">Generate store credit for returns, refunds, or exchanges.</p>*/}
                        <p className="paragraph-18px spend-block-paragraph new">
                          Give your customers the option to receive store credit
                          for refunds and exchanges. This approach keeps the
                          funds circulating within your business.
                        </p>
                      </div>
                    )}

                    {selectedIndex === 2 && (
                      <div className="space-y-4">
                        <h2 className="heading-40px spend-block-heading">
                          Prioritize{" "}
                          <strong className="text-green-500">north star</strong>{" "}
                          metrics
                        </h2>
                        <p className="paragraph-18px spend-block-paragraph">
                          The Pabloo loyalty program incentivizes customer
                          sign-ups, referrals, and purchases, while reducing
                          churn. Businesses leveraging Pabloo's cashback
                          functionality see a remarkable 4x increase in repeat
                          purchases.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <a
                className="tab-link-tab-1-3 w-inline-block w-tab-link w--current"
                onClick={() => {
                  setSelectedIndex(0);
                }}
              ></a>
            </div>
            <div className="tabs-content-5 w-tab-content">
              <div className="w-tab-pane w--tab-active">
                <div className="div-block-868">
                  {/*<img sizes="100vw" srcSet="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/663b80e3aa2dba2a7e1fd8a0_Group%202085663903new2-p-500.png 500w, https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/663b80e3aa2dba2a7e1fd8a0_Group%202085663903new2.png 660w" alt="" src="https://assets-global.website-files.com/61e8ec6584cc457e5ccb9501/663b80e3aa2dba2a7e1fd8a0_Group%202085663903new2.png" loading="lazy" className="image-264" />*/}

                  {selectedIndex === 0 && (
                    <img
                      sizes="100vw"
                      alt="pabloo store credit campaign"
                      src={InactiveCustomers}
                      loading="lazy"
                      className="image-264"
                    />
                  )}
                  {selectedIndex === 1 && (
                    <img
                      sizes="100vw"
                      alt="pabloo dashboard image"
                      src={Refunds1}
                      loading="lazy"
                      className="image-264"
                    />
                  )}
                  {selectedIndex === 2 && (
                    <img
                      sizes="100vw"
                      alt="pabloo loyalty program"
                      src={LoyaltyProgramImage}
                      loading="lazy"
                      className="image-264 p-0 md:p-4"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );

  function chevronArrow() {
    return (
      <div className="indicator-arrow w-embed flex items-center">
        <svg
          width={16}
          height={16}
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_714_197)">
            <path
              d="M5.5 2L11 8L5.5 14"
              stroke="#240213"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_714_197">
              <rect
                width={16}
                height={16}
                fill="currentColor"
                transform="matrix(0 1 1 0 0 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    );
  }
};

export default SpendComponent;
